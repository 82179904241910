import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { GlobalImportVolume, Country } from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function getUserGlobalImportVolume(accessToken: string, locale: string) {
  return axios.get<RequestResult<GlobalImportVolume>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/gti/import-volume`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function getUserLargestImporters(accessToken: string, locale: string) {
  return axios.get<RequestResult<Country[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/gti/largest-importers`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
