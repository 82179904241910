import { BusinessOpportunityType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_BUSINESS_OPPORTUNITY_TYPES = 'businessopportunitytypes/RETRIEVE_BUSINESS_OPPORTUNITY_TYPES';
export const RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS =
  'businessopportunitytypes/RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS';
export const RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE =
  'businessopportunitytypes/RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE';

export interface RetrieveBusinessOpportunityTypesAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY_TYPES;
}

export interface RetrieveBusinessOpportunityTypesSuccessAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS;
  businessOpportunityTypes: BusinessOpportunityType[];
}

export interface RetrieveBusinessOpportunityTypesFailureAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE;
  error?: RequestResult<void>;
}
