import axios from 'axios';
import getConfig from 'next/config';
import { Company } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { SaveCompanyBody } from './types';

const { publicRuntimeConfig } = getConfig();

export function saveCompany(body: SaveCompanyBody, accessToken: string, locale: string) {
  return axios.post<RequestResult<Company>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/companies`, body, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveCompany(accessToken: string, locale: string) {
  return axios.get<RequestResult<Company>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/companies`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function searchCompany(companyName: string, accessToken: string, locale: string) {
  return axios.get<RequestResult<Partial<Company & { duns: string }>>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/crm/companies-bisnode?name=${companyName}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
export function certifyCRMCompany(companyDuns: string, accessToken: string, locale: string) {
  return axios.post<RequestResult<Partial<Company & { duns: string }>>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/crm/companies`,
    {
      duns: companyDuns
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
