import axios from 'axios';
import { MendelTaxesInfo } from './interfaces';
import { SelectedProduct } from '../product/interfaces';
import { MarketGuide } from '../marketGuide/interfaces';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function retrieveMendelInfo(locale: string, product: SelectedProduct, market: MarketGuide, accessToken: string) {
  return axios.get<MendelTaxesInfo>(`${publicRuntimeConfig.API_URI}/v1/${locale}/mendel`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { market: market.ISOCode2Letter, product: product.HSCode }
  });
}
