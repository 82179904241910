import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { RetryServerActionsAction, RETRY_SERVER_ACTIONS } from './types';
import { doClearServerActions } from './actions';
import { AppState } from '../../reducers';

function* workerRetryActions({}: RetryServerActionsAction) {
  const failedActions = yield select((state: AppState) => state.actionsReducer.failedServerActions);

  if (failedActions && failedActions.length > 0) {
    for (let index = 0; index < failedActions.length; index++) {
      yield put(failedActions[index]);
      yield put(doClearServerActions(failedActions[index]));
    }
  }
}
export function* watcherRetryActions() {
  yield takeLatest(RETRY_SERVER_ACTIONS, workerRetryActions);
}

export function* actionsSaga() {
  yield all([call(watcherRetryActions)]);
}
