import { Sector } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_SECTORS = 'sectors/RETRIEVE_SECTORS';
export const RETRIEVE_SECTORS_SUCCESS = 'sectors/RETRIEVE_SECTORS_SUCCESS';
export const RETRIEVE_SECTORS_FAILURE = 'sectors/RETRIEVE_SECTORS_FAILURE';

export interface RetrieveSectorsAction {
  type: typeof RETRIEVE_SECTORS;
  language?: string;
}

export interface RetrieveSectorsSuccessAction {
  type: typeof RETRIEVE_SECTORS_SUCCESS;
  sectors: Sector[];
}

export interface RetrieveSectorsFailureAction {
  type: typeof RETRIEVE_SECTORS_FAILURE;
  error?: RequestResult<void>;
}
