import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { CMSConsultant } from './interfaces';
import { SendConsultantEmailAction } from './types';

const { publicRuntimeConfig } = getConfig();

export function retrieveMarketConsultant(accessToken: string, locale: string, marketSlug: string) {
  return axios.get<RequestResult<CMSConsultant[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/consultants/${marketSlug}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

export function retrieveUserConsultantList(accessToken: string, locale: string) {
  return axios.get<RequestResult<CMSConsultant[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/consultants/user`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveConsultantBySlug(accessToken: string, locale: string, consultantSlug: string) {
  return axios.get<RequestResult<CMSConsultant[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/consultants/by-slug`, {
    params: { consultantSlug },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function sendConsultantEmail(data: SendConsultantEmailAction['data'], locale: string, accessToken: string) {
  return axios.post<RequestResult>(`${publicRuntimeConfig.API_URI}/v1/${locale}/consultants/send-email`, data, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
