import { createReducer } from 'redux-starter-kit';
import {
  AddFailedServerActionsAction,
  ADD_FAILED_SERVER_ACTION,
  ClearServerActionAction,
  CLEAR_SERVER_ACTION
} from './types';

export interface ActionsReducerState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failedServerActions: any[];
}

const initialState: ActionsReducerState = {
  failedServerActions: []
};

export default createReducer<ActionsReducerState>(initialState, {
  [CLEAR_SERVER_ACTION]: (state, action: ClearServerActionAction) => {
    state.failedServerActions = state.failedServerActions.filter(
      failedAction => failedAction.type !== action.action.type
    );
  },
  [ADD_FAILED_SERVER_ACTION]: (state, action: AddFailedServerActionsAction) => {
    state.failedServerActions = [...state.failedServerActions, action.action];
  }
});
