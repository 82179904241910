import {
  WORK_TYPES,
  WORK_TYPES_FAILURE,
  WORK_TYPES_SUCCESS,
  RetrieveWorkTypesAction,
  RetrieveWorkTypesFailureAction,
  RetrieveWorkTypesSuccessAction
} from './types';
import { WorkType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveWorkTypes = (): RetrieveWorkTypesAction => ({
  type: WORK_TYPES
});

export const doRetrieveWorkTypesFailure = (error?: RequestResult<void>): RetrieveWorkTypesFailureAction => ({
  error,
  type: WORK_TYPES_FAILURE
});

export const doRetrieveWorkTypesSuccess = (workTypes: WorkType[]): RetrieveWorkTypesSuccessAction => ({
  type: WORK_TYPES_SUCCESS,
  workTypes
});
