// Modules
import { AxiosResponse } from 'axios';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { doRetrieveProductTaxes, doRetrieveProductTaxesFailure, doRetrieveProductTaxesSuccess } from './actions';
import { MendelTaxesInfo } from './interfaces';
import { retrieveMendelInfo } from './operations';
import { RetrieveProductTaxesAction, RETRIEVE_PRODUCT_TAXES } from './types';

function* workerRetrieveProductTaxes({ product, market }: RetrieveProductTaxesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      const response: AxiosResponse<RequestResult<MendelTaxesInfo>> = yield call(
        retrieveMendelInfo,
        locale,
        product,
        market,
        profile.accessToken
      );
      if (response.data && response.data.result) {
        // Generating full report url
        const info = response.data.result;
        yield put(doRetrieveProductTaxesSuccess(info));
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveProductTaxes(product, market)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveProductTaxes(product, market)));
    } else {
      logging.error(error);
      yield put(doRetrieveProductTaxesFailure(error));
    }
  }
}

export function* watcherRetrieveProductTaxes() {
  yield takeEvery(RETRIEVE_PRODUCT_TAXES, workerRetrieveProductTaxes);
}

export function* tariffTaxesSaga() {
  yield all([call(watcherRetrieveProductTaxes)]);
}
