import { createReducer } from 'redux-starter-kit';
import { SupsWatchlistEditorRaw } from './interfaces';
import {
  RETRIEVE_WATCHLIST_EDITORS,
  RETRIEVE_WATCHLIST_EDITORS_SUCCESS,
  RETRIEVE_WATCHLIST_EDITORS_FAILURE,
  ADD_WATCHLIST_EDITOR,
  ADD_WATCHLIST_EDITOR_SUCCESS,
  ADD_WATCHLIST_EDITOR_FAILURE,
  RetrieveWatchlistEditorsFailureAction,
  RetrieveWatchlistEditorsSuccessAction,
  REMOVE_WATCHLIST_EDITOR,
  REMOVE_WATCHLIST_EDITOR_SUCCESS,
  REMOVE_WATCHLIST_EDITOR_FAILURE,
  CLEAR_WATCHLIST_EDITOR_MODIFICATIONS,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE,
  RetrieveAvailableWatchlistEditorsFailureAction,
  RetrieveAvailableWatchlistEditorsSuccessAction,
  AddWatchlistEditorSuccessAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '@redux-modules/user/interfaces';

export interface WatchlistEditorsReducerState {
  editors?: SupsWatchlistEditorRaw[];
  isEditorsLoading: boolean;
  editorsError: RequestResult<void> | null;

  availableEditors?: User[];
  isAvaialbleEditorsLoading: boolean;
  availableEditorsError: RequestResult<void> | null;

  modifiedWatchlistEditor?: SupsWatchlistEditorRaw;
  isModificationLoading: boolean;
  isModificationSuccess?: boolean;
  modifiedWatchlistEditorError: RequestResult<void> | null;
}

const initialState: WatchlistEditorsReducerState = {
  editors: undefined,
  isEditorsLoading: false,
  editorsError: null,

  availableEditors: undefined,
  isAvaialbleEditorsLoading: false,
  availableEditorsError: null,

  modifiedWatchlistEditor: undefined,
  isModificationLoading: false,
  isModificationSuccess: undefined,
  modifiedWatchlistEditorError: null
};

const modifyHandler = (state: WatchlistEditorsReducerState) => {
  state.isModificationLoading = true;
  state.isModificationSuccess = undefined;
  state.modifiedWatchlistEditorError = null;
};

const modifySuccessHandler = <T extends { editor: SupsWatchlistEditorRaw }>(
  state: WatchlistEditorsReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistEditor = action.editor;
  state.modifiedWatchlistEditorError = null;
  state.isModificationSuccess = true;
};

const modifyErrorHandler = <T extends { error?: RequestResult<void> }>(
  state: WatchlistEditorsReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistEditor = undefined;
  state.isModificationSuccess = false;
  if (action.error) {
    state.modifiedWatchlistEditorError = action.error;
  }
};

export default createReducer<WatchlistEditorsReducerState>(initialState, {
  [RETRIEVE_WATCHLIST_EDITORS]: state => {
    state.isEditorsLoading = true;
    state.editorsError = null;
  },
  [RETRIEVE_WATCHLIST_EDITORS_SUCCESS]: (state, action: RetrieveWatchlistEditorsSuccessAction) => {
    state.isEditorsLoading = false;
    state.editors = action.watchlistEditors;
    state.editorsError = null;
  },
  [RETRIEVE_WATCHLIST_EDITORS_FAILURE]: (state, action: RetrieveWatchlistEditorsFailureAction) => {
    state.isEditorsLoading = false;
    state.editors = undefined;
    if (action.error) {
      state.editorsError = action.error;
    }
  },
  [RETRIEVE_AVAILABLE_WATCHLIST_EDITORS]: state => {
    state.isAvaialbleEditorsLoading = true;
    state.availableEditorsError = null;
  },
  [RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_SUCCESS]: (state, action: RetrieveAvailableWatchlistEditorsSuccessAction) => {
    state.isAvaialbleEditorsLoading = false;
    state.availableEditors = action.users;
    state.availableEditorsError = null;
  },
  [RETRIEVE_AVAILABLE_WATCHLIST_EDITORS_FAILURE]: (state, action: RetrieveAvailableWatchlistEditorsFailureAction) => {
    state.isAvaialbleEditorsLoading = false;
    state.availableEditors = undefined;
    if (action.error) {
      state.availableEditorsError = action.error;
    }
  },
  [ADD_WATCHLIST_EDITOR]: modifyHandler,
  [ADD_WATCHLIST_EDITOR_SUCCESS]: (state, action: AddWatchlistEditorSuccessAction) => {
    modifySuccessHandler(state, action);
    if (state.availableEditors && action.editor) {
      state.availableEditors = state.availableEditors.filter(editor => editor.id !== action.editor.editorId);
    }
  },
  [ADD_WATCHLIST_EDITOR_FAILURE]: modifyErrorHandler,
  [REMOVE_WATCHLIST_EDITOR]: modifyHandler,
  [REMOVE_WATCHLIST_EDITOR_SUCCESS]: modifySuccessHandler,
  [REMOVE_WATCHLIST_EDITOR_FAILURE]: modifyErrorHandler,
  [CLEAR_WATCHLIST_EDITOR_MODIFICATIONS]: state => {
    state.modifiedWatchlistEditor = undefined;
    state.isModificationLoading = false;
    state.isModificationSuccess = undefined;
    state.modifiedWatchlistEditorError = null;
  }
});
