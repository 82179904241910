import { createWrapper, MakeStore } from 'next-redux-wrapper';
import getConfig from 'next/config';
import { AnyAction, applyMiddleware, compose, createStore, Middleware, Reducer, Store } from 'redux';
import logger from 'redux-logger';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import createSagaMiddleware, { Task } from 'redux-saga';
import rootReducer, { AppState } from './reducers';
import { rootSaga } from './sagas';

const { publicRuntimeConfig } = getConfig();
const isServer = typeof window === 'undefined';

export interface AppStore extends Store {
  sagaTask?: Task;
  persistor?: Persistor;
}

const makeConfiguredStore = (reducer: Reducer) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: Middleware[] = [sagaMiddleware];
  let composeEnhancers = compose;

  if (!isServer && process.env.NODE_ENV === 'development') {
    if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }

    if (publicRuntimeConfig.LOG_REDUX_ACTIONS !== 'false') {
      middlewares.push(logger);
    }
  }

  const store = createStore<
    AppState,
    AnyAction,
    { sagaTask?: Task; persistor?: Persistor; dispatch: unknown },
    unknown
  >(reducer, undefined, composeEnhancers(applyMiddleware(...middlewares)));
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

const makeStore: MakeStore<AppState> = () => {
  if (isServer) {
    return makeConfiguredStore(rootReducer);
  }

  const persistConfig = {
    key: 'root',
    whitelist: [
      'appReducer',
      'industryReducer',
      'cachedMarketListReducer',
      'userReducer',
      'exportNavigatorReducer',
      'reportReducer',
      'onboardingReducer'
    ],
    storage: createWebStorage('local')
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = makeConfiguredStore(persistedReducer);
  store.persistor = persistStore(store);

  return store;
};

export const wrapper = createWrapper<AppState>(makeStore);
