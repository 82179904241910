import axios from 'axios';
import getConfig from 'next/config';
import { PostingReason } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrievePostingReasons(accessToken: string, locale: string) {
  return axios.get<RequestResult<PostingReason[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/posting-reasons`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
