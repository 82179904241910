import { PostingEmployeesDuration } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_POSTING_EMPLOYEES_DURATIONS = 'postingemployeesdurations/RETRIEVE_POSTING_EMPLOYEES_DURATIONS';
export const RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS =
  'postingemployeesdurations/RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS';
export const RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE =
  'postingemployeesdurations/RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE';

export interface RetrievePostingEmployeesDurationsAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_DURATIONS;
}

export interface RetrievePostingEmployeesDurationsSuccessAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS;
  postingEmployeesDurations: PostingEmployeesDuration[];
}

export interface RetrievePostingEmployeesDurationsFailureAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE;
  error?: RequestResult<void>;
}
