import {
  SEARCH_SUPS,
  SEARCH_SUPS_SUCCESS,
  SEARCH_SUPS_FAILURE,
  SearchSupsAction,
  SearchSupsFailureAction,
  SearchSupsSuccessAction,
  RetrieveSupsDetailsAction,
  RETRIEVE_SUPS_DETAILS,
  RetrieveSupsDetailsSuccessAction,
  RetrieveSupsDetailsFailureAction,
  RETRIEVE_SUPS_DETAILS_FAILURE,
  RETRIEVE_SUPS_DETAILS_SUCCESS,
  RetrieveSupsCompanyAction,
  RETRIEVE_SUPS_COMPANY,
  RETRIEVE_SUPS_COMPANY_FAILURE,
  RETRIEVE_SUPS_COMPANY_SUCCESS,
  RetrieveSupsCompanyFailureAction,
  RetrieveSupsCompanySuccessAction,
  SUGGEST_SUPS_SEARCH,
  SUGGEST_SUPS_SEARCH_FAILURE,
  SUGGEST_SUPS_SEARCH_SUCCESS,
  SuggestSupsSearchSuccessAction,
  SuggestSupsSearchFailureAction,
  SuggestSupsSearchAction
} from './types';
import { SupsRecord, SupsResult, SupsSuggestResult } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doSearchSups = (url: string): SearchSupsAction => ({
  type: SEARCH_SUPS,
  url
});

export const doSearchSupsFailure = (error?: RequestResult<void>): SearchSupsFailureAction => ({
  error,
  type: SEARCH_SUPS_FAILURE
});

export const doSearchSupsSuccess = (sups: SupsResult, currentUrl: string): SearchSupsSuccessAction => ({
  type: SEARCH_SUPS_SUCCESS,
  sups,
  currentUrl
});

export const doRetrieveSupsDetails = (url: string): RetrieveSupsDetailsAction => ({
  type: RETRIEVE_SUPS_DETAILS,
  url
});

export const doRetrieveSupsDetailsFailure = (error?: RequestResult<void>): RetrieveSupsDetailsFailureAction => ({
  error,
  type: RETRIEVE_SUPS_DETAILS_FAILURE
});

export const doRetrieveSupsDetailsSuccess = (
  sups: SupsResult,
  currentUrl: string
): RetrieveSupsDetailsSuccessAction => ({
  type: RETRIEVE_SUPS_DETAILS_SUCCESS,
  sups,
  currentUrl
});

export const doRetrieveSupsCompany = (url: string): RetrieveSupsCompanyAction => ({
  type: RETRIEVE_SUPS_COMPANY,
  url
});

export const doRetrieveSupsCompanyFailure = (error?: RequestResult<void>): RetrieveSupsCompanyFailureAction => ({
  error,
  type: RETRIEVE_SUPS_COMPANY_FAILURE
});

export const doRetrieveSupsCompanySuccess = (
  record: SupsRecord,
  currentUrl: string
): RetrieveSupsCompanySuccessAction => ({
  type: RETRIEVE_SUPS_COMPANY_SUCCESS,
  record,
  currentUrl
});

export const doSuggestSupsSearch = (term: string): SuggestSupsSearchAction => ({
  type: SUGGEST_SUPS_SEARCH,
  term
});

export const doSuggestSupsSearchFailure = (error?: RequestResult<void>): SuggestSupsSearchFailureAction => ({
  error,
  type: SUGGEST_SUPS_SEARCH_FAILURE
});

export const doSuggestSupsSearchSuccess = (result: SupsSuggestResult): SuggestSupsSearchSuccessAction => ({
  type: SUGGEST_SUPS_SEARCH_SUCCESS,
  result
});
