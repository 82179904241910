import { createReducer } from 'redux-starter-kit';
import { Industry } from './interfaces';
import {
  RETRIEVE_INDUSTRIES,
  RETRIEVE_INDUSTRIES_SUCCESS,
  RETRIEVE_INDUSTRIES_FAILURE,
  RetrieveIndustriesSuccessAction,
  RetrieveIndustriesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { SAVE_COMPANY_SUCCESS, SaveCompanySuccessAction } from '../company/types';

export interface IndustriesReducerState {
  industries?: Industry[];
  selectedIndustries?: string[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: IndustriesReducerState = {
  industries: [],
  selectedIndustries: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<IndustriesReducerState>(initialState, {
  [RETRIEVE_INDUSTRIES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_INDUSTRIES_SUCCESS]: (state, action: RetrieveIndustriesSuccessAction) => {
    state.retrieveLoading = false;
    state.industries = action.industries;
    state.error = null;
  },
  [RETRIEVE_INDUSTRIES_FAILURE]: (state, action: RetrieveIndustriesFailureAction) => {
    state.retrieveLoading = false;
    state.industries = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [SAVE_COMPANY_SUCCESS]: (state, action: SaveCompanySuccessAction) => {
    state.selectedIndustries = action.industries;
  }
});
