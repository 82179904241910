import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { Country } from '../dashboard/interfaces';
import { MarketGuide, MarketProductPotential, MarketTrait, SwissExport } from './interfaces';
import {
  RetrieveMarketGuidePublicFailureAction,
  RetrieveMarketGuidePublicSuccessAction,
  RetrieveMarketGuideSuccessAction,
  RetrieveMarketProductPotentialFailureAction,
  RetrieveMarketProductPotentialSuccessAction,
  RetrieveMarketSwissExportFailureAction,
  RetrieveMarketSwissExportSuccessAction,
  RetrieveMarketSwissTraitsFailureAction,
  RetrieveMarketSwissTraitsSuccessAction,
  RetrieveMarketTopExportersFailureAction,
  RetrieveMarketTopExportersSuccessAction,
  RETRIEVE_MARKET_GUIDE,
  RETRIEVE_MARKET_GUIDE_PUBLIC,
  RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE,
  RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS,
  RETRIEVE_MARKET_GUIDE_SUCCESS,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS,
  RETRIEVE_MARKET_SWISS_EXPORT,
  RETRIEVE_MARKET_SWISS_EXPORT_FAILURE,
  RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS,
  RETRIEVE_MARKET_SWISS_TRAITS,
  RETRIEVE_MARKET_SWISS_TRAITS_FAILURE,
  RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS,
  RETRIEVE_MARKET_TOP_EXPORTERS,
  RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE,
  RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS,
  SubmitConsultantContactFailureAction,
  SUBMIT_CONSULTANT_CONTACT,
  SUBMIT_CONSULTANT_CONTACT_FAILURE,
  SUBMIT_CONSULTANT_CONTACT_SUCCESS
} from './types';

export interface MarketGuideReducerState {
  marketGuide: MarketGuide;
  loading: boolean;
  error?: RequestResult<void>;
  topExporters?: Country[];
  topExportersLoading: boolean;
  swissExport?: SwissExport;
  swissExportLoading: boolean;
  swissTraitsLoading: boolean;
  swissTraitsError?: RequestResult;
  swissTraits?: MarketTrait[];
  marketProductPotential?: MarketProductPotential[];
  marketProductPotentialLoading: boolean;
  marketProductPotentialError?: RequestResult<void>;
  submitConsultantContactLoading?: boolean;
  submitConsultantContactError?: RequestResult;
}

const initialState: MarketGuideReducerState = {
  marketGuide: {
    id: null,
    CRMGuid: '',
    ISOCode3Digit: '',
    ISOCode2Letter: '',
    ISOCode3Letter: '',
    internalName: '',
    teaserImage: '',
    headerImage: '',
    isActive: true,
    createdAt: '',
    updatedAt: '',
    name: '',
    languageCode: '',
    teaserText: '',
    text: '',
    slug: '',
    title: '',
    quote: '',
    quoteContactInfo: '',
    quoteContactFirstName: '',
    quoteContactLastName: '',
    quoteContactAvatar: '',
    swissPerspective: '',
    quoteContactJobTitle: ''
  },
  loading: true,
  topExportersLoading: false,
  swissExportLoading: false,
  swissTraitsLoading: false,
  marketProductPotentialLoading: false
};

export default createReducer<MarketGuideReducerState>(initialState, {
  [RETRIEVE_MARKET_GUIDE]: state => {
    state.error = undefined;
    state.loading = true;
  },
  [RETRIEVE_MARKET_GUIDE_SUCCESS]: (state, action: RetrieveMarketGuideSuccessAction) => {
    state.loading = false;
    state.marketGuide = action.marketGuide;
  },
  [RETRIEVE_MARKET_GUIDE_PUBLIC]: state => {
    state.error = undefined;
    state.loading = true;
  },
  [RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS]: (state, action: RetrieveMarketGuidePublicSuccessAction) => {
    state.loading = false;
    state.marketGuide = action.marketGuide;
  },
  [RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE]: (state, action: RetrieveMarketGuidePublicFailureAction) => {
    state.loading = false;
    state.error = action.error;
  },
  [RETRIEVE_MARKET_TOP_EXPORTERS]: state => {
    state.error = undefined;
    state.topExporters = undefined;
    state.topExportersLoading = true;
  },
  [RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS]: (state, action: RetrieveMarketTopExportersSuccessAction) => {
    state.topExporters = action.topExporters;
    state.topExportersLoading = false;
  },
  [RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE]: (state, action: RetrieveMarketTopExportersFailureAction) => {
    state.topExportersLoading = true;
    state.error = action.error;
  },
  [RETRIEVE_MARKET_SWISS_EXPORT]: state => {
    state.error = undefined;
    state.swissExport = undefined;
    state.swissExportLoading = true;
  },
  [RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS]: (state, action: RetrieveMarketSwissExportSuccessAction) => {
    state.swissExportLoading = false;
    state.swissExport = action.swissExport;
  },
  [RETRIEVE_MARKET_SWISS_EXPORT_FAILURE]: (state, action: RetrieveMarketSwissExportFailureAction) => {
    state.swissExportLoading = false;
    state.error = action.error;
  },
  [RETRIEVE_MARKET_SWISS_TRAITS]: state => {
    state.swissTraitsError = undefined;
    state.swissTraits = undefined;
    state.swissTraitsLoading = true;
  },
  [RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS]: (state, action: RetrieveMarketSwissTraitsSuccessAction) => {
    state.swissTraitsLoading = false;
    state.swissTraits = action.traits;
  },
  [RETRIEVE_MARKET_SWISS_TRAITS_FAILURE]: (state, action: RetrieveMarketSwissTraitsFailureAction) => {
    state.swissExportLoading = false;
    state.error = action.error;
  },
  [RETRIEVE_MARKET_PRODUCT_POTENTIAL]: state => {
    state.marketProductPotentialLoading = true;
  },
  [RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS]: (state, action: RetrieveMarketProductPotentialSuccessAction) => {
    state.marketProductPotential = action.marketProductPotential;
    state.marketProductPotentialLoading = false;
  },
  [RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE]: (state, action: RetrieveMarketProductPotentialFailureAction) => {
    state.marketProductPotentialError = action.error;
    state.marketProductPotentialLoading = false;
  },
  [SUBMIT_CONSULTANT_CONTACT]: state => {
    state.submitConsultantContactLoading = true;
  },
  [SUBMIT_CONSULTANT_CONTACT_SUCCESS]: state => {
    state.submitConsultantContactLoading = false;
  },
  [SUBMIT_CONSULTANT_CONTACT_FAILURE]: (state, action: SubmitConsultantContactFailureAction) => {
    state.submitConsultantContactError = action.error;
    state.submitConsultantContactLoading = false;
  }
});
