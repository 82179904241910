import moment from 'moment';
import { NextPage } from 'next';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { defaultLocale, momentLocales } from '../../../../locales';
import { doRetryServerActions } from '../../../../redux/modules/actions/actions';
import { AppState } from '../../../../redux/reducers';

export type MomentLanguageProps = React.PropsWithChildren<MomentLanguageReducerProps> & {
  locale?: string;
};

const MomentLanguage: NextPage<MomentLanguageProps> = props => {
  const momentLocale = momentLocales[props.locale || props.language || defaultLocale()];
  moment.updateLocale(momentLocale.code, momentLocale.locale);

  return <>{props.children}</>;
};

const mapStateToProps = ({ appReducer }: AppState) => ({
  language: appReducer.language
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ doRetryServerActions }, dispatch);

type MomentLanguageReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MomentLanguage);
