import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { PostingEmployeesReport } from './interfaces';
import {
  SavePostingEmployeesReportFailureAction,
  SavePostingEmployeesReportSuccessAction,
  ADD_POSTING_EMPLOYEES_REPORT,
  ADD_POSTING_EMPLOYEES_REPORT_FAILURE,
  ADD_POSTING_EMPLOYEES_REPORT_SUCCESS,
  RetrievePostingEmployeesReportFailureAction,
  RetrievePostingEmployeesReportSuccessAction,
  RETRIEVE_POSTING_EMPLOYEES_REPORT,
  RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE,
  RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS
} from './types';

export interface ReportReducerState {
  loadingReport: boolean;
  savingReport: boolean;
  error?: RequestResult;
  postingEmployeesReport?: PostingEmployeesReport;
}

const initialState: ReportReducerState = {
  loadingReport: false,
  savingReport: true
};

export default createReducer<ReportReducerState>(initialState, {
  [ADD_POSTING_EMPLOYEES_REPORT]: state => {
    state.savingReport = true;
    state.error = undefined;
  },
  [ADD_POSTING_EMPLOYEES_REPORT_SUCCESS]: (state, action: SavePostingEmployeesReportSuccessAction) => {
    state.savingReport = false;
    state.error = undefined;
    state.postingEmployeesReport = action.postingEmployeesReport;
  },
  [ADD_POSTING_EMPLOYEES_REPORT_FAILURE]: (state, action: SavePostingEmployeesReportFailureAction) => {
    state.savingReport = false;
    state.postingEmployeesReport = undefined;
    state.error = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_POSTING_EMPLOYEES_REPORT]: state => {
    state.loadingReport = true;
    state.error = undefined;
  },
  [RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS]: (state, action: RetrievePostingEmployeesReportSuccessAction) => {
    state.loadingReport = false;
    state.error = undefined;
    state.postingEmployeesReport = action.postingEmployeesReport;
  },
  [RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE]: (state, action: RetrievePostingEmployeesReportFailureAction) => {
    state.loadingReport = false;
    state.postingEmployeesReport = undefined;
    state.error = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
