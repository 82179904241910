import axios from 'axios';
import getConfig from 'next/config';
import { BusinessOpportunityDetailsMMBrain } from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveBusinessOpportunity(accessToken: string, businessOpportunityId: string, locale: string) {
  const url = `${publicRuntimeConfig.API_URI}/v1/${locale}/mm-brain-api/business-opportunities-details?id=${businessOpportunityId}`;
  const response = axios.get<BusinessOpportunityDetailsMMBrain>(url, {
    headers: { accept: 'application/json', Authorization: `Bearer ${accessToken}` }
  });
  return response;
}
