import axios from 'axios';
import getConfig from 'next/config';
import { AddWatchlistCommentDto, SupsWatchlistCommentDetails, SupsWatchlistCommentRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveWatchlistComments(accessToken: string, locale: string, watchlistId: number) {
  return axios.get<RequestResult<SupsWatchlistCommentRaw[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/comments`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function addWatchlistComment(
  accessToken: string,
  locale: string,
  watchlistId: number,
  data: AddWatchlistCommentDto
) {
  return axios.post<RequestResult<SupsWatchlistCommentDetails>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/comments`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function removeWatchlistComment(accessToken: string, locale: string, watchlistId: number, commentId: number) {
  return axios.delete<RequestResult<SupsWatchlistCommentRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/comments/${commentId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}
