import {
  RETRIEVE_BUSINESS_OPPORTUNITY,
  RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE,
  RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS,
  RetrieveBusinessOpportunityAction,
  RetrieveBusinessOpportunityFailureAction,
  RetrieveBusinessOpportunitySuccessAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { BusinessOpportunityDetailsMMBrain } from './interfaces';

export const doRetrieveBusinessOpportunity = (
  businessOpportunityId: string,
  //if set to true, omits title and file attachdements
  asACopy = false
): RetrieveBusinessOpportunityAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITY,
  businessOpportunityId,
  asACopy
});

export const doRetrieveBusinessOpportunityFailure = (
  error?: RequestResult<void>
): RetrieveBusinessOpportunityFailureAction => ({
  error,
  type: RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE
});

export const doRetrieveBusinessOpportunitySuccess = (
  businessOpportunityDetails: BusinessOpportunityDetailsMMBrain
): RetrieveBusinessOpportunitySuccessAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS,
  businessOpportunityDetails
});
