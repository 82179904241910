import {
  RETRIEVE_CPVCODES,
  RETRIEVE_CPVCODES_FAILURE,
  RETRIEVE_CPVCODES_SUCCESS,
  RetrieveCPVCodesAction,
  RetrieveCPVCodesFailureAction,
  RetrieveCPVCodesSuccessAction
} from './types';
import { CPVCode } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveCPVCodes = (industryIds?: string): RetrieveCPVCodesAction => ({
  type: RETRIEVE_CPVCODES,
  industryIds: industryIds
});

export const doRetrieveCPVCodesFailure = (error?: RequestResult<void>): RetrieveCPVCodesFailureAction => ({
  error,
  type: RETRIEVE_CPVCODES_FAILURE
});

export const doRetrieveCPVCodesSuccess = (cPVCodes: CPVCode[]): RetrieveCPVCodesSuccessAction => ({
  type: RETRIEVE_CPVCODES_SUCCESS,
  cPVCodes: cPVCodes
});
