import axios from 'axios';
import moment from 'moment';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { CMSEvent } from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveMarketEvents(accessToken: string, locale: string, slug: string) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/cms/events`, {
    params: { market: slug },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveRelevantUserMarketEvents(accessToken: string, locale: string, slug: string) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/cms/events/user`, {
    params: { market: slug },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveEvents(limit: number, offset: number, accessToken: string, locale: string) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/cms/events`, {
    params: { limit, offset },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveMyUpcomingEvents(
  limit: number,
  offset: number,
  slug: string,
  accessToken: string,
  locale: string
) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/events`, {
    params: { limit, offset, market: slug, 'date[start]': moment().format('YYYY-MM-DD') },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveMyPastEvents(limit: number, offset: number, accessToken: string, locale: string) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/events`, {
    params: { limit, offset, 'date[end]': moment().subtract(1, 'day').format('YYYY-MM-DD') },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveUserCustomEvents(limit: number, offset: number, accessToken: string, locale: string) {
  return axios.get<RequestResult<CMSEvent[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/cms/events/user`, {
    params: { limit, offset },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function selectEvent(locale: string, drupalNodeId: string | number, accessToken?: string) {
  return axios.get<RequestResult<CMSEvent>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/cms/events/${drupalNodeId}`,
    accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : undefined
  );
}
