import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { PostingEmployeesReport } from './interfaces';

const { publicRuntimeConfig } = getConfig();

/**
 *
 * @param accessToken
 * @param locale
 */
export function retrievePostingEmployeesReport(accessToken: string, locale: string) {
  return axios.get<RequestResult<PostingEmployeesReport>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/posting-employees-reports/last`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

/**
 *
 * @param accessToken
 * @param locale
 * @param postingReasonId
 * @param targetMarketId
 * @param postingNationalityId
 * @param postingEmployeesDurationId
 */
export function savePostingEmployeesReport(
  accessToken: string,
  locale: string,
  postingReasonId?: string,
  targetMarketId?: string,
  postingNationalityId?: string,
  postingEmployeesDurationId?: string
) {
  return axios.post<RequestResult<PostingEmployeesReport>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/posting-employees-reports`,
    {
      postingReasonId,
      targetMarketId,
      postingNationalityId,
      postingEmployeesDurationId
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {}
    }
  );
}
