import axios from 'axios';
import getConfig from 'next/config';
import { Question } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

/**
 * @TODO : Refactor with actual http requests
 */
export function retrieveQuestions(accessToken: string, locale: string) {
  return axios.get<RequestResult<Question[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/questions`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
