import {
  RETRIEVE_COUNTRIES,
  RETRIEVE_COUNTRIES_FAILURE,
  RETRIEVE_COUNTRIES_SUCCESS,
  RetrieveCountriesAction,
  RetrieveCountriesFailureAction,
  RetrieveCountriesSuccessAction
} from './types';
import { Country } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveCountries = (): RetrieveCountriesAction => ({
  type: RETRIEVE_COUNTRIES
});

export const doRetrieveCountriesFailure = (error?: RequestResult<void>): RetrieveCountriesFailureAction => ({
  error,
  type: RETRIEVE_COUNTRIES_FAILURE
});

export const doRetrieveCountriesSuccess = (countries: Country[]): RetrieveCountriesSuccessAction => ({
  type: RETRIEVE_COUNTRIES_SUCCESS,
  countries: countries
});
