import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { PublishOnBackendQueueAction } from './types';

const { publicRuntimeConfig } = getConfig();

export const publish = (
  locale: string,
  event: PublishOnBackendQueueAction['event'],
  variables: PublishOnBackendQueueAction['variables'],
  accessToken: string
) => {
  return axios.post<RequestResult>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/queueing/publish`,
    { event, variables },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
};
