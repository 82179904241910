import axios from 'axios';
import getConfig from 'next/config';
import { AddWatchlistEditorDto, SupsWatchlistEditorDetails, SupsWatchlistEditorRaw } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '@redux-modules/user/interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveWatchlistEditors(accessToken: string, locale: string, watchlistId: number) {
  return axios.get<RequestResult<SupsWatchlistEditorRaw[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/editors`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function retrieveAvailableWatchlistEditors(accessToken: string, locale: string, watchlistId: number) {
  return axios.get<RequestResult<User[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/editors`,
    {
      params: {
        availableOnly: true
      },
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function addWatchlistEditor(
  accessToken: string,
  locale: string,
  watchlistId: number,
  data: AddWatchlistEditorDto
) {
  return axios.post<RequestResult<SupsWatchlistEditorDetails>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/editors`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function removeWatchlistEditor(accessToken: string, locale: string, watchlistId: number, editorId: number) {
  return axios.delete<RequestResult<SupsWatchlistEditorRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/editors/${editorId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}
