import React from 'react';
import { Container } from '@components';
import ReactNotification from 'react-notifications-component';

const NotificationContainer: React.FC = () => {
  return (
    <Container>
      <ReactNotification />
    </Container>
  );
};

export default NotificationContainer;
