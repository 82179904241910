import cookie from 'js-cookie';
import { createReducer } from 'redux-starter-kit';
import {
  EditTranslationAction,
  EDIT_TRANSLATION,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_FAILED,
  UPDATE_TRANSLATION_SUCESS,
  REFRESHING_APP_SUCESS,
  UPDATE_TRANSLATION_CANCELLED,
  SetAdminModeAction,
  SET_ADMIN_MODE,
  AdminMode,
  RETRIEVE_TRANSLATIONS_SUCCESS,
  RetrieveTranslationsSuccessAction,
  RETRIEVE_TRANSLATIONS,
  RetrieveTranslationsAction,
  UpdateTranslationSuccessAction
} from './types';

export interface AppReducerState {
  language?: string;
  currentTranslationKey?: string | null;
  translationUpdatingStatus?:
    | 'refreshing-app-success'
    | 'storing-in-db-success'
    | 'failed'
    | 'updating-translation'
    | 'editing'
    | '';
  adminMode?: AdminMode;
  translations: Record<string, string | never>;
}

const initialState: AppReducerState = {
  currentTranslationKey: null,
  translationUpdatingStatus: '',
  translations: {}
};

export default createReducer<AppReducerState>(initialState, {
  [SET_ADMIN_MODE]: (state, action: SetAdminModeAction) => {
    state.adminMode = action.adminMode;
  },
  [EDIT_TRANSLATION]: (state, action: EditTranslationAction) => {
    state.currentTranslationKey = action.translationKey;
    state.translationUpdatingStatus = 'editing';
  },
  [UPDATE_TRANSLATION]: state => {
    state.translationUpdatingStatus = 'updating-translation';
  },
  [UPDATE_TRANSLATION_SUCESS]: (state, action: UpdateTranslationSuccessAction) => {
    state.translationUpdatingStatus = 'storing-in-db-success';
    state.translations = {
      ...state.translations,
      [action.currentTranslationKey]: action.newValue
    };
  },
  [UPDATE_TRANSLATION_CANCELLED]: state => {
    state.currentTranslationKey = null;
    state.translationUpdatingStatus = '';
  },
  [REFRESHING_APP_SUCESS]: state => {
    state.currentTranslationKey = null;
    state.translationUpdatingStatus = 'refreshing-app-success';
  },
  [UPDATE_TRANSLATION_FAILED]: state => {
    state.currentTranslationKey = null;
    state.translationUpdatingStatus = 'failed';
  },
  [RETRIEVE_TRANSLATIONS]: (state, action: RetrieveTranslationsAction) => {
    state.language = action.language;
    cookie.set('locale', action.language, { expires: 1 });
  },
  [RETRIEVE_TRANSLATIONS_SUCCESS]: (state, action: RetrieveTranslationsSuccessAction) => {
    state.translations = action.translations;
  }
});
