import {
  RETRIEVE_CONSULTING_PACKAGES,
  RETRIEVE_CONSULTING_PACKAGES_SUCCESS,
  RETRIEVE_CONSULTING_PACKAGES_FAILURE,
  RetrieveConsultingPackagesAction,
  RetrieveConsultingPackagesSuccessAction,
  RetrieveConsultingPackagesFailureAction,
  SendConfirmationMailToConsultantAction,
  SEND_CONFIRMATION_MAIL_TO_CONSULTANT
} from './types';
import { ConsultingPackage } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveConsultingPackages = (): RetrieveConsultingPackagesAction => ({
  type: RETRIEVE_CONSULTING_PACKAGES
});

export const doRetrieveConsultingPackagesFailure = (
  error?: RequestResult<void>
): RetrieveConsultingPackagesFailureAction => ({
  error,
  type: RETRIEVE_CONSULTING_PACKAGES_FAILURE
});

export const doRetrieveConsultingPackagesSuccess = (
  consultingPackages: ConsultingPackage[]
): RetrieveConsultingPackagesSuccessAction => ({
  type: RETRIEVE_CONSULTING_PACKAGES_SUCCESS,
  consultingPackages
});

export const doSendConfirmationMailToConsultant = (
  consultingPackageId?: string,
  consultantId?: string,
  linkToConsultingPackageDetailPage?: string,
  selectedCountryId?: string
): SendConfirmationMailToConsultantAction => ({
  consultingPackageId,
  consultantId,
  linkToConsultingPackageDetailPage,
  selectedCountryId,
  type: SEND_CONFIRMATION_MAIL_TO_CONSULTANT
});
