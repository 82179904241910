import axios from 'axios';
import getConfig from 'next/config';
import { PostingEmployeesDuration } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrievePostingEmployeesDurations(accessToken: string, locale: string) {
  return axios.get<RequestResult<PostingEmployeesDuration[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/posting-employees-durations`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
