import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { Task } from './interfaces';
import {
  RETRIEVE_TASKS,
  RETRIEVE_TASKS_FAILURE,
  RETRIEVE_TASKS_SUCCESS,
  CLEAR_EXPORT_HSCODEMARKET,
  SET_EXPORT_HSCODEMARKET,
  SET_TODO_DONE,
  RetrieveNavigatorTasksFailureAction,
  RetrieveNavigatorTasksSuccessAction,
  SetExportHSCodeMarketsAction,
  SetTodoDoneAction
} from './types';
import { Market } from '../markets/interfaces';
import { HSCode } from '../product/interfaces';

export interface ExportNavigatorReducerState {
  loading: boolean;
  error?: RequestResult;
  tasks: Task[];
  market?: Market | null;
  hscode?: HSCode | null;
}

const initialState: ExportNavigatorReducerState = {
  loading: false,
  tasks: [],
  market: null,
  hscode: null
};

export default createReducer<ExportNavigatorReducerState>(initialState, {
  [SET_EXPORT_HSCODEMARKET]: (state, action: SetExportHSCodeMarketsAction) => {
    state.market = action.market;
    state.hscode = action.hscode;
  },
  [CLEAR_EXPORT_HSCODEMARKET]: state => {
    state.market = undefined;
    state.hscode = null;
  },
  [RETRIEVE_TASKS]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [RETRIEVE_TASKS_SUCCESS]: (state, action: RetrieveNavigatorTasksSuccessAction) => {
    state.loading = false;
    state.error = undefined;
    state.tasks = action.tasks;
  },
  [RETRIEVE_TASKS_FAILURE]: (state, action: RetrieveNavigatorTasksFailureAction) => {
    state.loading = false;
    state.error = undefined;
    state.tasks = [];
    if (action.error) {
      state.error = action.error;
    }
  },
  [SET_TODO_DONE]: (state, action: SetTodoDoneAction) => {
    const setSelectedTaskTodo = (taskList: Task[]) => {
      // Get the index of the task and then its value
      const taskIndex = taskList.findIndex(x => x.id === action.task.id);
      if (taskIndex > -1) {
        const task: Task = { ...taskList[taskIndex] };

        // Update if the task is selected or not
        task.done = action.checked;

        // Updates the array on this specific index
        taskList[taskIndex] = {
          ...task
        };
      } else {
        taskList = [
          ...taskList,
          {
            ...action.task,
            done: action.checked
          }
        ];
      }

      return taskList;
    };

    state.tasks = setSelectedTaskTodo(state.tasks ? state.tasks : []);
  }
});
