// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { createUpdateBOSearchCriteria, deleteBOSearchCriteria, retrieveBOSearchCriterias } from './operations';

// Types
import { AppState } from '../../reducers';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doCreateUpdateBOSearchCriteria,
  doCreateUpdateBOSearchCriteriaSuccess,
  doCreateUpdateBOSearchCriteriaFailure,
  doRetrieveBOSearchCriteriaFailure,
  doRetrieveBOSearchCriteria,
  doRetrieveBOSearchCriteriaSuccess,
  doDeleteBOSearchCriteriaSuccess,
  doDeleteBOSearchCriteriaFailure,
  doDeleteBOSearchCriteria
} from './actions';
import {
  CREATEUPDATE_BO_SEARCH_CRITERIA,
  CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE,
  RETRIEVE_BO_SEARCH_CRITERIAS,
  CreateUpdateBOSearchCriteriaAction,
  DeleteBOSearchCriteriaAction,
  DELETE_BO_SEARCH_CRITERIA_FAILURE,
  DELETE_BO_SEARCH_CRITERIA
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { BOSearchCriteria } from './interfaces';

//Create + Update
function* workerCreateUpdateBOSearchCriteria({ bOSearchCriteriaToBeStored }: CreateUpdateBOSearchCriteriaAction) {
  try {
    const locale: string = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<BOSearchCriteria>> = yield call(
        createUpdateBOSearchCriteria,
        profile.accessToken,
        locale,
        bOSearchCriteriaToBeStored
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doCreateUpdateBOSearchCriteriaSuccess(bOSearchCriteriaToBeStored, response.data.result));
      } else {
        yield put(doCreateUpdateBOSearchCriteriaFailure(bOSearchCriteriaToBeStored));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doCreateUpdateBOSearchCriteria(bOSearchCriteriaToBeStored))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doCreateUpdateBOSearchCriteria(bOSearchCriteriaToBeStored))
      );
    } else {
      logging.error(error);
      yield put({
        type: CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE,
        error
      });
    }
  }
}

export function* watcherCreateUpdateBOSearchCriteria() {
  yield takeLatest(CREATEUPDATE_BO_SEARCH_CRITERIA, workerCreateUpdateBOSearchCriteria);
}

//Delete
function* workerDeleteBOSearchCriteria({ bOSearchCriteriaTypeToBeDeleted }: DeleteBOSearchCriteriaAction) {
  try {
    const locale: string = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<null>> = yield call(
        deleteBOSearchCriteria,
        profile.accessToken,
        locale,
        bOSearchCriteriaTypeToBeDeleted
      );
      if (response.data && response.data.succeeded) {
        yield put(doDeleteBOSearchCriteriaSuccess(bOSearchCriteriaTypeToBeDeleted));
        yield put(doRetrieveBOSearchCriteria());
      } else {
        yield put(doDeleteBOSearchCriteriaFailure(bOSearchCriteriaTypeToBeDeleted));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doDeleteBOSearchCriteria(bOSearchCriteriaTypeToBeDeleted))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doDeleteBOSearchCriteria(bOSearchCriteriaTypeToBeDeleted))
      );
    } else {
      logging.error(error);
      yield put({
        type: DELETE_BO_SEARCH_CRITERIA_FAILURE,
        error
      });
    }
  }
}

export function* watcherDeleteBOSearchCriteria() {
  yield takeLatest(DELETE_BO_SEARCH_CRITERIA, workerDeleteBOSearchCriteria);
}

//List
function* workerRetrieveBOSearchCriteria() {
  try {
    const locale: string = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<BOSearchCriteria[]>> = yield call(
        retrieveBOSearchCriterias,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result != null) {
        yield put(doRetrieveBOSearchCriteriaSuccess(response.data.result));
      } else {
        yield put(doRetrieveBOSearchCriteriaFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveBOSearchCriteria()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveBOSearchCriteria()));
    } else {
      logging.error(error);
      yield put(doRetrieveBOSearchCriteriaFailure());
    }
  }
}

export function* watcherRetrieveBOSearchCriteria() {
  yield takeLatest(RETRIEVE_BO_SEARCH_CRITERIAS, workerRetrieveBOSearchCriteria);
}

export function* bOSearchCriteriaSaga() {
  yield all([
    call(watcherRetrieveBOSearchCriteria),
    call(watcherDeleteBOSearchCriteria),
    call(watcherCreateUpdateBOSearchCriteria)
  ]);
}
