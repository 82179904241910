import axios from 'axios';
import getConfig from 'next/config';
import { SupsRecord, SupsResult } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function searchSups(accessToken: string, locale: string, url: string) {
  return axios.get<RequestResult<SupsResult>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/sups/search`, {
    headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
    params: { query: url }
  });
}

export function getSupsCompany(accessToken: string, locale: string, url: string) {
  return axios.get<RequestResult<SupsRecord>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/sups/record`, {
    headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
    params: { query: url }
  });
}

export function suggestSupsSearch(accessToken: string, locale: string, term: string) {
  return axios.get<RequestResult<SupsResult>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/sups/suggest`, {
    headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
    params: { term }
  });
}
