import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { BOSearchCriteria } from './interfaces';
import {
  CreateUpdateBOSearchCriteriaAction,
  CreateUpdateBOSearchCriteriaFailureAction,
  CreateUpdateBOSearchCriteriaSuccessAction,
  CREATEUPDATE_BO_SEARCH_CRITERIA,
  CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE,
  CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS,
  DELETE_BO_SEARCH_CRITERIA,
  DELETE_BO_SEARCH_CRITERIA_FAILURE,
  DELETE_BO_SEARCH_CRITERIA_SUCCESS,
  DeleteBOSearchCriteriaSuccessAction,
  RetrieveBOSearchCriteriaFailureAction,
  RetrieveBOSearchCriteriaSuccessAction,
  RETRIEVE_BO_SEARCH_CRITERIAS,
  RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE,
  RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS,
  DeleteBOSearchCriteriaAction,
  DeleteBOSearchCriteriaFailureAction
} from './types';

export interface BOSearchCriteriaReducerState {
  //Create + Update
  creatingUpdatingMailBOSearchCriteria: boolean;
  creatingUpdatingMailError?: RequestResult;

  creatingUpdatingOverviewBOSearchCriteria: boolean;
  creatingUpdatingOverviewError?: RequestResult;

  //Delete
  deletinggMailBOSearchCriteria: boolean;
  deletingMailError?: RequestResult;
  deletingOverviewBOSearchCriteria: boolean;
  deletingOverviewError?: RequestResult;

  //List
  loadingBOSearchCriterias: boolean;
  loadingError?: RequestResult;

  //Data
  mailBOSearchCriterias?: BOSearchCriteria[];
  overviewBOSearchCriterias?: BOSearchCriteria[];
}

const initialState: BOSearchCriteriaReducerState = {
  loadingBOSearchCriterias: false,
  creatingUpdatingMailBOSearchCriteria: false,
  creatingUpdatingOverviewBOSearchCriteria: false,
  deletinggMailBOSearchCriteria: false,
  deletingOverviewBOSearchCriteria: false
};

export default createReducer<BOSearchCriteriaReducerState>(initialState, {
  //Create + Update
  [CREATEUPDATE_BO_SEARCH_CRITERIA]: (state, action: CreateUpdateBOSearchCriteriaAction) => {
    if (action.bOSearchCriteriaToBeStored.type == 'email-notifications-criterias') {
      state.creatingUpdatingMailBOSearchCriteria = true;
      state.creatingUpdatingMailError = undefined;
    }
    if (action.bOSearchCriteriaToBeStored.type == 'overview-search-criterias') {
      state.creatingUpdatingOverviewBOSearchCriteria = true;
      state.creatingUpdatingOverviewError = undefined;
    }
  },
  [CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS]: (state, action: CreateUpdateBOSearchCriteriaSuccessAction) => {
    if (action.bOSearchCriteriaToBeStored.type == 'email-notifications-criterias') {
      state.creatingUpdatingMailBOSearchCriteria = false;
      state.creatingUpdatingMailError = undefined;
      state.mailBOSearchCriterias = [action.bOSearchCriteriaToBeStored];
    }
    if (action.bOSearchCriteriaToBeStored.type == 'overview-search-criterias') {
      state.creatingUpdatingOverviewBOSearchCriteria = false;
      state.creatingUpdatingOverviewError = undefined;
      state.overviewBOSearchCriterias = [action.bOSearchCriteriaToBeStored];
    }
  },
  [CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE]: (state, action: CreateUpdateBOSearchCriteriaFailureAction) => {
    if (action.bOSearchCriteriaToBeStored.type == 'email-notifications-criterias') {
      state.creatingUpdatingMailBOSearchCriteria = false;
      state.creatingUpdatingMailError = undefined;
      //  state.overviewBOSearchCriterias = undefined;
      if (action.error) {
        state.creatingUpdatingMailError = action.error;
      }
    }
    if (action.bOSearchCriteriaToBeStored.type == 'overview-search-criterias') {
      state.creatingUpdatingOverviewBOSearchCriteria = false;
      state.creatingUpdatingOverviewError = undefined;
      if (action.error) {
        state.creatingUpdatingOverviewError = action.error;
      }
    }
  },

  //Delete
  [DELETE_BO_SEARCH_CRITERIA]: (state, action: DeleteBOSearchCriteriaAction) => {
    if (action.bOSearchCriteriaTypeToBeDeleted == 'email-notifications-criterias') {
      state.deletinggMailBOSearchCriteria = true;
      state.deletingMailError = undefined;
    }
    if (action.bOSearchCriteriaTypeToBeDeleted == 'overview-search-criterias') {
      state.deletingOverviewBOSearchCriteria = true;
      state.deletingOverviewError = undefined;
    }
  },
  [DELETE_BO_SEARCH_CRITERIA_SUCCESS]: (state, action: DeleteBOSearchCriteriaSuccessAction) => {
    if (action.bOSearchCriteriaTypeToBeDeleted == 'email-notifications-criterias') {
      state.deletinggMailBOSearchCriteria = false;
      state.deletingMailError = undefined;
    }
    if (action.bOSearchCriteriaTypeToBeDeleted == 'overview-search-criterias') {
      state.deletingOverviewBOSearchCriteria = false;
      state.deletingOverviewError = undefined;
    }
  },
  [DELETE_BO_SEARCH_CRITERIA_FAILURE]: (state, action: DeleteBOSearchCriteriaFailureAction) => {
    if (action.bOSearchCriteriaTypeToBeDeleted == 'email-notifications-criterias') {
      state.deletinggMailBOSearchCriteria = false;
      state.deletingMailError = undefined;
      if (action.error) {
        state.deletingMailError = action.error;
      }
    }
    if (action.bOSearchCriteriaTypeToBeDeleted == 'overview-search-criterias') {
      state.deletingOverviewBOSearchCriteria = false;
      state.deletingOverviewError = undefined;
      if (action.error) {
        state.deletingOverviewError = action.error;
      }
    }
  },

  //List
  [RETRIEVE_BO_SEARCH_CRITERIAS]: state => {
    state.loadingBOSearchCriterias = true;
    state.loadingError = undefined;
  },
  [RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS]: (state, action: RetrieveBOSearchCriteriaSuccessAction) => {
    state.loadingBOSearchCriterias = false;
    state.loadingError = undefined;
    state.mailBOSearchCriterias = action.bOSearchCriterias.filter(
      criteria => criteria.type == 'email-notifications-criterias'
    );
    state.overviewBOSearchCriterias = action.bOSearchCriterias.filter(
      criteria => criteria.type == 'overview-search-criterias'
    );
  },
  [RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE]: (state, action: RetrieveBOSearchCriteriaFailureAction) => {
    state.loadingBOSearchCriterias = false;
    state.mailBOSearchCriterias = undefined;
    state.overviewBOSearchCriterias = undefined;
    state.loadingError = undefined;
    if (action.error) {
      state.loadingError = action.error;
    }
  }
});
