import axios from 'axios';
import getConfig from 'next/config';
import { Industry } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveIndustries(accessToken: string, locale: string) {
  return axios.get<RequestResult<Industry[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/industries`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
