import {
  RETRIEVE_MARKET_PUBLICATIONS,
  RETRIEVE_MARKET_PUBLICATIONS_SUCCESS,
  RETRIEVE_MARKET_PUBLICATIONS_FAILURE,
  RetrieveMarketPublicationsAction,
  RetrieveMarketPublicationsSuccessAction,
  RetrieveMarketPublicationsFailureAction
} from './types';
import { CMSPublication } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveMarketPublications = (slug: string): RetrieveMarketPublicationsAction => ({
  type: RETRIEVE_MARKET_PUBLICATIONS,
  slug
});

export const doRetrieveMarketPublicationsSuccess = (
  publications: CMSPublication[]
): RetrieveMarketPublicationsSuccessAction => ({
  type: RETRIEVE_MARKET_PUBLICATIONS_SUCCESS,
  publications
});

export const doRetrieveMarketPublicationsFailure = (
  error?: RequestResult<void>
): RetrieveMarketPublicationsFailureAction => ({
  type: RETRIEVE_MARKET_PUBLICATIONS_FAILURE,
  error
});
