import { all, call } from 'redux-saga/effects';
import { actionsSaga } from './modules/actions/sagas';
import { authSaga } from './modules/auth/sagas';
import { userSaga } from './modules/user/sagas';
import { marketsSaga } from './modules/markets/sagas';
import { companySaga } from './modules/company/sagas';
import { industrySaga } from './modules/industry/sagas';
import { sectorSaga } from './modules/sector/sagas';
import { businessTypeSaga } from './modules/businessType/sagas';
import { consultingPackagesConditionSaga } from './modules/consultingPackagesCondition/sagas';
import { productSaga } from './modules/product/sagas';
import { eventSaga } from './modules/event/sagas';
import { articleSaga } from './modules/article/sagas';
import { businessOpportunitySaga } from './modules/businessOpportunityMMBrain/sagas';
import { dashboardSaga } from './modules/dashboard/sagas';
import { onboardingSaga } from './modules/onboarding/sagas';
import { marketGuideSaga } from './modules/marketGuide/sagas';
import { consultantSaga } from './modules/consultant/sagas';
import { publicationSaga } from './modules/publication/sagas';
import { cachedMarketListSaga } from './modules/cachedMarketList/sagas';
import { tariffTaxesSaga } from './modules/tariffTaxes/sagas';
import { exportNavigatorSaga } from './modules/exportNavigator/sagas';
import { questionsSaga } from './modules/questions/sagas';
import { reportSaga } from './modules/report/sagas';
import { appSaga } from './modules/app/sagas';
import { queueingSaga } from './modules/queueing/sagas';
import { oecSaga } from './modules/oec/sagas';
import { postingReasonSaga } from './modules/postingReason/sagas';
import { postingNationalitySaga } from './modules/postingNationality/sagas';
import { postingEmployeesDurationSaga } from './modules/postingEmployeesDuration/sagas';
import { postingEmployeesContentSaga } from './modules/postingEmployeesContent/sagas';
import { postingEmployeesReportSaga } from './modules/postingEmployeesReport/sagas';
import { consultingPackageSaga } from './modules/consultingPackage/sagas';
import { businessOpportunityTypeSaga } from './modules/businessOpportunityType/sagas';
import { workTypeSaga } from './modules/workType/sagas';
import { bOSearchCriteriaSaga } from './modules/bOSearchCriteria/sagas';
import { businessOpportunityDetailsMMBrainSaga } from './modules/businessOpportunityDetailsMMBrain/sagas';
import { cPVCodesSaga } from './modules/cPVCode/sagas';
import { countrySaga } from './modules/country/sagas';
import { supsSaga } from './modules/sups/sagas';
import { watchlistSaga } from './modules/watchlists/sagas';
import { watchlistCompanySaga } from './modules/watchlistCompanies/sagas';
import { watchlistEditorSaga } from './modules/watchlistEditors/sagas';
import { watchlistCommentSaga } from './modules/watchlistComments/sagas';

export function* rootSaga() {
  yield all([
    call(actionsSaga),
    call(authSaga),
    call(userSaga),
    call(companySaga),
    call(industrySaga),
    call(sectorSaga),
    call(businessTypeSaga),
    call(consultingPackagesConditionSaga),
    call(postingReasonSaga),
    call(postingNationalitySaga),
    call(postingEmployeesDurationSaga),
    call(postingEmployeesContentSaga),
    call(postingEmployeesReportSaga),
    call(productSaga),
    call(marketsSaga),
    call(eventSaga),
    call(articleSaga),
    call(dashboardSaga),
    call(marketGuideSaga),
    call(onboardingSaga),
    call(consultantSaga),
    call(publicationSaga),
    call(cachedMarketListSaga),
    call(tariffTaxesSaga),
    call(exportNavigatorSaga),
    call(questionsSaga),
    call(reportSaga),
    call(appSaga),
    call(queueingSaga),
    call(oecSaga),
    call(consultingPackageSaga),
    call(businessOpportunityTypeSaga),
    call(workTypeSaga),
    call(bOSearchCriteriaSaga),
    call(cPVCodesSaga),
    call(countrySaga),
    call(businessOpportunityDetailsMMBrainSaga),
    call(businessOpportunitySaga),
    call(supsSaga),
    call(watchlistSaga),
    call(watchlistCompanySaga),
    call(watchlistEditorSaga),
    call(watchlistCommentSaga)
  ]);
}
