import {
  ToggleSelectMarketAction,
  RETRIEVE_USER_MARKETS,
  RetrieveUserMarketsAction,
  RetrieveUserMarketsSuccessAction,
  RETRIEVE_USER_MARKETS_SUCCESS,
  RetrieveUserMarketsFailureAction,
  RETRIEVE_USER_MARKETS_FAILURE,
  TOGGLE_SELECT_MARKET,
  SET_MARKET_SELECTED,
  SetMarketSelectedAction,
  UpdateUserMarketAction,
  UPDATE_USER_MARKET,
  SET_ALL_MARKETS_SORT,
  SetAllMarketsSortAction
} from './types';

export const doToggleSelectMarket = (market: ToggleSelectMarketAction['market']): ToggleSelectMarketAction => ({
  type: TOGGLE_SELECT_MARKET,
  market
});

export const doSetMarketSelected = (
  market: SetMarketSelectedAction['market'],
  isUserSelection: SetMarketSelectedAction['isUserSelection']
): SetMarketSelectedAction => ({
  type: SET_MARKET_SELECTED,
  market,
  isUserSelection: isUserSelection
});

export const doUpdateUserMarket = (market: UpdateUserMarketAction['market']): UpdateUserMarketAction => ({
  type: UPDATE_USER_MARKET,
  market
});

export const doRetrieveUserMarkets = (): RetrieveUserMarketsAction => ({
  type: RETRIEVE_USER_MARKETS
});

export const doRetrieveUserMarketsSuccess = (
  markets: RetrieveUserMarketsSuccessAction['markets']
): RetrieveUserMarketsSuccessAction => ({
  type: RETRIEVE_USER_MARKETS_SUCCESS,
  markets
});

export const doRetrieveUserMarketsFailure = (errorMessageId?: string): RetrieveUserMarketsFailureAction => ({
  type: RETRIEVE_USER_MARKETS_FAILURE,
  errorMessageId
});

export const doSetAllMarketsSort = (
  sortAllMarketsBy: SetAllMarketsSortAction['sortAllMarketsBy']
): SetAllMarketsSortAction => ({
  type: SET_ALL_MARKETS_SORT,
  sortAllMarketsBy
});
