import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { CMSPublication } from './interfaces';
import {
  RetrieveMarketPublicationsFailureAction,
  RetrieveMarketPublicationsSuccessAction,
  RETRIEVE_MARKET_PUBLICATIONS,
  RETRIEVE_MARKET_PUBLICATIONS_FAILURE,
  RETRIEVE_MARKET_PUBLICATIONS_SUCCESS
} from './types';

export interface PublicationsReducerState {
  marketPublications: CMSPublication[];
  marketPublicationsLoading: boolean;
  marketPublicationsError?: RequestResult;
}

const initialState: PublicationsReducerState = {
  marketPublications: [],
  marketPublicationsLoading: false
};

export default createReducer<PublicationsReducerState>(initialState, {
  [RETRIEVE_MARKET_PUBLICATIONS]: state => {
    state.marketPublicationsLoading = true;
  },
  [RETRIEVE_MARKET_PUBLICATIONS_SUCCESS]: (state, action: RetrieveMarketPublicationsSuccessAction) => {
    state.marketPublicationsLoading = false;
    state.marketPublications = action.publications;
    state.marketPublicationsError = undefined;
  },
  [RETRIEVE_MARKET_PUBLICATIONS_FAILURE]: (state, action: RetrieveMarketPublicationsFailureAction) => {
    state.marketPublicationsLoading = false;
    state.marketPublications = [];
    if (action.error) {
      state.marketPublicationsError = action.error;
    }
  }
});
