import { PostingReason } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_POSTING_REASONS = 'postingreasons/RETRIEVE_POSTING_REASONS';
export const RETRIEVE_POSTING_REASONS_SUCCESS = 'postingreasons/RETRIEVE_POSTING_REASONS_SUCCESS';
export const RETRIEVE_POSTING_REASONS_FAILURE = 'postingreasons/RETRIEVE_POSTING_REASONS_FAILURE';

export interface RetrievePostingReasonsAction {
  type: typeof RETRIEVE_POSTING_REASONS;
}

export interface RetrievePostingReasonsSuccessAction {
  type: typeof RETRIEVE_POSTING_REASONS_SUCCESS;
  postingReasons: PostingReason[];
}

export interface RetrievePostingReasonsFailureAction {
  type: typeof RETRIEVE_POSTING_REASONS_FAILURE;
  error?: RequestResult<void>;
}
