import axios from 'axios';
import getConfig from 'next/config';
import { CPVCode } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveCPVCodes(accessToken: string, locale: string, industryIds?: string) {
  const result = axios.get<RequestResult<CPVCode[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/cpvcodes/by-industries${
      industryIds != undefined && industryIds != '' ? '?industries=' + industryIds : ''
    }`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );

  return result;
}
