import {
  RETRIEVE_TASKS,
  RETRIEVE_TASKS_SUCCESS,
  RETRIEVE_TASKS_FAILURE,
  SET_EXPORT_HSCODEMARKET,
  CLEAR_EXPORT_HSCODEMARKET,
  RetrieveNavigatorTasksAction,
  RetrieveNavigatorTasksSuccessAction,
  RetrieveNavigatorTasksFailureAction,
  SetExportHSCodeMarketsAction,
  ClearExportHSCodeMarketsAction,
  ToggleTodoDoneAction,
  SetTodoDoneAction,
  TOGGLE_TODO_DONE,
  SET_TODO_DONE
} from './types';
import { Task } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveTasks = (): RetrieveNavigatorTasksAction => ({
  type: RETRIEVE_TASKS
});

export const doRetrieveTasksSuccess = (tasks: Task[]): RetrieveNavigatorTasksSuccessAction => ({
  type: RETRIEVE_TASKS_SUCCESS,
  tasks
});

export const doRetrieveTasksFailure = (error?: RequestResult<void>): RetrieveNavigatorTasksFailureAction => ({
  type: RETRIEVE_TASKS_FAILURE,
  error
});

export const setMarketHSCode = (market: any, hscode: any): SetExportHSCodeMarketsAction => ({
  type: SET_EXPORT_HSCODEMARKET,
  market,
  hscode
});

export const clearMarketHSCode = (): ClearExportHSCodeMarketsAction => ({
  type: CLEAR_EXPORT_HSCODEMARKET
});

export const doToggleTodoDone = (
  task: ToggleTodoDoneAction['task'],
  checked: ToggleTodoDoneAction['checked']
): ToggleTodoDoneAction => ({
  type: TOGGLE_TODO_DONE,
  task,
  checked
});

export const doSetTodoDone = (
  task: SetTodoDoneAction['task'],
  checked: SetTodoDoneAction['checked']
): SetTodoDoneAction => ({
  type: SET_TODO_DONE,
  task,
  checked
});
