import axios from 'axios';
import getConfig from 'next/config';
import { BusinessType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveBusinessTypes(accessToken: string, locale: string) {
  return axios.get<RequestResult<BusinessType[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/business-types`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
