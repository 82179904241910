import { WorkType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const WORK_TYPES = 'worktypes/WORK_TYPES';
export const WORK_TYPES_SUCCESS = 'worktypes/WORK_TYPES_SUCCESS';
export const WORK_TYPES_FAILURE = 'worktypes/WORK_TYPES_FAILURE';

export interface RetrieveWorkTypesAction {
  type: typeof WORK_TYPES;
}

export interface RetrieveWorkTypesSuccessAction {
  type: typeof WORK_TYPES_SUCCESS;
  workTypes: WorkType[];
}

export interface RetrieveWorkTypesFailureAction {
  type: typeof WORK_TYPES_FAILURE;
  error?: RequestResult<void>;
}
