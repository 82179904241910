import {
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS,
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE,
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS,
  RetrievePostingEmployeesDurationsAction,
  RetrievePostingEmployeesDurationsFailureAction,
  RetrievePostingEmployeesDurationsSuccessAction
} from './types';
import { PostingEmployeesDuration } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrievePostingEmployeesDurations = (): RetrievePostingEmployeesDurationsAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_DURATIONS
});

export const doRetrievePostingEmployeesDurationsFailure = (
  error?: RequestResult<void>
): RetrievePostingEmployeesDurationsFailureAction => ({
  error,
  type: RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE
});

export const doRetrievePostingEmployeesDurationsSuccess = (
  postingEmployeesDurations: PostingEmployeesDuration[]
): RetrievePostingEmployeesDurationsSuccessAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS,
  postingEmployeesDurations
});
