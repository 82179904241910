import {
  RETRIEVE_COMPANY,
  RETRIEVE_COMPANY_FAILURE,
  RETRIEVE_COMPANY_SUCCESS,
  RetrieveCompanyAction,
  RetrieveCompanyFailureAction,
  RetrieveCompanySuccessAction,
  CERTIFY_CRM_COMPANY,
  CERTIFY_CRM_COMPANY_FAILURE,
  CERTIFY_CRM_COMPANY_SUCCESS,
  CertifyCRMCompanyAction,
  CertifyCRMCompanyFailureAction,
  CertifyCRMCompanySuccessAction,
  SAVE_COMPANY,
  SAVE_COMPANY_FAILURE,
  SAVE_COMPANY_SUCCESS,
  SaveCompanyAction,
  SaveCompanyBody,
  SaveCompanyFailureAction,
  SaveCompanySuccessAction,
  SEARCH_COMPANY,
  SEARCH_COMPANY_FAILURE,
  SEARCH_COMPANY_SUCCESS,
  SearchCompanyAction,
  SearchCompanyFailureAction,
  SearchCompanySuccessAction,
  SELECT_COMPANY,
  SelectCompanyAction
} from './types';
import { Company } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveCompany = (): RetrieveCompanyAction => ({
  type: RETRIEVE_COMPANY
});

export const doRetrieveCompanyFailure = (error?: RequestResult<void>): RetrieveCompanyFailureAction => ({
  error,
  type: RETRIEVE_COMPANY_FAILURE
});

export const doRetrieveCompanySuccess = (company: Company): RetrieveCompanySuccessAction => ({
  type: RETRIEVE_COMPANY_SUCCESS,
  company
});

export const doCertifyCRMCompany = (
  duns: string,
  onFinish?: (data: Partial<Company> | null) => void
): CertifyCRMCompanyAction => ({
  type: CERTIFY_CRM_COMPANY,
  duns,
  onFinish
});

export const doCertifyCRMCompanyFailure = (error?: RequestResult<void>): CertifyCRMCompanyFailureAction => ({
  error,
  type: CERTIFY_CRM_COMPANY_FAILURE
});

export const doCertifyCRMCompanySuccess = (company: Partial<Company>): CertifyCRMCompanySuccessAction => ({
  type: CERTIFY_CRM_COMPANY_SUCCESS,
  company
});

export const doSaveCompany = (
  body: SaveCompanyBody,
  onSuccess?: (data: SaveCompanyBody) => void
): SaveCompanyAction => ({
  type: SAVE_COMPANY,
  body,
  onSuccess
});

export const doSaveCompanyFailure = (error?: RequestResult<void>): SaveCompanyFailureAction => ({
  type: SAVE_COMPANY_FAILURE,
  error
});

export const doSaveCompanySuccess = (response: SaveCompanyBody): SaveCompanySuccessAction => ({
  company: response.company,
  user: response.user,
  industries: response.industries,
  type: SAVE_COMPANY_SUCCESS
});

export const doSearchCompany = (companyName: string): SearchCompanyAction => ({
  type: SEARCH_COMPANY,
  companyName
});

export const doSearchCompanyFailure = (error?: RequestResult<void>): SearchCompanyFailureAction => ({
  type: SEARCH_COMPANY_FAILURE,
  error
});

export const doSearchCompanySuccess = (companies: Partial<Company>[]): SearchCompanySuccessAction => ({
  companies,
  type: SEARCH_COMPANY_SUCCESS
});

export const doSelectCompany = (selectedCompany: Partial<Company & { duns: string }> | null): SelectCompanyAction => ({
  selectedCompany,
  type: SELECT_COMPANY
});
