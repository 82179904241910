import { Country } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_COUNTRIES = 'countries/RETRIEVE_COUNTRIES';
export const RETRIEVE_COUNTRIES_SUCCESS = 'countries/RETRIEVE_COUNTRIES_SUCCESS';
export const RETRIEVE_COUNTRIES_FAILURE = 'countries/RETRIEVE_COUNTRIES_FAILURE';

export interface RetrieveCountriesAction {
  type: typeof RETRIEVE_COUNTRIES;
}

export interface RetrieveCountriesSuccessAction {
  type: typeof RETRIEVE_COUNTRIES_SUCCESS;
  countries: Country[];
}

export interface RetrieveCountriesFailureAction {
  type: typeof RETRIEVE_COUNTRIES_FAILURE;
  error?: RequestResult<void>;
}
