import { createReducer } from 'redux-starter-kit';
import { STORE_USER_AUTH, StoreUserAuthAction } from './types';
import { AuthenticatedProfile } from './interfaces';

export interface AuthReducerState {
  profile?: AuthenticatedProfile;
}

const initialState: AuthReducerState = {};

export default createReducer<AuthReducerState>(initialState, {
  [STORE_USER_AUTH]: (state, action: StoreUserAuthAction) => {
    state.profile = action.profile;
  }
});
