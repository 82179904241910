import { Market, SortMarketsEnum } from './interfaces';

export const RETRIEVE_USER_MARKETS = 'markets/RETRIEVE_USER_MARKETS';
export const RETRIEVE_USER_MARKETS_SUCCESS = 'markets/RETRIEVE_USER_MARKETS_SUCCESS';
export const RETRIEVE_USER_MARKETS_FAILURE = 'markets/RETRIEVE_USER_MARKETS_FAILURE';

export const TOGGLE_SELECT_MARKET = 'markets/TOGGLE_SELECT_MARKET';
export const SET_MARKET_SELECTED = 'markets/SET_MARKET_SELECTED';

export const UPDATE_USER_MARKET = 'markets/UPDATE_USER_MARKET';

export const SET_ALL_MARKETS_SORT = 'markets/SET_ALL_MARKETS_SORT';

export interface ToggleSelectMarketAction {
  type: typeof TOGGLE_SELECT_MARKET;
  market: Market;
}

export interface SetMarketSelectedAction {
  type: typeof SET_MARKET_SELECTED;
  market: Market;
  isUserSelection: boolean;
}

export interface RetrieveUserMarketsAction {
  type: typeof RETRIEVE_USER_MARKETS;
}

export interface RetrieveUserMarketsSuccessAction {
  type: typeof RETRIEVE_USER_MARKETS_SUCCESS;
  markets: Market[];
}

export interface RetrieveUserMarketsFailureAction {
  type: typeof RETRIEVE_USER_MARKETS_FAILURE;
  errorMessageId?: string;
}

export interface UpdateUserMarketAction {
  type: typeof UPDATE_USER_MARKET;
  market: Market;
}

export interface SetAllMarketsSortAction {
  type: typeof SET_ALL_MARKETS_SORT;
  sortAllMarketsBy: SortMarketsEnum;
}
