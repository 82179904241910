import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import {
  ConsultantContact,
  MarketGuide,
  MarketProductPotential,
  MarketTrait,
  SwissExport,
  TopExporters
} from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveMarketGuide(accessToken: string, locale: string, slug: string) {
  return axios.get<RequestResult<MarketGuide>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/markets/${slug}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveMarketGuidePublic(locale: string, slug: string) {
  return axios.get<RequestResult<MarketGuide>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/markets/${slug}/public`);
}

export function retrieveTopExporters(accessToken: string, locale: string, marketISOCode: string) {
  const params = { partner: marketISOCode, currency: 'CHF', year: publicRuntimeConfig.TRADE_INSIGHTS_YEAR };
  return axios.get<RequestResult<TopExporters>>(
    `${publicRuntimeConfig.TRADE_INSIGHTS_URI}/${locale}/data/top-exporters-to-country`,
    {
      params,
      headers: { Authorization: `${publicRuntimeConfig.TRADE_INSIGHTS_API_KEY}` }
    }
  );
}

export function retrieveSwissExport(accessToken: string, locale: string, marketISOCode: string) {
  const params = {
    reporter: 'CHE',
    partner: marketISOCode,
    currency: 'CHF',
    year: publicRuntimeConfig.TRADE_INSIGHTS_YEAR
  };
  return axios.get<RequestResult<SwissExport>>(
    `${publicRuntimeConfig.TRADE_INSIGHTS_URI}/${locale}/data/importers/volume/country-to-country`,
    {
      params,
      headers: { Authorization: `${publicRuntimeConfig.TRADE_INSIGHTS_API_KEY}` }
    }
  );
}

export function retrieveSwissStats(accessToken: string, locale: string) {
  return axios.get<MarketTrait[]>(`${publicRuntimeConfig.TRADE_INSIGHTS_URI}/${locale}/countries/switzerland/stats`, {
    headers: { Authorization: `${publicRuntimeConfig.TRADE_INSIGHTS_API_KEY}` },
    params: {
      year: publicRuntimeConfig.TRADE_INSIGHTS_YEAR
    }
  });
}

export function retrieveMarketProductPotential(locale: string, marketISOCode: string, HSCodes: string[]) {
  const params = { HSCodes, currency: 'CHF', reporter: marketISOCode, year: publicRuntimeConfig.TRADE_INSIGHTS_YEAR };
  return axios.get<RequestResult<MarketProductPotential[]>>(
    `${publicRuntimeConfig.TRADE_INSIGHTS_URI}/${locale}/data/market-product-potential`,
    {
      params,
      headers: { Authorization: `${publicRuntimeConfig.TRADE_INSIGHTS_API_KEY}` }
    }
  );
}

export function submitConsultantContact(consultantContact: ConsultantContact, locale: string, accessToken: string) {
  return axios.post<RequestResult<ConsultantContact>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/consultants/contact`,
    consultantContact,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
