import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doAddFailedServerAction } from '../actions/actions';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { Market } from '../markets/interfaces';
import {
  doRetrieveAllCachedMarkets,
  doRetrieveAllCachedMarketsFailure,
  doRetrieveAllCachedMarketsSuccess
} from './actions';
import { getUserMarkets } from './operations';
import { RetrieveAllCachedMarketsAction, RETRIEVE_ALL_CACHED_MARKETS } from './types';

function* workerRetrieveCachedMarkets({}: RetrieveAllCachedMarketsAction) {
  if (process.browser) {
    try {
      const locale = yield select((state: AppState) => state.appReducer.language);
      const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
      const currentList: Market[] = yield select((state: AppState) => state.cachedMarketListReducer.cachedMarketList);

      if (!currentList || currentList.length < 1) {
        if (profile) {
          const response: AxiosResponse<RequestResult<Market[]>> = yield call(
            getUserMarkets,
            profile.accessToken,
            locale
          );

          if (response.data.succeeded && response.data && response.data.result) {
            yield put(
              doRetrieveAllCachedMarketsSuccess(
                response.data.result.map(x => ({
                  name: x.name,
                  slug: x.slug,
                  ISOCode3Letter: x.ISOCode3Letter,
                  ISOCode2Letter: x.ISOCode2Letter
                }))
              )
            );
          }
        } else {
          yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveAllCachedMarkets()));
        }
      } else {
        yield put(doRetrieveAllCachedMarketsSuccess(currentList));
      }
    } catch (error) {
      const parsedError = parseError(error);
      if (parsedError.status === 401) {
        yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveAllCachedMarkets()));
      } else {
        logging.error(error);
        yield put(doRetrieveAllCachedMarketsFailure(error));
      }
    }
  } else {
    // Don't try to fetch on server side, because server side doesn't have cache
    yield put(doAddFailedServerAction(doRetrieveAllCachedMarkets()));
  }
}

export function* watcherRetrieveCachedMarkets() {
  yield takeLatest(RETRIEVE_ALL_CACHED_MARKETS, workerRetrieveCachedMarkets);
}
export function* cachedMarketListSaga() {
  yield all([call(watcherRetrieveCachedMarkets)]);
}
