// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveIndustries } from './operations';

// Types
import { AppState } from '../../reducers';
import { Industry } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import { doRetrieveIndustriesSuccess, doRetrieveIndustries, doRetrieveIndustriesFailure } from './actions';
import { RetrieveIndustriesAction, RETRIEVE_INDUSTRIES } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveIndustries({ language }: RetrieveIndustriesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Industry[]>> = yield call(
        retrieveIndustries,
        profile.accessToken,
        language || locale
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveIndustriesSuccess(response.data.result));
      } else {
        yield put(doRetrieveIndustriesFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveIndustries()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveIndustries()));
    } else {
      logging.error(error);
      yield put(doRetrieveIndustriesFailure());
    }
  }
}

export function* watcherRetrieveIndustries() {
  yield takeLatest(RETRIEVE_INDUSTRIES, workerRetrieveIndustries);
}

export function* industrySaga() {
  yield all([call(watcherRetrieveIndustries)]);
}
