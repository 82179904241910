import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { Report, ReportHSCode, ReportMarket, ReportQuestion } from './interfaces';
import {
  RetrieveNavigatorReportFailureAction,
  RetrieveNavigatorReportFullFailureAction,
  RetrieveNavigatorReportFullSuccessAction,
  RetrieveNavigatorReportSuccessAction,
  RetrieveNavigatorReportUuidFailureAction,
  RetrieveNavigatorReportUuidSuccessAction,
  RETRIEVE_REPORT,
  RETRIEVE_REPORT_FAILURE,
  RETRIEVE_REPORT_FULL,
  RETRIEVE_REPORT_FULL_FAILURE,
  RETRIEVE_REPORT_FULL_SUCCESS,
  RETRIEVE_REPORT_SUCCESS,
  RETRIEVE_REPORT_UUID,
  RETRIEVE_REPORT_UUID_FAILURE,
  RETRIEVE_REPORT_UUID_SUCCESS,
  SendNavigatorReportFailureAction,
  SendNavigatorReportSuccessAction,
  SEND_REPORT,
  SEND_REPORT_FAILURE,
  SEND_REPORT_SUCCESS,
  SetReportTodoDoneAction,
  SET_REPORT_TODO_DONE,
  UpdateNavigatorReportFailureAction,
  UPDATE_REPORT,
  UPDATE_REPORT_FAILURE,
  UPDATE_REPORT_SUCCESS
} from './types';

export interface ReportReducerState {
  loading: boolean;
  loadingReport: boolean;
  loadingUpdate: boolean;
  error?: RequestResult;
  report?: {
    uuid: string;
    questions?: ReportQuestion[];
    HSCode: ReportHSCode;
    market: ReportMarket;
    tradeInsightsData: {
      importValue?: string;
    };
  };
  uuid?: Report['uuid'];
}

const initialState: ReportReducerState = {
  loading: true,
  loadingReport: true,
  loadingUpdate: false,
  report: undefined,
  uuid: ''
};

export default createReducer<ReportReducerState>(initialState, {
  [RETRIEVE_REPORT]: state => {
    state.loadingReport = true;
    state.error = undefined;
  },
  [RETRIEVE_REPORT_SUCCESS]: (state, action: RetrieveNavigatorReportSuccessAction) => {
    state.loadingReport = false;
    state.error = undefined;
    state.report = action.report;
  },
  [RETRIEVE_REPORT_FAILURE]: (state, action: RetrieveNavigatorReportFailureAction) => {
    state.loadingReport = false;
    state.error = undefined;
    state.report = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_REPORT_UUID]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [RETRIEVE_REPORT_UUID_SUCCESS]: (state, action: RetrieveNavigatorReportUuidSuccessAction) => {
    state.loading = false;
    state.error = undefined;
    state.uuid = action.uuid;
  },
  [RETRIEVE_REPORT_UUID_FAILURE]: (state, action: RetrieveNavigatorReportUuidFailureAction) => {
    state.loading = false;
    state.error = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_REPORT_FULL]: state => {
    state.loading = true;
    state.loadingReport = true;
    state.error = undefined;
  },
  [RETRIEVE_REPORT_FULL_SUCCESS]: (state, action: RetrieveNavigatorReportFullSuccessAction) => {
    state.loading = false;
    state.loadingReport = false;
    state.error = undefined;
    state.report = action.report;
    state.uuid = action.report?.uuid;
  },
  [RETRIEVE_REPORT_FULL_FAILURE]: (state, action: RetrieveNavigatorReportFullFailureAction) => {
    state.loading = false;
    state.loadingReport = false;
    state.error = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [UPDATE_REPORT]: state => {
    state.loadingUpdate = true;
    state.error = undefined;
  },
  [UPDATE_REPORT_SUCCESS]: state => {
    state.loadingUpdate = false;
    state.error = undefined;
  },
  [UPDATE_REPORT_FAILURE]: (state, action: UpdateNavigatorReportFailureAction) => {
    state.loadingUpdate = false;
    state.error = undefined;
    state.report = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [SEND_REPORT]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [SEND_REPORT_SUCCESS]: (state, action: SendNavigatorReportSuccessAction) => {
    state.loading = false;
    state.error = undefined;
    state.uuid = action.uuid;
  },
  [SEND_REPORT_FAILURE]: (state, action: SendNavigatorReportFailureAction) => {
    state.loading = false;
    state.uuid = undefined;
    state.error = undefined;
    state.report = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },

  [SET_REPORT_TODO_DONE]: (state, action: SetReportTodoDoneAction) => {
    const { questionId, reportTask, checked } = action;
    if (state.report?.questions) {
      const questionIndex = state.report.questions.findIndex(({ id }) => id === questionId);

      if (questionIndex > -1) {
        const taskIndex = state.report.questions[questionIndex].option.todos.findIndex(
          ({ id }) => id === reportTask.id
        );

        if (taskIndex > -1) {
          const report = { ...state.report };
          if (report.questions) {
            report.questions[questionIndex].option.todos[taskIndex].userTodo.done = checked;
            state.report = report;
          }
        }
      }
    }
  }
});
