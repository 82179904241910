import { CRMContact } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '../user/interfaces';
import { Company } from '../company/interfaces';
import { Industry } from '../industry/interfaces';
import { BusinessTypeEnumerable } from 'src/utils/userUtils';

export const RETRIEVE_CRM_CONTACT = 'onboarding/RETRIEVE_CRM_CONTACT';
export const RETRIEVE_CRM_CONTACT_SUCCESS = 'onboarding/RETRIEVE_CRM_CONTACT_SUCCESS';
export const RETRIEVE_CRM_CONTACT_FAILURE = 'onboarding/RETRIEVE_CRM_CONTACT_FAILURE';
export const UPDATE_ONBOARDING_USER = 'onboarding/UPDATE_ONBOARDING_USER';
export const UPDATE_ONBOARDING_COMPANY = 'onboarding/UPDATE_ONBOARDING_COMPANY';
export const UPDATE_ONBOARDING_INDUSTRIES = 'onboarding/UPDATE_ONBOARDING_INDUSTRIES';
export const ONBOARD_USER = 'onboarding/ONBOARD_USER';
export const ONBOARD_USER_SUCCESS = 'onboarding/ONBOARD_USER_SUCCESS';
export const ONBOARD_USER_FAILURE = 'onboarding/ONBOARD_USER_FAILURE';
export const UPDATE_ONBOARDING_STATE = 'onboarding/UPDATE_ONBOARDING_STATE';

export interface RetrieveCRMContactAction {
  type: typeof RETRIEVE_CRM_CONTACT;
}

export interface RetrieveCRMContactSuccessAction {
  type: typeof RETRIEVE_CRM_CONTACT_SUCCESS;
  CRMContact: CRMContact;
}

export interface RetrieveCRMContactFailureAction {
  type: typeof RETRIEVE_CRM_CONTACT_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateOnboardingUserAction {
  type: typeof UPDATE_ONBOARDING_USER;
  user: Partial<User>;
}

export interface UpdateOnboardingCompanyAction {
  type: typeof UPDATE_ONBOARDING_COMPANY;
  company: Partial<Company> | null;
}

export interface UpdateOnboardingIndustriesAction {
  type: typeof UPDATE_ONBOARDING_INDUSTRIES;
  industries: Industry[];
}

export interface OnboardUserAction {
  type: typeof ONBOARD_USER;
  onFinish: (user?: User) => void;
  onError: (status: number) => void;
}

export interface OnboardUserSuccessAction {
  type: typeof ONBOARD_USER_SUCCESS;
  user: User;
}

export interface OnboardUserFailureAction {
  type: typeof ONBOARD_USER_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateOnboardingStateAction {
  type: typeof UPDATE_ONBOARDING_STATE;
  businessType: BusinessTypeEnumerable;
}
