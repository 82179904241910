import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import {
  RETRIEVE_PRODUCT_TAXES,
  RETRIEVE_PRODUCT_TAXES_SUCCESS,
  RETRIEVE_PRODUCT_TAXES_FAILURE,
  RetrieveProductTaxesAction,
  RetrieveProductTaxesSuccessAction,
  RetrieveProductTaxesFailureAction
} from './types';
import { MendelTaxesInfo } from './interfaces';
import { MarketGuide } from '../marketGuide/interfaces';

export interface MarketGuideReducerState {
  lastMarket?: MarketGuide;
  productTaxes: MendelTaxesInfo[];
  loading: boolean;
  error?: RequestResult<void>;
}

const initialState: MarketGuideReducerState = {
  loading: true,
  productTaxes: []
};

export default createReducer<MarketGuideReducerState>(initialState, {
  [RETRIEVE_PRODUCT_TAXES]: (state, action: RetrieveProductTaxesAction) => {
    state.error = undefined;
    state.loading = true;
    if (!state.lastMarket || action.market.id !== state.lastMarket.id) {
      state.lastMarket = action.market;
      state.productTaxes = [];
    }
  },
  [RETRIEVE_PRODUCT_TAXES_SUCCESS]: (state, action: RetrieveProductTaxesSuccessAction) => {
    state.loading = false;
    state.productTaxes = [...state.productTaxes, action.productTaxes];
  },
  [RETRIEVE_PRODUCT_TAXES_FAILURE]: (state, action: RetrieveProductTaxesFailureAction) => {
    state.loading = true;
    state.error = action.error;
  }
});
