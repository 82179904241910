import { all, call, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import {
  ToggleSelectMarketAction,
  RETRIEVE_USER_MARKETS,
  RetrieveUserMarketsAction,
  TOGGLE_SELECT_MARKET
} from './types';
import {
  doRetrieveUserMarkets,
  doRetrieveUserMarketsSuccess,
  doRetrieveUserMarketsFailure,
  doToggleSelectMarket,
  doSetMarketSelected,
  doUpdateUserMarket
} from './actions';
import { AppState } from '../../reducers';
import logging from '../../../logging';
import { selectMarket, deselectMarket, getMarkets } from './operations';
import { RequestResult } from '../../../interfaces/requests';
import { AxiosResponse } from 'axios';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { Market } from './interfaces';
import { AuthenticatedProfile } from '../auth/interfaces';

function* workerToggleMarket({ market }: ToggleSelectMarketAction) {
  // Get the market list to select the item from
  const userMarkets: AppState['marketsReducer']['userMarkets'] = yield select(
    (state: AppState) => state.marketsReducer.userMarkets
  );

  let marketToToggle: Market | undefined;
  // If the market list has any items
  if (userMarkets && userMarkets.length > 0) {
    marketToToggle = userMarkets.find(x => x.id === market.id);
  }

  // If the market was found
  if (marketToToggle) {
    const previousSelectedState = marketToToggle.userMarket ? marketToToggle.userMarket.isUserSelection : false;

    try {
      // Optimistically update the item before sending or waiting the api
      yield put(doSetMarketSelected(market, !previousSelectedState));

      const locale = yield select((state: AppState) => state.appReducer.language);
      const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

      // If the user is logged in
      if (profile) {
        let toggleMarketResult: AxiosResponse<RequestResult<Market>> | undefined;

        // If not selected
        if (!previousSelectedState) {
          // Select
          toggleMarketResult = yield call(selectMarket, market.id, profile.accessToken, locale);
        } else {
          // Deselect
          toggleMarketResult = yield call(deselectMarket, market.id, profile.accessToken, locale);
        }

        if (toggleMarketResult && toggleMarketResult.data.succeeded) {
          if (toggleMarketResult.data.result) {
            yield put(doUpdateUserMarket(toggleMarketResult.data.result));
          }
        } else {
          yield put(doSetMarketSelected(market, previousSelectedState));
        }
      } else {
        // If some error occurred, rollback
        yield put(doSetMarketSelected(market, previousSelectedState));
        // If the user not logged in
        yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doToggleSelectMarket(market)));
      }
    } catch (error) {
      // If some error occurred, rollback
      yield put(doSetMarketSelected(market, previousSelectedState));

      if (error && error.response && error.response.status && error.response.status === 401) {
        // If the token is expired
        yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doToggleSelectMarket(market)));
      } else {
        logging.error(error);
      }
    }
  }
}

function* workerRetrieveUserMarkets({}: RetrieveUserMarketsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const selectMarketResult: AxiosResponse<RequestResult<Market[]>> = yield call(
        getMarkets,
        profile.accessToken,
        locale
      );

      if (selectMarketResult.data.succeeded && selectMarketResult.data && selectMarketResult.data.result) {
        yield put(doRetrieveUserMarketsSuccess(selectMarketResult.data.result));
      } else {
        yield put(doRetrieveUserMarketsFailure('commonApiErrors.500OrNoInternet'));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUserMarkets()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUserMarkets()));
    } else {
      logging.error(error);
      yield put(doRetrieveUserMarketsFailure('commonApiErrors.500OrNoInternet'));
    }
  }
}

export function* watcherToggleMarket() {
  yield takeEvery(TOGGLE_SELECT_MARKET, workerToggleMarket);
}
export function* watcherRetrieveUserMarkets() {
  yield takeLatest(RETRIEVE_USER_MARKETS, workerRetrieveUserMarkets);
}

export function* marketsSaga() {
  yield all([call(watcherToggleMarket), call(watcherRetrieveUserMarkets)]);
}
