import { Flex } from '@components';
import { MobileAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Container: styled(Flex).attrs({ justifyContent: 'center', alignItems: 'center' })<{ visible: boolean }>`
    position: fixed;
    bottom: ${props => props.theme.spacing.md};
    left: 50%;
    transform: translateX(-50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.black};

    opacity: ${props => (props.visible ? 1 : 0)};
    pointer-events: ${props => (props.visible ? 'all' : 'none')};
    transition: opacity 0.45s ease-in-out;

    ${props => MobileAndDown(props)} {
      right: 12px;
      bottom: 76px;
      left: auto;
      transform: scale(0.8571);
    }
  `
};
