import {
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS,
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS,
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE,
  RetrieveConsultingPackagesConditionsAction,
  RetrieveConsultingPackagesConditionsSuccessAction,
  RetrieveConsultingPackagesConditionsFailureAction
} from './types';
import { ConsultingPackagesCondition } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

interface ConsultingPackagesConditions {
  businessTypeId?: string;
  consultingPackageId?: string;
  marketId?: string;
  pageType?: string;
}

export const doRetrieveConsultingPackagesConditions = ({
  businessTypeId,
  consultingPackageId,
  marketId,
  pageType
}: ConsultingPackagesConditions): RetrieveConsultingPackagesConditionsAction => ({
  type: RETRIEVE_CONSULTING_PACKAGES_CONDITIONS,
  businessTypeId,
  consultingPackageId,
  marketId,
  pageType
});

export const doRetrieveConsultingPackagesConditionsFailure = (
  error?: RequestResult<void>
): RetrieveConsultingPackagesConditionsFailureAction => ({
  error,
  type: RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE
});

export const doRetrieveConsultingPackagesConditionsSuccess = (
  consultingPackagesCondition: ConsultingPackagesCondition[]
): RetrieveConsultingPackagesConditionsSuccessAction => ({
  type: RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS,
  consultingPackagesCondition
});
