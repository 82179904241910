import { createReducer } from 'redux-starter-kit';
import { CMSConsultant } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import {
  RETRIEVE_MARKET_CONSULTANT,
  RETRIEVE_MARKET_CONSULTANT_SUCCESS,
  RETRIEVE_MARKET_CONSULTANT_FAILURE,
  RetrieveMarketConsultantSuccessAction,
  RetrieveMarketConsultantFailureAction,
  RETRIEVE_USER_CONSULTANT_LIST,
  RETRIEVE_USER_CONSULTANT_LIST_SUCCESS,
  RETRIEVE_USER_CONSULTANT_LIST_FAILURE,
  RetrieveUserConsultantListSuccessAction,
  RetrieveUserConsultantListFailureAction,
  RETRIEVE_CONSULTANT_BY_SLUG,
  RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS,
  RETRIEVE_CONSULTANT_BY_SLUG_FAILURE,
  RetrieveConsultantBySlugSuccessAction,
  RetrieveConsultantBySlugFailureAction,
  RETRIEVE_GENERAL_CONSULTANT,
  RETRIEVE_GENERAL_CONSULTANT_SUCCESS,
  RETRIEVE_GENERAL_CONSULTANT_FAILURE,
  RetrieveGeneralConsultantSuccessAction,
  RetrieveGeneralConsultantFailureAction,
  SetModalConsultantAction,
  SET_MODAL_CONSULTANT,
  SetConsultantEmailDataAction,
  SET_CONSULTANT_EMAIL_DATA,
  SEND_CONSULTANT_EMAIL,
  SEND_CONSULTANT_EMAIL_SUCCESS,
  SEND_CONSULTANT_EMAIL_FAILURE,
  SendConsultantEmailFailureAction
} from './types';

export interface ConsultantReducerState {
  marketConsultant?: CMSConsultant;
  marketConsultantError?: RequestResult;
  marketConsultantLoading: boolean;
  userConsultantList: CMSConsultant[];
  userConsultantListError?: RequestResult;
  userConsultantListLoading: boolean;
  consultantBySlug?: CMSConsultant;
  consultantBySlugError?: RequestResult;
  consultantBySlugLoading?: boolean;
  generalConsultant?: CMSConsultant;
  generalConsultantError?: RequestResult;
  generalConsultantLoading?: boolean;

  modalConsultant?: CMSConsultant;
  shouldClearOnClose?: boolean;
  consultantEmailData?: SetConsultantEmailDataAction['data'];
  consultantEmailSending: boolean;
  consultantEmailError?: RequestResult;
}

const initialState: ConsultantReducerState = {
  marketConsultantLoading: false,
  userConsultantListLoading: false,
  userConsultantList: [],
  consultantEmailSending: false
};

export default createReducer<ConsultantReducerState>(initialState, {
  [RETRIEVE_MARKET_CONSULTANT]: state => {
    state.marketConsultantLoading = true;
    state.marketConsultantError = undefined;
    state.marketConsultant = undefined;
  },
  [RETRIEVE_MARKET_CONSULTANT_SUCCESS]: (state, action: RetrieveMarketConsultantSuccessAction) => {
    state.marketConsultantLoading = false;
    state.marketConsultant = action.marketConsultant;
  },
  [RETRIEVE_MARKET_CONSULTANT_FAILURE]: (state, action: RetrieveMarketConsultantFailureAction) => {
    state.marketConsultantLoading = false;
    state.marketConsultantError = action.error;
  },
  [RETRIEVE_USER_CONSULTANT_LIST]: state => {
    state.userConsultantListLoading = true;
    state.userConsultantListError = undefined;
  },
  [RETRIEVE_USER_CONSULTANT_LIST_SUCCESS]: (state, action: RetrieveUserConsultantListSuccessAction) => {
    state.userConsultantListLoading = false;
    state.userConsultantList = action.consultantList;
  },
  [RETRIEVE_USER_CONSULTANT_LIST_FAILURE]: (state, action: RetrieveUserConsultantListFailureAction) => {
    state.userConsultantListLoading = false;
    state.userConsultantListError = action.error;
  },
  [RETRIEVE_CONSULTANT_BY_SLUG]: state => {
    state.consultantBySlugLoading = true;
    state.consultantBySlugError = undefined;
    state.consultantBySlug = undefined;
  },
  [RETRIEVE_CONSULTANT_BY_SLUG_SUCCESS]: (state, action: RetrieveConsultantBySlugSuccessAction) => {
    state.consultantBySlugLoading = false;
    state.consultantBySlug = action.consultant;
  },
  [RETRIEVE_CONSULTANT_BY_SLUG_FAILURE]: (state, action: RetrieveConsultantBySlugFailureAction) => {
    state.consultantBySlugLoading = false;
    state.consultantBySlugError = action.error;
  },
  [RETRIEVE_GENERAL_CONSULTANT]: state => {
    state.generalConsultantLoading = true;
    state.generalConsultantError = undefined;
    state.generalConsultant = undefined;
  },
  [RETRIEVE_GENERAL_CONSULTANT_SUCCESS]: (state, action: RetrieveGeneralConsultantSuccessAction) => {
    state.generalConsultantLoading = false;
    state.generalConsultant = action.consultant;
  },
  [RETRIEVE_GENERAL_CONSULTANT_FAILURE]: (state, action: RetrieveGeneralConsultantFailureAction) => {
    state.generalConsultantLoading = false;
    state.generalConsultantError = action.error;
  },
  [SET_MODAL_CONSULTANT]: (state, action: SetModalConsultantAction) => {
    state.modalConsultant = action.consultant;
    state.shouldClearOnClose = action.shouldClearOnClose;
  },
  [SET_CONSULTANT_EMAIL_DATA]: (state, action: SetConsultantEmailDataAction) => {
    state.consultantEmailData = action.data;
  },
  [SEND_CONSULTANT_EMAIL]: state => {
    state.consultantEmailSending = true;
    state.consultantEmailError = undefined;
  },
  [SEND_CONSULTANT_EMAIL_SUCCESS]: state => {
    state.consultantEmailSending = false;
  },
  [SEND_CONSULTANT_EMAIL_FAILURE]: (state, action: SendConsultantEmailFailureAction) => {
    state.consultantEmailSending = false;
    state.consultantEmailError = action.error;
  }
});
