// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveConsultingPackagesConditions } from './operations';

// Types
import { AppState } from '../../reducers';
import { ConsultingPackagesCondition } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrieveConsultingPackagesConditions,
  doRetrieveConsultingPackagesConditionsFailure,
  doRetrieveConsultingPackagesConditionsSuccess
} from './actions';
import { RetrieveConsultingPackagesConditionsAction, RETRIEVE_CONSULTING_PACKAGES_CONDITIONS } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveConsultingPackagesConditions({
  businessTypeId,
  consultingPackageId,
  marketId,
  pageType
}: RetrieveConsultingPackagesConditionsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<{ consultingPackage: ConsultingPackagesCondition }[]>> = yield call(
        retrieveConsultingPackagesConditions,
        profile.accessToken,
        locale,
        businessTypeId,
        consultingPackageId,
        marketId,
        pageType
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(
          doRetrieveConsultingPackagesConditionsSuccess(response.data.result.map(item => item.consultingPackage))
        );
      } else {
        yield put(doRetrieveConsultingPackagesConditionsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveConsultingPackagesConditions({})));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveConsultingPackagesConditions({})));
    } else {
      logging.error(error);
      yield put(doRetrieveConsultingPackagesConditionsFailure());
    }
  }
}

export function* watcherRetrieveConsultingPackagesConditions() {
  yield takeLatest(RETRIEVE_CONSULTING_PACKAGES_CONDITIONS, workerRetrieveConsultingPackagesConditions);
}

export function* consultingPackagesConditionSaga() {
  yield all([call(watcherRetrieveConsultingPackagesConditions)]);
}
