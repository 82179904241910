import { MessageFormatElement } from 'intl-messageformat-parser';
import { WrapperProps } from 'next-redux-wrapper';
import App, { AppContext } from 'next/app';
import React from 'react';
import { END } from 'redux-saga';
import { Locale } from '../locales';
import { doRetrieveTranslations } from '../redux/modules/app/actions';
import { doStoreUserAuth } from '../redux/modules/auth/actions';
import { AppState } from '../redux/reducers';
import { AppStore, wrapper } from '../redux/store';
import { optionalAuth } from '../utils/nextUtils';
import AppComponent from '@modules/app';
import './lock-style-customization.css';
import { DOMParser } from 'xmldom';

// Set Global DomParser for react-intl
global.DOMParser = DOMParser;

interface AppContainerProps {
  language: string;
  translations: Record<string, string> | Record<string, MessageFormatElement[]>;
}

class AppContainer extends App<AppContainerProps & WrapperProps> {
  static async getInitialProps({ Component, ctx, router }: AppContext) {
    const { store, req } = ctx;
    const state: AppState = store.getState();
    const language = router.locale as Locale;
    // get translations on the server and put to redux store
    if (req) {
      store.dispatch(doRetrieveTranslations(language));
    }

    if (!state?.authReducer?.profile) {
      const authProfile = optionalAuth(ctx);
      if (authProfile) {
        store.dispatch(doStoreUserAuth(authProfile));
      }
    }

    // 1. Wait for all page actions to dispatch
    const pageProps = { ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}) };
    // 2. Stop the saga if on server
    if (req) {
      store.dispatch(END);
      await (store as AppStore).sagaTask?.toPromise();
    }

    return { pageProps, language };
  }

  render() {
    const { Component, pageProps, language } = this.props;

    return <AppComponent language={language as Locale} pageProps={pageProps} Component={Component} />;
  }
}

export default wrapper.withRedux(AppContainer);
