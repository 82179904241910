import { RequestResult } from '../../../interfaces/requests';
import { Country } from '../dashboard/interfaces';
import { ConsultantContact, MarketGuide, MarketProductPotential, MarketTrait, SwissExport } from './interfaces';

export const RETRIEVE_MARKET_GUIDE = 'marketGuide/RETRIEVE_MARKET_GUIDE';
export const RETRIEVE_MARKET_GUIDE_SUCCESS = 'marketGuide/RETRIEVE_MARKET_GUIDE_SUCCESS';

export const RETRIEVE_MARKET_GUIDE_PUBLIC = 'marketGuide/RETRIEVE_MARKET_GUIDE_PUBLIC';
export const RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS = 'marketGuide/RETRIEVE_MARKET_GUIDE_SUCCESS';
export const RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE = 'marketGuide/RETRIEVE_MARKET_GUIDE_FAILURE';

export const RETRIEVE_MARKET_TOP_EXPORTERS = 'marketGuide/RETRIEVE_MARKET_TOP_EXPORTERS';
export const RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS = 'marketGuide/RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS';
export const RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE = 'marketGuide/RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE';

export const RETRIEVE_MARKET_SWISS_EXPORT = 'marketGuide/RETRIEVE_MARKET_SWISS_EXPORT';
export const RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS = 'marketGuide/RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS';
export const RETRIEVE_MARKET_SWISS_EXPORT_FAILURE = 'marketGuide/RETRIEVE_MARKET_SWISS_EXPORT_FAILURE';

export const RETRIEVE_MARKET_PRODUCT_VOLUME = 'marketGuide/RETRIEVE_MARKET_PRODUCT_VOLUME';
export const RETRIEVE_MARKET_PRODUCT_VOLUME_SUCCESS = 'marketGuide/RETRIEVE_MARKET_PRODUCT_VOLUME_SUCCESS';
export const RETRIEVE_MARKET_PRODUCT_VOLUME_FAILURE = 'marketGuide/RETRIEVE_MARKET_PRODUCT_VOLUME_FAILURE';

export const RETRIEVE_MARKET_SWISS_TRAITS = 'marketGuide/RETRIEVE_MARKET_SWISS_TRAITS';
export const RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS = 'marketGuide/RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS';
export const RETRIEVE_MARKET_SWISS_TRAITS_FAILURE = 'marketGuide/RETRIEVE_MARKET_SWISS_TRAITS_FAILURE';
export const RETRIEVE_MARKET_PRODUCT_POTENTIAL = 'marketGuide/RETRIEVE_MARKET_PRODUCT_POTENTIAL';
export const RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS = 'marketGuide/RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS';
export const RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE = 'marketGuide/RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE';

export const SUBMIT_CONSULTANT_CONTACT = 'marketGuide/SUBMIT_CONSULTANT_CONTACT';
export const SUBMIT_CONSULTANT_CONTACT_SUCCESS = 'marketGuide/SUBMIT_CONSULTANT_CONTACT_SUCCESS';
export const SUBMIT_CONSULTANT_CONTACT_FAILURE = 'marketGuide/SUBMIT_CONSULTANT_CONTACT_FAILURE';

export interface RetrieveMarketGuideAction {
  type: typeof RETRIEVE_MARKET_GUIDE;
  slug: string;
}
export interface RetrieveMarketGuideSuccessAction {
  type: typeof RETRIEVE_MARKET_GUIDE_SUCCESS;
  marketGuide: MarketGuide;
}

export interface RetrieveMarketGuidePublicAction {
  type: typeof RETRIEVE_MARKET_GUIDE_PUBLIC;
  slug: string;
}
export interface RetrieveMarketGuidePublicSuccessAction {
  type: typeof RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS;
  marketGuide: MarketGuide;
}

export interface RetrieveMarketGuidePublicFailureAction {
  type: typeof RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveMarketTopExportersAction {
  type: typeof RETRIEVE_MARKET_TOP_EXPORTERS;
  slug: string;
}
export interface RetrieveMarketTopExportersSuccessAction {
  type: typeof RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS;
  topExporters: Country[];
}
export interface RetrieveMarketTopExportersFailureAction {
  type: typeof RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveMarketSwissExportAction {
  type: typeof RETRIEVE_MARKET_SWISS_EXPORT;
  slug: string;
}
export interface RetrieveMarketSwissExportSuccessAction {
  type: typeof RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS;
  swissExport: SwissExport;
}
export interface RetrieveMarketSwissExportFailureAction {
  type: typeof RETRIEVE_MARKET_SWISS_EXPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveMarketSwissTraitsAction {
  type: typeof RETRIEVE_MARKET_SWISS_TRAITS;
}
export interface RetrieveMarketSwissTraitsSuccessAction {
  type: typeof RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS;
  traits: MarketTrait[];
}
export interface RetrieveMarketSwissTraitsFailureAction {
  type: typeof RETRIEVE_MARKET_SWISS_TRAITS_FAILURE;
  error?: RequestResult;
}
export interface RetrieveMarketProductPotentialAction {
  type: typeof RETRIEVE_MARKET_PRODUCT_POTENTIAL;
  slug: string;
}

export interface RetrieveMarketProductPotentialSuccessAction {
  type: typeof RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS;
  marketProductPotential: MarketProductPotential[];
}

export interface RetrieveMarketProductPotentialFailureAction {
  type: typeof RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE;
  error?: RequestResult<void>;
}

export interface SubmitConsultantContactAction {
  type: typeof SUBMIT_CONSULTANT_CONTACT;
  contactForm: ConsultantContact;
}

export interface SubmitConsultantContactSuccessAction {
  type: typeof SUBMIT_CONSULTANT_CONTACT_SUCCESS;
}

export interface SubmitConsultantContactFailureAction {
  type: typeof SUBMIT_CONSULTANT_CONTACT_FAILURE;
  error?: RequestResult<void>;
}
