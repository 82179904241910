import { createReducer } from 'redux-starter-kit';
import { ConsultingPackagesCondition } from './interfaces';
import {
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS,
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS,
  RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE,
  RetrieveConsultingPackagesConditionsSuccessAction,
  RetrieveConsultingPackagesConditionsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface ConsultingPackagesReducerState {
  consultingPackagesConditions?: ConsultingPackagesCondition[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: ConsultingPackagesReducerState = {
  consultingPackagesConditions: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<ConsultingPackagesReducerState>(initialState, {
  [RETRIEVE_CONSULTING_PACKAGES_CONDITIONS]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS]: (
    state,
    action: RetrieveConsultingPackagesConditionsSuccessAction
  ) => {
    state.retrieveLoading = false;
    state.consultingPackagesConditions = action.consultingPackagesCondition;
    state.error = null;
  },
  [RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE]: (
    state,
    action: RetrieveConsultingPackagesConditionsFailureAction
  ) => {
    state.retrieveLoading = false;
    state.consultingPackagesConditions = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
