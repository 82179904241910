import { createReducer } from 'redux-starter-kit';
import { Sector } from './interfaces';
import {
  RETRIEVE_SECTORS,
  RETRIEVE_SECTORS_SUCCESS,
  RETRIEVE_SECTORS_FAILURE,
  RetrieveSectorsSuccessAction,
  RetrieveSectorsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface SectorsReducerState {
  sectors?: Sector[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: SectorsReducerState = {
  sectors: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<SectorsReducerState>(initialState, {
  [RETRIEVE_SECTORS]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_SECTORS_SUCCESS]: (state, action: RetrieveSectorsSuccessAction) => {
    state.retrieveLoading = false;
    state.sectors = action.sectors;
    state.error = null;
  },
  [RETRIEVE_SECTORS_FAILURE]: (state, action: RetrieveSectorsFailureAction) => {
    state.retrieveLoading = false;
    state.sectors = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
