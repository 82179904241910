import React from 'react';

export enum BoStatus {
  New = 'new',
  Draft = 'draft',
  ReadyForReview = 'ready-for-review',
  Rework = 'rework',
  Published = 'published',
  Expired = 'expired',
  //For BOs that were not added manually
  Unknown = 'unknown',
  Released = 'released'
}

export enum BoUserRole {
  None = 'none',
  Approver = 'approver',
  Creator = 'creator',
  Publisher = 'publisher'
}

export type GuidelineType = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export type GuidelineTranslationType = {
  titleTranslationKey: string;
  descriptionTranslationKey: string;
};

export enum BoInternalSource {
  Manual = 'MANUAL',
  SpendNetwork = 'SPEND_NETWORK_OPEN_OPPS'
}
