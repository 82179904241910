// Modules
import { all, call, takeLatest, select } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import { sendConfirmationMailToConsultant } from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import { doSendConfirmationMailToConsultant } from './actions';
import { SendConfirmationMailToConsultantAction, SEND_CONFIRMATION_MAIL_TO_CONSULTANT } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerSendConfirmationMailToConsultant({
  consultingPackageId,
  consultantId,
  linkToConsultingPackageDetailPage,
  selectedCountryId
}: SendConfirmationMailToConsultantAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      yield call(
        sendConfirmationMailToConsultant,
        profile.accessToken,
        locale,
        consultingPackageId,
        consultantId,
        linkToConsultingPackageDetailPage,
        selectedCountryId
      );
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSendConfirmationMailToConsultant()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSendConfirmationMailToConsultant()));
    } else {
      logging.error(error);
    }
  }
}

export function* watcherSendConfirmationMailToConsultant() {
  yield takeLatest(SEND_CONFIRMATION_MAIL_TO_CONSULTANT, workerSendConfirmationMailToConsultant);
}

export function* consultingPackageSaga() {
  yield all([call(watcherSendConfirmationMailToConsultant)]);
}
