import {
  RETRIEVE_POSTING_REASONS,
  RETRIEVE_POSTING_REASONS_FAILURE,
  RETRIEVE_POSTING_REASONS_SUCCESS,
  RetrievePostingReasonsAction,
  RetrievePostingReasonsFailureAction,
  RetrievePostingReasonsSuccessAction
} from './types';
import { PostingReason } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrievePostingReasons = (): RetrievePostingReasonsAction => ({
  type: RETRIEVE_POSTING_REASONS
});

export const doRetrievePostingReasonsFailure = (error?: RequestResult<void>): RetrievePostingReasonsFailureAction => ({
  error,
  type: RETRIEVE_POSTING_REASONS_FAILURE
});

export const doRetrievePostingReasonsSuccess = (
  postingReasons: PostingReason[]
): RetrievePostingReasonsSuccessAction => ({
  type: RETRIEVE_POSTING_REASONS_SUCCESS,
  postingReasons
});
