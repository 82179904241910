// Modules
import { all, call, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveTasks, toggleTaskTodo } from './operations';

// Types
import { AppState } from '../../reducers';
import { Task } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrieveTasks,
  doRetrieveTasksSuccess,
  doRetrieveTasksFailure,
  doSetTodoDone,
  doToggleTodoDone
} from './actions';
import { RETRIEVE_TASKS, RetrieveNavigatorTasksAction, TOGGLE_TODO_DONE, ToggleTodoDoneAction } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveTasks({}: RetrieveNavigatorTasksAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Task[]>> = yield call(retrieveTasks, profile.accessToken, locale);
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveTasksSuccess(response.data.result));
      } else {
        yield put(doRetrieveTasksFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveTasks()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveTasks()));
    } else {
      logging.error(error);
      yield put(doRetrieveTasksFailure());
    }
  }
}

function* workerToggleTodoDone({ task, checked }: ToggleTodoDoneAction) {
  // Get the task list to select the item from
  const tasks: AppState['exportNavigatorReducer']['tasks'] | undefined = yield select(
    (state: AppState) => state.exportNavigatorReducer.tasks
  );

  let taskToToggle: Task | undefined;
  // If the task list has any items
  if (tasks && tasks.length > 0) {
    taskToToggle = tasks.find(x => x.id === task.id);
  }

  // If the task was found
  if (taskToToggle) {
    const previousSelectedState = taskToToggle.done;

    try {
      // Optimistically update the item before sending or waiting the api
      yield put(doSetTodoDone(task, !previousSelectedState));

      const locale = yield select((state: AppState) => state.appReducer.language);
      const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

      // If the user is logged in
      if (profile) {
        const toggleTaskResult: AxiosResponse<RequestResult<Task>> = yield call(
          toggleTaskTodo,
          profile.accessToken,
          locale,
          task.todo.id,
          checked
        );

        if (toggleTaskResult && toggleTaskResult.data.succeeded) {
          yield put(doSetTodoDone(task, checked));
        } else {
          // If some error occurred, rollback
          yield put(doSetTodoDone(task, previousSelectedState));
        }
      } else {
        // If some error occurred, rollback
        yield put(doSetTodoDone(task, previousSelectedState));
        // If the user not logged in
        yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doToggleTodoDone(task, checked)));
      }
    } catch (error) {
      // If some error occurred, rollback
      yield put(doSetTodoDone(task, previousSelectedState));

      if (error && error.response && error.response.status && error.response.status === 401) {
        // If the token is expired
        yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doToggleTodoDone(task, checked)));
      } else {
        logging.error(error);
      }
    }
  }
}

export function* watcherRetrieveTasks() {
  yield takeLatest(RETRIEVE_TASKS, workerRetrieveTasks);
}

export function* watcherToggleTodoDone() {
  // It SHOULD take every
  yield takeEvery(TOGGLE_TODO_DONE, workerToggleTodoDone);
}

export function* exportNavigatorSaga() {
  yield all([call(watcherRetrieveTasks), call(watcherToggleTodoDone)]);
}
