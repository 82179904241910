import { AxiosResponse } from 'axios';
import getConfig from 'next/config';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { isPath } from '../../../utils/nextUtils';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doAuthUser, doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { LOGIN_REDIRECT_URL_KEY, workerRefreshTokenAndRetry } from '../auth/sagas';
import {
  doLoginRedirect,
  doRetrieveUser,
  doRetrieveUserFailure,
  doRetrieveUserSuccess,
  doSignupOrLogin,
  doSignupOrLoginFailure,
  doSignupOrLoginSuccess,
  doSyncDBUser,
  doSyncDBUserFailure,
  doSyncDBUserSuccess,
  doUpdateUser,
  doUpdateUserFailure,
  doUpdateUserSuccess
} from './actions';
import { User } from './interfaces';
import { certifyUser, createUser, retrieveUser, syncDBUser, updateUser } from './operations';
import {
  RetrieveUserAction,
  RETRIEVE_USER,
  SignupOrLoginAction,
  SIGNUP_OR_LOGIN,
  SyncDBUserAction,
  SYNC_DB_USER,
  UpdateUserAction,
  UPDATE_USER
} from './types';

const { publicRuntimeConfig } = getConfig();

function* workerSignupOrLogin({ auth0Profile, redirectUrl }: SignupOrLoginAction) {
  try {
    const isServer = typeof window === 'undefined';
    const locale = yield select((state: AppState) => state.appReducer.language);
    const { sub: auth0Id, accessToken, tokenPayload } = auth0Profile;
    let userResult = yield call(certifyUser, { auth0Id }, accessToken, locale);
    const isEventSignup =
      userResult.status === 200 &&
      userResult.data.succeeded === false &&
      (isPath('EVENT', redirectUrl) ||
        isPath('EVENT', window.localStorage.getItem(LOGIN_REDIRECT_URL_KEY) || undefined));

    const isIncompleteLightUser =
      userResult.status === 200 &&
      userResult.data.succeeded &&
      userResult.data.result?.onboarded === false &&
      userResult.data.result?.type !== 'light' &&
      (isPath('EVENT', tokenPayload?.['https://goglobal.s-ge.com/signup_via_url']) ||
        isPath('EVENT', tokenPayload?.['https://goglobal.s-ge.com/signupViaUrl']));

    if (isEventSignup) {
      userResult = yield call(createUser, { type: 'light' }, accessToken, locale);
      window.localStorage.removeItem(LOGIN_REDIRECT_URL_KEY);
    } else if (isIncompleteLightUser) {
      // Update existing light users in the database by changing their type to "light"
      userResult = yield call(
        updateUser,
        { id: (userResult.data.result as User).id, type: 'light' },
        null,
        accessToken,
        locale
      );
    }

    if (userResult.status !== 200) {
      logging.error(userResult);
      return;
    }

    if (!userResult.data.succeeded) {
      if (!isServer) {
        yield put(doLoginRedirect());
        setTimeout(() => {
          window.location.assign(`/${locale}/signup`);
        }, 100);
      }

      return;
    }

    const user: User | undefined = userResult.data.result;
    const isLightUser = user?.type === 'light';
    yield put(doSignupOrLoginSuccess(user));

    if (
      !(isServer || window.location.href.includes('skipSync')) &&
      publicRuntimeConfig.SKIP_LOGIN_SYNC !== 'true' &&
      !isLightUser
    ) {
      yield put(doSyncDBUser(redirectUrl));
      return;
    }

    if (redirectUrl) {
      if (!isServer) {
        yield put(doLoginRedirect());
        window.location.assign(redirectUrl);
      }

      return;
    }

    if (isLightUser) {
      if (!isServer) {
        window.location.assign(`/${locale}/events`);
      }
      return;
    }

    if (!isServer) {
      window.location.assign(`/${locale}/cockpit`);
    }
  } catch (error) {
    logging.error(error);
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSignupOrLogin(auth0Profile, redirectUrl)));
    } else {
      const parsedError = parseError(error);
      yield put(doAuthUser(redirectUrl)); // Going back to login
      yield put(doSignupOrLoginFailure(parsedError));
    }
  }
}

function* workerRetrieveUser({ onSuccess, onFailure }: RetrieveUserAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      const retrieveUserResult: AxiosResponse<RequestResult<User>> = yield call(
        retrieveUser,
        profile.accessToken,
        locale
      );

      if (retrieveUserResult.data && retrieveUserResult.data.succeeded && retrieveUserResult.data.result) {
        yield put(doRetrieveUserSuccess(retrieveUserResult.data.result));

        if (onSuccess) {
          onSuccess(retrieveUserResult.data.result);
        }
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUser(onSuccess)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUser(onSuccess)));
    } else {
      const parsedError = parseError(error);
      if (onFailure) {
        onFailure(error, parsedError);
      }
      logging.error(error);
      yield put(doRetrieveUserFailure(parsedError));
    }
  }
}

function* workerUpdateUser({ user, onSuccess }: UpdateUserAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const retrieveUserResult: AxiosResponse<RequestResult<User>> = yield call(
        updateUser,
        { ...user, industries: undefined },
        user.industries ? user.industries.map(x => x.id) : [],
        profile.accessToken,
        locale
      );

      if (retrieveUserResult.data.succeeded && retrieveUserResult.data && retrieveUserResult.data.result) {
        const user = retrieveUserResult.data.result;

        yield put(doUpdateUserSuccess(user, 'profile.updateUserSuccess'));
        if (onSuccess) {
          onSuccess(user);
        }
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateUser(user, onSuccess)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateUser(user)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doUpdateUserFailure(parsedError));
    }
  }
}

function* workerSyncDBUser({ redirectUrl }: SyncDBUserAction) {
  const locale = yield select((state: AppState) => state.appReducer.language);
  let profile: AuthenticatedProfile | undefined = undefined;
  try {
    profile = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const retrieveUserResult: AxiosResponse<RequestResult<User>> = yield call(
        syncDBUser,
        profile.accessToken,
        locale
      );

      if (retrieveUserResult.data.succeeded && retrieveUserResult.data && retrieveUserResult.data.result) {
        yield put(doSyncDBUserSuccess(retrieveUserResult.data.result));
      }
    } else {
      yield put(doRefreshTokenAndRetry(doSyncDBUser(redirectUrl)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doSyncDBUser(redirectUrl)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doSyncDBUserFailure(parsedError));
    }
  }

  if (typeof window !== 'undefined') {
    // User will be redirected even when the request failed
    // Check the length to don't redirect to '/' or '/en'
    if (redirectUrl && redirectUrl.length > 3) {
      window.location.assign(redirectUrl);
    } else {
      window.location.assign(`/${locale}/cockpit`);
    }
  }
}

export function* watcherSignupOrLogin() {
  yield takeLatest(SIGNUP_OR_LOGIN, workerSignupOrLogin);
}

export function* watcherRetrieveUser() {
  yield takeLatest(RETRIEVE_USER, workerRetrieveUser);
}

export function* watcherUpdateUser() {
  yield takeLatest(UPDATE_USER, workerUpdateUser);
}

export function* watcherSyncDBUser() {
  yield takeLatest(SYNC_DB_USER, workerSyncDBUser);
}

export function* userSaga() {
  yield all([call(watcherSignupOrLogin), call(watcherRetrieveUser), call(watcherUpdateUser), call(watcherSyncDBUser)]);
}
