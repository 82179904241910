import { createReducer } from 'redux-starter-kit';
import { CMSArticle } from './interfaces';
import {
  RETRIEVE_ARTICLES,
  RETRIEVE_ARTICLES_FAILURE,
  RETRIEVE_ARTICLES_SUCCESS,
  SELECT_ARTICLE,
  SELECT_ARTICLE_SUCCESS,
  RetrieveArticlesFailureAction,
  RetrieveArticlesSuccessAction,
  SelectArticleSuccessAction,
  SELECT_ARTICLE_FAILURE,
  SelectArticleFailureAction,
  RetrieveMenuArticlesFailureAction,
  RetrieveMenuArticlesSuccessAction,
  RETRIEVE_MENU_ARTICLES,
  RETRIEVE_MENU_ARTICLES_FAILURE,
  RETRIEVE_MENU_ARTICLES_SUCCESS
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface ArticlesReducerState {
  articles: CMSArticle[];
  loading: boolean;
  error?: RequestResult;
  article?: CMSArticle;
  articleLoading: boolean;
  articleError?: RequestResult;
  total: number;
  notFound: boolean;
  menuArticles: CMSArticle[];
  menuArticlesLoading: boolean;
  menuArticlesError?: RequestResult;
}

const initialState: ArticlesReducerState = {
  articles: [],
  loading: false,
  articleLoading: false,
  total: 0,
  notFound: false,
  menuArticles: [],
  menuArticlesLoading: false
};

export default createReducer<ArticlesReducerState>(initialState, {
  [RETRIEVE_ARTICLES]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [RETRIEVE_ARTICLES_FAILURE]: (state, action: RetrieveArticlesFailureAction) => {
    state.loading = false;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_ARTICLES_SUCCESS]: (state, action: RetrieveArticlesSuccessAction) => {
    state.loading = false;
    state.articles = action.articles;
    state.total = action.total;
    state.error = undefined;
  },
  [RETRIEVE_MENU_ARTICLES]: state => {
    state.menuArticlesLoading = true;
    state.menuArticlesError = undefined;
  },
  [RETRIEVE_MENU_ARTICLES_FAILURE]: (state, action: RetrieveMenuArticlesFailureAction) => {
    state.menuArticlesLoading = false;
    if (action.error) {
      state.menuArticlesError = action.error;
    }
  },
  [RETRIEVE_MENU_ARTICLES_SUCCESS]: (state, action: RetrieveMenuArticlesSuccessAction) => {
    state.menuArticlesLoading = false;
    state.menuArticles = action.articles;
    state.menuArticlesError = undefined;
  },
  [SELECT_ARTICLE]: state => {
    state.articleLoading = true;
    state.articleError = initialState.articleError;
  },
  [SELECT_ARTICLE_SUCCESS]: (state, action: SelectArticleSuccessAction) => {
    state.article = action.article;
    state.articleLoading = false;
  },
  [SELECT_ARTICLE_FAILURE]: (state, action: SelectArticleFailureAction) => {
    state.articleError = action.error;
    state.articleLoading = false;
  }
});
