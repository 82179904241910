import axios from 'axios';
import getConfig from 'next/config';
import { ConsultingPackage } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveBusinessTypes(accessToken: string, locale: string) {
  return axios.get<RequestResult<ConsultingPackage[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/consulting-packages`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

export function sendConfirmationMailToConsultant(
  accessToken: string,
  locale: string,
  consultingPackageId?: string,
  consultantId?: string,
  linkToConsultingPackageDetailPage?: string,
  selectedCountryId?: string
) {
  return axios.get(
    `${getConfig().publicRuntimeConfig.API_URI}/v1/${locale}/consulting-packages/send-confirmation-email-to-consultant`,
    {
      params: {
        consultingPackageId,
        consultantId,
        linkToConsultingPackageDetailPage,
        selectedCountryId
      },
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
