import axios from 'axios';
import getConfig from 'next/config';
import { PostingEmployeesContent } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrievePostingEmployeesContents(
  accessToken: string,
  locale: string,
  postingReasonId?: string,
  targetMarketId?: string,
  postingNationalityId?: string,
  postingEmployeesDurationId?: string,
  contentTypes?: string
) {
  return axios.get<RequestResult<PostingEmployeesContent[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/posting-employees-contents`,
    {
      params: {
        postingReasonId: postingReasonId,
        targetMarketId: targetMarketId,
        postingNationalityId: postingNationalityId,
        postingEmployeesDurationId: postingEmployeesDurationId,
        contentTypes: contentTypes
      },
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
