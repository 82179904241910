import { createReducer } from 'redux-starter-kit';
import { Country } from './interfaces';
import {
  RETRIEVE_COUNTRIES,
  RETRIEVE_COUNTRIES_FAILURE,
  RETRIEVE_COUNTRIES_SUCCESS,
  RetrieveCountriesSuccessAction,
  RetrieveCountriesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface CountriesReducerState {
  countries?: Country[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: CountriesReducerState = {
  countries: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<CountriesReducerState>(initialState, {
  [RETRIEVE_COUNTRIES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_COUNTRIES_SUCCESS]: (state, action: RetrieveCountriesSuccessAction) => {
    state.retrieveLoading = false;
    state.countries = action.countries;
    state.error = null;
  },
  [RETRIEVE_COUNTRIES_FAILURE]: (state, action: RetrieveCountriesFailureAction) => {
    state.retrieveLoading = false;
    state.countries = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
