import {
  RETRIEVE_POSTING_NATIONALITIES,
  RETRIEVE_POSTING_NATIONALITIES_FAILURE,
  RETRIEVE_POSTING_NATIONALITIES_SUCCESS,
  RetrievePostingNationalitiesAction,
  RetrievePostingNationalitiesFailureAction,
  RetrievePostingNationalitiesSuccessAction
} from './types';
import { PostingNationality } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrievePostingNationalities = (): RetrievePostingNationalitiesAction => ({
  type: RETRIEVE_POSTING_NATIONALITIES
});

export const doRetrievePostingNationalitiesFailure = (
  error?: RequestResult<void>
): RetrievePostingNationalitiesFailureAction => ({
  error,
  type: RETRIEVE_POSTING_NATIONALITIES_FAILURE
});

export const doRetrievePostingNationalitiesSuccess = (
  postingNationalities: PostingNationality[]
): RetrievePostingNationalitiesSuccessAction => ({
  type: RETRIEVE_POSTING_NATIONALITIES_SUCCESS,
  postingNationalities
});
