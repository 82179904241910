import { createReducer } from 'redux-starter-kit';
import { BusinessOpportunityType } from './interfaces';
import {
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES,
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE,
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS,
  RetrieveBusinessOpportunityTypesSuccessAction,
  RetrieveBusinessOpportunityTypesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface BusinessOpportunityTypesReducerState {
  businessOpportunityTypes?: BusinessOpportunityType[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: BusinessOpportunityTypesReducerState = {
  businessOpportunityTypes: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<BusinessOpportunityTypesReducerState>(initialState, {
  [RETRIEVE_BUSINESS_OPPORTUNITY_TYPES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS]: (state, action: RetrieveBusinessOpportunityTypesSuccessAction) => {
    state.retrieveLoading = false;
    state.businessOpportunityTypes = action.businessOpportunityTypes;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE]: (state, action: RetrieveBusinessOpportunityTypesFailureAction) => {
    state.retrieveLoading = false;
    state.businessOpportunityTypes = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
