import { Input } from '@components';
import styled from 'styled-components';

export default {
  EditorContainer: styled.div<{ maximized?: boolean }>`
    position: fixed;
    min-width: 150px;
    top: 20%;
    z-index: 10000000000;
    font-size: 17px;
    max-width: 90%;
    margin-left: auto;
    width: fit-content;
    margin-right: auto;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255);
    box-shadow: 5px 20px 50px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    ${Input.TextArea} {
      min-width: 400px;
      ${props => props.maximized && 'width: 100%; height: 400px; '};
    }

    ${props =>
      props.maximized ? 'max-width: 100%; width: 100%; height: 100%; top:0px; right:auto; left:auto; margin:none' : ''};
  `
};
