import { createReducer } from 'redux-starter-kit';
import { PostingReason } from './interfaces';
import {
  RETRIEVE_POSTING_REASONS,
  RETRIEVE_POSTING_REASONS_SUCCESS,
  RETRIEVE_POSTING_REASONS_FAILURE,
  RetrievePostingReasonsSuccessAction,
  RetrievePostingReasonsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface PostingReasonsReducerState {
  postingReasons?: PostingReason[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: PostingReasonsReducerState = {
  postingReasons: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<PostingReasonsReducerState>(initialState, {
  [RETRIEVE_POSTING_REASONS]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_POSTING_REASONS_SUCCESS]: (state, action: RetrievePostingReasonsSuccessAction) => {
    state.retrieveLoading = false;
    state.postingReasons = action.postingReasons;
    state.error = null;
  },
  [RETRIEVE_POSTING_REASONS_FAILURE]: (state, action: RetrievePostingReasonsFailureAction) => {
    state.retrieveLoading = false;
    state.postingReasons = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
