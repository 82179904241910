// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveCPVCodes } from './operations';

// Types
import { AppState } from '../../reducers';
import { CPVCode } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import { doRetrieveCPVCodesSuccess, doRetrieveCPVCodes, doRetrieveCPVCodesFailure } from './actions';
import { RetrieveCPVCodesAction, RETRIEVE_CPVCODES } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveCPVCodes({ industryIds }: RetrieveCPVCodesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CPVCode[]>> = yield call(
        retrieveCPVCodes,
        profile.accessToken,
        locale,
        industryIds
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveCPVCodesSuccess(response.data.result));
      } else {
        yield put(doRetrieveCPVCodesFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCPVCodes()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCPVCodes()));
    } else {
      logging.error(error);
      yield put(doRetrieveCPVCodesFailure());
    }
  }
}

export function* watcherRetrieveCPVCodes() {
  yield takeLatest(RETRIEVE_CPVCODES, workerRetrieveCPVCodes);
}

export function* cPVCodesSaga() {
  yield all([call(watcherRetrieveCPVCodes)]);
}
