// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import { checkOec } from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import { doCheckOec, doCheckOecSuccess, doCheckOecFailure } from './actions';
import { CHECK_OEC, CheckOecAction } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerCheckOec({ url }: CheckOecAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      yield call(checkOec, profile.accessToken, locale, url);
      yield put(doCheckOecSuccess());
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCheckOec(url)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCheckOec(url)));
    } else {
      logging.error(error);
      yield put(doCheckOecFailure());
    }
  }
}

export function* watcherCheckOec() {
  yield takeLatest(CHECK_OEC, workerCheckOec);
}

export function* oecSaga() {
  yield all([call(watcherCheckOec)]);
}
