// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveBusinessOpportunity } from './operations';

// Types
import { AppState } from '../../reducers';
import { BusinessOpportunityDetailsMMBrain } from './interfaces';

// Redux
import {
  doRetrieveBusinessOpportunity,
  doRetrieveBusinessOpportunityFailure,
  doRetrieveBusinessOpportunitySuccess
} from './actions';
import { RetrieveBusinessOpportunityAction, RETRIEVE_BUSINESS_OPPORTUNITY } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveBusinessOpportunity({ businessOpportunityId, asACopy }: RetrieveBusinessOpportunityAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      const response: AxiosResponse<BusinessOpportunityDetailsMMBrain> = yield call(
        retrieveBusinessOpportunity,
        profile.accessToken,
        businessOpportunityId,
        locale
      );

      if (response.data) {
        const responseData = response.data;
        if (asACopy) {
          if (responseData.title.indexOf('[COPY]') === -1) {
            responseData.title = `[COPY] ${responseData.title}`;
          } else {
            responseData.title = `${responseData.title}`;
          }
          if (responseData.attachments) {
            responseData.attachments = responseData.attachments.filter(item => item.type !== 'file');
          }
        }

        yield put(doRetrieveBusinessOpportunitySuccess(responseData));
      } else {
        yield put(doRetrieveBusinessOpportunityFailure());
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRetrieveBusinessOpportunity(businessOpportunityId))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRetrieveBusinessOpportunity(businessOpportunityId))
      );
    } else {
      logging.error(error);
      yield put(doRetrieveBusinessOpportunityFailure(error));
    }
  }
}

export function* watcherRetrieveBusinessOpportunity() {
  yield takeLatest(RETRIEVE_BUSINESS_OPPORTUNITY, workerRetrieveBusinessOpportunity);
}

export function* businessOpportunityDetailsMMBrainSaga() {
  yield all([call(watcherRetrieveBusinessOpportunity)]);
}
