import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import {
  RETRIEVE_GLOBAL_IMPORT_VOLUME,
  RetrieveGlobalImportVolumeAction,
  RETRIEVE_LARGEST_IMPORTERS,
  RetrieveLargestImportersAction
} from './types';
import {
  doRetrieveGlobalImportVolume,
  doRetrieveGlobalImportVolumeSuccess,
  doRetrieveGlobalImportVolumeFailure,
  doRetrieveLargestImporters,
  doRetrieveLargestImportersSuccess,
  doRetrieveLargestImportersFailure
} from './actions';

import { AppState } from '../../reducers';
import logging from '../../../logging';
import { getUserGlobalImportVolume, getUserLargestImporters } from './operations';
import { RequestResult } from '../../../interfaces/requests';
import { AxiosResponse } from 'axios';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { GlobalImportVolume, Country } from './interfaces';
import { parseError } from '../../../utils/validationUtils';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveGlobalImportVolumeMarkets({}: RetrieveGlobalImportVolumeAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<GlobalImportVolume>> = yield call(
        getUserGlobalImportVolume,
        profile.accessToken,
        locale
      );

      if (response.data.succeeded && response.data && response.data.result) {
        yield put(doRetrieveGlobalImportVolumeSuccess(response.data.result));
      } else {
        yield put(doRetrieveGlobalImportVolumeFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveGlobalImportVolume()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveGlobalImportVolume()));
    } else {
      logging.error(error);
      yield put(doRetrieveGlobalImportVolumeFailure(parsedError));
    }
  }
}

function* workerRetrieveLargestImporters({}: RetrieveLargestImportersAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Country[]>> = yield call(
        getUserLargestImporters,
        profile.accessToken,
        locale
      );
      if (response.data.succeeded && response.data && response.data.result) {
        yield put(doRetrieveLargestImportersSuccess(response.data.result));
      } else {
        yield put(doRetrieveLargestImportersFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveLargestImporters()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveLargestImporters()));
    } else {
      logging.error(error);
      yield put(doRetrieveLargestImportersFailure(parsedError));
    }
  }
}

export function* watcherRetrieveGlobalImportVolumeMarkets() {
  yield takeLatest(RETRIEVE_GLOBAL_IMPORT_VOLUME, workerRetrieveGlobalImportVolumeMarkets);
}

export function* watcherRetrieveLargestImporters() {
  yield takeLatest(RETRIEVE_LARGEST_IMPORTERS, workerRetrieveLargestImporters);
}

export function* dashboardSaga() {
  yield all([call(watcherRetrieveGlobalImportVolumeMarkets), call(watcherRetrieveLargestImporters)]);
}
