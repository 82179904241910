// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrievePostingEmployeesContents } from './operations';

// Types
import { AppState } from '../../reducers';
import { PostingEmployeesContent } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrievePostingEmployeesContentsSuccess,
  doRetrievePostingEmployeesContents,
  doRetrievePostingEmployeesContentsFailure
} from './actions';
import { RetrievePostingEmployeesContentsAction, RETRIEVE_POSTING_EMPLOYEES_CONTENTS } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrievePostingEmployeesContents({
  postingReasonId,
  targetMarketId,
  postingNationalityId,
  postingEmployeesDurationId,
  contentTypes
}: RetrievePostingEmployeesContentsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<PostingEmployeesContent[]>> = yield call(
        retrievePostingEmployeesContents,
        profile.accessToken,
        locale,
        postingReasonId,
        targetMarketId,
        postingNationalityId,
        postingEmployeesDurationId,
        contentTypes
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrievePostingEmployeesContentsSuccess(response.data.result));
      } else {
        yield put(doRetrievePostingEmployeesContentsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingEmployeesContents()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingEmployeesContents()));
    } else {
      logging.error(error);
      yield put(doRetrievePostingEmployeesContentsFailure());
    }
  }
}

export function* watcherRetrievePostingEmployeesContents() {
  yield takeLatest(RETRIEVE_POSTING_EMPLOYEES_CONTENTS, workerRetrievePostingEmployeesContents);
}

export function* postingEmployeesContentSaga() {
  yield all([call(watcherRetrievePostingEmployeesContents)]);
}
