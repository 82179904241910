import { CPVCode } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_CPVCODES = 'cpvcodes/RETRIEVE_CPVCODES';
export const RETRIEVE_CPVCODES_SUCCESS = 'cpvcodes/RETRIEVE_CPVCODES_SUCCESS';
export const RETRIEVE_CPVCODES_FAILURE = 'cpvcodes/RETRIEVE_CPVCODES_FAILURE';

export interface RetrieveCPVCodesAction {
  type: typeof RETRIEVE_CPVCODES;
  industryIds?: string;
}

export interface RetrieveCPVCodesSuccessAction {
  type: typeof RETRIEVE_CPVCODES_SUCCESS;
  cPVCodes: CPVCode[];
}

export interface RetrieveCPVCodesFailureAction {
  type: typeof RETRIEVE_CPVCODES_FAILURE;
  error?: RequestResult<void>;
}
