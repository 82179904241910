import { BusinessOpportunityDetailsMMBrain } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_BUSINESS_OPPORTUNITY = 'businessopportunity/RETRIEVE_BUSINESS_OPPORTUNITY';
export const RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS = 'businessopportunity/RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS';
export const RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE = 'businessopportunity/RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE';

export interface RetrieveBusinessOpportunityAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY;
  businessOpportunityId: string;
  asACopy: boolean;
}

export interface RetrieveBusinessOpportunitySuccessAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS;
  businessOpportunityDetails: BusinessOpportunityDetailsMMBrain;
}

export interface RetrieveBusinessOpportunityFailureAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE;
  error?: RequestResult<void>;
}
