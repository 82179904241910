import { createReducer } from 'redux-starter-kit';
import { CPVCode } from './interfaces';
import {
  RETRIEVE_CPVCODES,
  RETRIEVE_CPVCODES_SUCCESS,
  RETRIEVE_CPVCODES_FAILURE,
  RetrieveCPVCodesSuccessAction,
  RetrieveCPVCodesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface CPVCodesReducerState {
  cPVCodes?: CPVCode[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: CPVCodesReducerState = {
  cPVCodes: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<CPVCodesReducerState>(initialState, {
  [RETRIEVE_CPVCODES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_CPVCODES_SUCCESS]: (state, action: RetrieveCPVCodesSuccessAction) => {
    state.retrieveLoading = false;
    state.cPVCodes = action.cPVCodes;
    state.error = null;
  },
  [RETRIEVE_CPVCODES_FAILURE]: (state, action: RetrieveCPVCodesFailureAction) => {
    state.retrieveLoading = false;
    state.cPVCodes = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
