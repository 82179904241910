import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { RetrieveCRMContactSuccessAction, RETRIEVE_CRM_CONTACT_SUCCESS } from '../onboarding/types';
import { Product, SelectedProduct, SelectedProductFull } from './interfaces';
import {
  ChangeSearchAction,
  CHANGE_SEARCH,
  DeselectProductAction,
  DESELECT_PRODUCT,
  DESELECT_ALL_PRODUCTS,
  RetrieveProductsFailureAction,
  RetrieveProductsFullFailureAction,
  RetrieveProductsFullSuccessAction,
  RetrieveProductsSuccessAction,
  RETRIEVE_PRODUCTS,
  RETRIEVE_PRODUCTS_FAILURE,
  RETRIEVE_PRODUCTS_FULL,
  RETRIEVE_PRODUCTS_FULL_FAILURE,
  RETRIEVE_PRODUCTS_FULL_SUCCESS,
  RETRIEVE_PRODUCTS_SUCCESS,
  SaveProductsFailureAction,
  SAVE_PRODUCTS,
  SAVE_PRODUCTS_FAILURE,
  SAVE_PRODUCTS_SUCCESS,
  SearchProductsFailureAction,
  SearchProductsSuccessAction,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_FAILURE,
  SEARCH_PRODUCTS_SUCCESS,
  SelectProductAction,
  SELECT_PRODUCT
} from './types';

export interface ProductReducerState {
  searchTerms: string;
  searchResults: Product[];
  selected: SelectedProduct[];
  products: SelectedProduct[];
  productsFull: SelectedProductFull[];
  loading: boolean;
  loadingFull: boolean;
  error?: RequestResult<void> | null;
  errorFull?: RequestResult<void> | null;
  notFound: boolean;
}

const initialState: ProductReducerState = {
  searchTerms: '',
  searchResults: [],
  selected: [],
  products: [],
  productsFull: [],
  loading: false,
  loadingFull: false,
  error: null,
  errorFull: null,
  notFound: false
};

export default createReducer<ProductReducerState>(initialState, {
  [CHANGE_SEARCH]: (state, action: ChangeSearchAction) => {
    state.searchTerms = action.searchTerms;
    state.notFound = false;
  },
  [SEARCH_PRODUCTS]: state => {
    state.error = null;
    state.searchResults = [];
    state.loading = true;
    state.notFound = false;
  },
  [SEARCH_PRODUCTS_SUCCESS]: (state, action: SearchProductsSuccessAction) => {
    state.searchResults = action.searchResults;
    state.loading = false;
    state.error = null;
    state.notFound = action.searchResults.length === 0;
  },
  [SEARCH_PRODUCTS_FAILURE]: (state, action: SearchProductsFailureAction) => {
    state.error = action.error;
    state.loading = false;
  },
  [SELECT_PRODUCT]: (state, action: SelectProductAction) => {
    state.selected = [...state.selected, action.selected];
  },
  [DESELECT_PRODUCT]: (state, action: DeselectProductAction) => {
    state.selected = state.selected.filter(product => product.HSCode !== action.selected);
  },
  [DESELECT_ALL_PRODUCTS]: state => {
    state.selected = [];
  },
  [SAVE_PRODUCTS]: state => {
    state.error = null;
    state.loading = true;
  },
  [SAVE_PRODUCTS_SUCCESS]: state => {
    state.error = null;
    state.loading = false;
  },
  [SAVE_PRODUCTS_FAILURE]: (state, action: SaveProductsFailureAction) => {
    state.error = action.error;
    state.loading = false;
  },
  [RETRIEVE_PRODUCTS]: state => {
    state.error = null;
    state.loading = true;
  },
  [RETRIEVE_PRODUCTS_SUCCESS]: (state, action: RetrieveProductsSuccessAction) => {
    state.error = null;
    state.loading = false;
    state.products = action.products;
    state.selected = action.products;
  },
  [RETRIEVE_PRODUCTS_FAILURE]: (state, action: RetrieveProductsFailureAction) => {
    state.error = action.error;
    state.loading = false;
  },
  [RETRIEVE_CRM_CONTACT_SUCCESS]: (state, action: RetrieveCRMContactSuccessAction) => {
    if (action.CRMContact.products) {
      state.selected = action.CRMContact.products;
    }
  },
  [RETRIEVE_PRODUCTS_FULL]: state => {
    state.loadingFull = true;
    state.errorFull = null;
  },
  [RETRIEVE_PRODUCTS_FULL_SUCCESS]: (state, action: RetrieveProductsFullSuccessAction) => {
    state.loadingFull = false;
    state.errorFull = null;
    state.productsFull = action.productsFull;
  },
  [RETRIEVE_PRODUCTS_FULL_FAILURE]: (state, action: RetrieveProductsFullFailureAction) => {
    state.loadingFull = false;
    state.errorFull = action.error;
  }
});
