import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { Question } from './interfaces';
import {
  RetrieveNavigatorQuestionsFailureAction,
  RetrieveNavigatorQuestionsSuccessAction,
  RETRIEVE_QUESTIONS,
  RETRIEVE_QUESTIONS_FAILURE,
  RETRIEVE_QUESTIONS_SUCCESS
} from './types';

export interface ExportNavigatorReducerState {
  loading: boolean;
  error?: RequestResult;
  questions: Question[];
}

const initialState: ExportNavigatorReducerState = {
  loading: false,
  questions: []
};

export default createReducer<ExportNavigatorReducerState>(initialState, {
  [RETRIEVE_QUESTIONS]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [RETRIEVE_QUESTIONS_FAILURE]: (state, action: RetrieveNavigatorQuestionsFailureAction) => {
    state.loading = false;
    state.error = undefined;
    state.questions = [];
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_QUESTIONS_SUCCESS]: (state, action: RetrieveNavigatorQuestionsSuccessAction) => {
    state.loading = false;
    state.error = undefined;
    state.questions = action.questions;
  }
});
