// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrievePostingReasons } from './operations';

// Types
import { AppState } from '../../reducers';
import { PostingReason } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import { doRetrievePostingReasonsSuccess, doRetrievePostingReasons, doRetrievePostingReasonsFailure } from './actions';
import { RETRIEVE_POSTING_REASONS } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrievePostingReasons() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<PostingReason[]>> = yield call(
        retrievePostingReasons,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrievePostingReasonsSuccess(response.data.result));
      } else {
        yield put(doRetrievePostingReasonsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingReasons()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingReasons()));
    } else {
      logging.error(error);
      yield put(doRetrievePostingReasonsFailure());
    }
  }
}

export function* watcherRetrievePostingReasons() {
  yield takeLatest(RETRIEVE_POSTING_REASONS, workerRetrievePostingReasons);
}

export function* postingReasonSaga() {
  yield all([call(watcherRetrievePostingReasons)]);
}
