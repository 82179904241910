import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { User } from '../user/interfaces';
import {
  doRetrieveEvents,
  doRetrieveEventsFailure,
  doRetrieveEventsLoading,
  doRetrieveEventsSuccess,
  doRetrieveEventTeasers,
  doRetrieveEventTeasersFailure,
  doRetrieveEventTeasersLoading,
  doRetrieveEventTeasersSuccess,
  doRetrieveMarketEvents,
  doRetrieveMarketEventsLoading,
  doRetrieveMarketEventsFailure,
  doRetrieveMarketEventsSuccess,
  doRetrieveRelevantUserMarketEvents,
  doRetrieveRelevantUserMarketEventsLoading,
  doRetrieveRelevantUserMarketEventsFailure,
  doRetrieveRelevantUserMarketEventsSuccess,
  doRetrieveMyPastEvents,
  doRetrieveMyPastEventsFailure,
  doRetrieveMyPastEventsLoading,
  doRetrieveMyPastEventsSuccess,
  doRetrieveMyUpcomingEvents,
  doRetrieveMyUpcomingEventsFailure,
  doRetrieveMyUpcomingEventsLoading,
  doRetrieveMyUpcomingEventsSuccess,
  doRetrieveRelevantUserEvents,
  doRetrieveRelevantUserEventsFailure,
  doRetrieveRelevantUserEventsLoading,
  doRetrieveRelevantUserEventsSuccess,
  doSelectEvent,
  doSelectEventFailure,
  doSelectEventSuccess,
  doRetrieveMenuEventsSuccess,
  doRetrieveMenuEventsFailure,
  doRetrieveMenuEvents
} from './actions';
import { CMSEvent } from './interfaces';
import {
  retrieveEvents,
  retrieveMarketEvents,
  retrieveMyPastEvents,
  retrieveMyUpcomingEvents,
  retrieveRelevantUserMarketEvents,
  retrieveUserCustomEvents,
  selectEvent
} from './operations';
import {
  RetrieveEventsAction,
  RetrieveEventTeasersAction,
  RetrieveMarketEventsAction,
  RetrieveMyPastEventsAction,
  RetrieveMyUpcomingEventsAction,
  RetrieveRelevantUserEventsAction,
  RetrieveRelevantUserMarketEventsAction,
  RETRIEVE_EVENTS,
  RETRIEVE_EVENT_TEASERS,
  RETRIEVE_MARKET_EVENTS,
  RETRIEVE_MENU_EVENTS,
  RETRIEVE_MY_PAST_EVENTS,
  RETRIEVE_MY_UPCOMING_EVENTS,
  RETRIEVE_RELEVANT_USER_EVENTS,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS,
  SelectEventAction,
  SELECT_EVENT
} from './types';

function* workerSelectEvent({ drupalNodeID }: SelectEventAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent>> = yield call(
        selectEvent,
        locale,
        drupalNodeID,
        profile.accessToken
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doSelectEventSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSelectEvent(drupalNodeID)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSelectEvent(drupalNodeID)));
    } else {
      logging.error(error);
      yield put(doSelectEventFailure(error));
    }
  }
}

function* workerRetrieveMarketEvents({ slug }: RetrieveMarketEventsAction) {
  try {
    yield put(doRetrieveMarketEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent[]>> = yield call(
        retrieveMarketEvents,
        profile.accessToken,
        locale,
        slug
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveMarketEventsSuccess(response.data.result));
      } else {
        yield put(doRetrieveMarketEventsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketEvents(slug)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketEvents(slug)));
    } else {
      logging.error(error);
      yield put(doRetrieveMarketEventsFailure());
    }
  }
}

function* workerRetrieveRelevantUserMarketEvents({ slug }: RetrieveRelevantUserMarketEventsAction) {
  try {
    yield put(doRetrieveRelevantUserMarketEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent[]>> = yield call(
        retrieveRelevantUserMarketEvents,
        profile.accessToken,
        locale,
        slug
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveRelevantUserMarketEventsSuccess(response.data.result));
      } else {
        yield put(doRetrieveRelevantUserMarketEventsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveRelevantUserMarketEvents(slug)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveRelevantUserMarketEvents(slug)));
    } else {
      logging.error(error);
      yield put(doRetrieveRelevantUserMarketEventsFailure());
    }
  }
}

const getEventsTotal = (data: RequestResult<CMSEvent[]>): number => {
  return Number(data?.meta?.count || 0);
};

function* workerRetrieveEvents({ limit, offset }: RetrieveEventsAction) {
  try {
    yield put(doRetrieveEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent[]>> = yield call(
        retrieveEvents,
        limit,
        offset,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        const events = yield select((state: AppState) => state.eventReducer.events);
        const total = getEventsTotal(response.data);
        yield put(doRetrieveEventsSuccess([...events.slice(0, offset), ...response.data.result], total));
      } else {
        yield put(doRetrieveEventsFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveEvents({ limit, offset })));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveEvents({ limit, offset })));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveEventsFailure(errorResult));
    }
  }
}

function* workerRetrieveMenuEvents({ limit, offset }: RetrieveEventsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent[]>> = yield call(
        retrieveEvents,
        limit,
        offset,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        const total = getEventsTotal(response.data);
        yield put(doRetrieveMenuEventsSuccess(response.data.result, total));
      } else {
        yield put(doRetrieveMenuEventsFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveMenuEvents({ limit, offset })));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveMenuEvents({ limit, offset })));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveMenuEventsFailure(errorResult));
    }
  }
}

function* workerRetrieveRelevantUserEvents({ limit, offset }: RetrieveRelevantUserEventsAction) {
  try {
    yield put(doRetrieveRelevantUserEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSEvent[]>> = yield call(
        retrieveUserCustomEvents,
        limit,
        offset,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        const events = yield select((state: AppState) => state.eventReducer.relevantUserEvents);
        const total = getEventsTotal(response.data);
        yield put(doRetrieveRelevantUserEventsSuccess([...events.slice(0, offset), ...response.data.result], total));
      } else {
        yield put(doRetrieveRelevantUserEventsFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveRelevantUserEvents({ limit, offset })));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveRelevantUserEvents({ limit, offset })));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveRelevantUserEventsFailure(errorResult));
    }
  }
}

function* workerRetrieveEventTeasers({ limit, ignoreEvents = [] }: RetrieveEventTeasersAction) {
  try {
    yield put(doRetrieveEventTeasersLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    const user: User | undefined = yield select((state: AppState) => state.userReducer.user);

    if (profile) {
      const eventsLimit = ignoreEvents.length > 0 ? limit + ignoreEvents.length : limit;

      let response: AxiosResponse<RequestResult<CMSEvent[]>>;
      if (user?.onboarded) {
        response = yield call(retrieveUserCustomEvents, eventsLimit, 0, profile.accessToken, locale);
      } else {
        response = yield call(retrieveEvents, eventsLimit, 0, profile.accessToken, locale);
      }

      if (response.data && response.data.succeeded && response.data.result) {
        const events =
          ignoreEvents.length > 0
            ? [...response.data.result]
                .filter(({ drupalNodeId }) => !ignoreEvents.includes(drupalNodeId))
                .slice(0, limit)
            : response.data.result;

        yield put(doRetrieveEventTeasersSuccess(events, limit));
      } else {
        yield put(doRetrieveEventTeasersFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveEventTeasers(limit, ignoreEvents)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveEventTeasers(limit, ignoreEvents)));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveEventTeasersFailure(errorResult));
    }
  }
}

function* workerRetrieveMyUpcomingEvents({ limit, offset, slug }: RetrieveMyUpcomingEventsAction) {
  try {
    yield put(doRetrieveMyUpcomingEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveMyUpcomingEvents, limit, offset, slug, profile.accessToken, locale);

      if (response.data && response.data.succeeded && response.data.result) {
        const events = yield select((state: AppState) => state.eventReducer.myUpcomingEvents);
        const total = getEventsTotal(response.data);
        yield put(doRetrieveMyUpcomingEventsSuccess([...events.slice(0, offset), ...response.data.result], total));
      } else {
        yield put(doRetrieveMyUpcomingEventsFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveMyUpcomingEvents({ limit, offset, slug })));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveMyUpcomingEvents({ limit, offset, slug })));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveMyUpcomingEventsFailure(errorResult));
    }
  }
}

function* workerRetrieveMyPastEvents({ limit, offset }: RetrieveMyPastEventsAction) {
  try {
    yield put(doRetrieveMyPastEventsLoading());
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveMyPastEvents, limit, offset, profile.accessToken, locale);

      if (response.data && response.data.succeeded && response.data.result) {
        const events = yield select((state: AppState) => state.eventReducer.myPastEvents);
        const total = getEventsTotal(response.data);
        yield put(doRetrieveMyPastEventsSuccess([...events.slice(0, offset), ...response.data.result], total));
      } else {
        yield put(doRetrieveMyPastEventsFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveMyPastEvents({ limit, offset })));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveMyPastEvents({ limit, offset })));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveMyPastEventsFailure(errorResult));
    }
  }
}

export function* watcherRetrieveEvents() {
  yield takeLatest(RETRIEVE_EVENTS, workerRetrieveEvents);
}

export function* watcherRetrieveMenuEvents() {
  yield takeLatest(RETRIEVE_MENU_EVENTS, workerRetrieveMenuEvents);
}

export function* watcherRelevantUserRetrieveEvents() {
  yield takeLatest(RETRIEVE_RELEVANT_USER_EVENTS, workerRetrieveRelevantUserEvents);
}

export function* watcherRetrieveEventTeasers() {
  yield takeLatest(RETRIEVE_EVENT_TEASERS, workerRetrieveEventTeasers);
}

export function* watcherRetrieveMyUpcomingEvents() {
  yield takeLatest(RETRIEVE_MY_UPCOMING_EVENTS, workerRetrieveMyUpcomingEvents);
}

export function* watcherRetrieveMyPastEvents() {
  yield takeLatest(RETRIEVE_MY_PAST_EVENTS, workerRetrieveMyPastEvents);
}

export function* watcherSelectEvent() {
  yield takeLatest(SELECT_EVENT, workerSelectEvent);
}

export function* watcherRetrieveMarketEvents() {
  yield takeLatest(RETRIEVE_MARKET_EVENTS, workerRetrieveMarketEvents);
}

export function* watcherRetrieveRelevantUserMarketEvents() {
  yield takeLatest(RETRIEVE_RELEVANT_USER_MARKET_EVENTS, workerRetrieveRelevantUserMarketEvents);
}

export function* eventSaga() {
  yield all([
    call(watcherRetrieveMarketEvents),
    call(watcherRetrieveRelevantUserMarketEvents),
    call(watcherSelectEvent),
    call(watcherRetrieveEvents),
    call(watcherRelevantUserRetrieveEvents),
    call(watcherRetrieveEventTeasers),
    call(watcherRetrieveMyUpcomingEvents),
    call(watcherRetrieveMyPastEvents),
    call(watcherRetrieveMenuEvents)
  ]);
}
