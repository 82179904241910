import { BusinessTypeEnumerable } from 'src/utils/userUtils';
import { RequestResult } from '../../../interfaces/requests';
import {
  OnboardUserAction,
  OnboardUserFailureAction,
  OnboardUserSuccessAction,
  ONBOARD_USER,
  ONBOARD_USER_FAILURE,
  ONBOARD_USER_SUCCESS,
  RetrieveCRMContactAction,
  RetrieveCRMContactFailureAction,
  RetrieveCRMContactSuccessAction,
  RETRIEVE_CRM_CONTACT,
  RETRIEVE_CRM_CONTACT_FAILURE,
  RETRIEVE_CRM_CONTACT_SUCCESS,
  UpdateOnboardingCompanyAction,
  UpdateOnboardingIndustriesAction,
  UpdateOnboardingStateAction,
  UpdateOnboardingUserAction,
  UPDATE_ONBOARDING_COMPANY,
  UPDATE_ONBOARDING_INDUSTRIES,
  UPDATE_ONBOARDING_STATE,
  UPDATE_ONBOARDING_USER
} from './types';

export const doRetrieveCRMContact = (): RetrieveCRMContactAction => ({
  type: RETRIEVE_CRM_CONTACT
});

export const doRetrieveCRMContactSuccess = (
  CRMContact: RetrieveCRMContactSuccessAction['CRMContact']
): RetrieveCRMContactSuccessAction => ({
  CRMContact,
  type: RETRIEVE_CRM_CONTACT_SUCCESS
});

export const doRetrieveCRMContactFailure = (error?: RequestResult<void>): RetrieveCRMContactFailureAction => ({
  type: RETRIEVE_CRM_CONTACT_FAILURE,
  error
});

export const doUpdateOnboardingUser = (user: UpdateOnboardingUserAction['user']): UpdateOnboardingUserAction => ({
  user,
  type: UPDATE_ONBOARDING_USER
});

export const doUpdateOnboardingCompany = (
  company: UpdateOnboardingCompanyAction['company']
): UpdateOnboardingCompanyAction => ({
  type: UPDATE_ONBOARDING_COMPANY,
  company
});

export const doUpdateOnboardingIndustries = (
  industries: UpdateOnboardingIndustriesAction['industries']
): UpdateOnboardingIndustriesAction => ({
  type: UPDATE_ONBOARDING_INDUSTRIES,
  industries
});

export const doOnboardUser = (
  onFinish: OnboardUserAction['onFinish'],
  onError: OnboardUserAction['onError']
): OnboardUserAction => ({
  type: ONBOARD_USER,
  onFinish,
  onError
});

export const doOnboardUserSuccess = (user: OnboardUserSuccessAction['user']): OnboardUserSuccessAction => ({
  user,
  type: ONBOARD_USER_SUCCESS
});

export const doOnboardUserFailure = (error?: RequestResult<void>): OnboardUserFailureAction => ({
  type: ONBOARD_USER_FAILURE,
  error
});

//Used only for Intercom purposes - to be able to get acces to the current selection of business type + eventually other values
export const doUpdateOnboardingState = (businessType: BusinessTypeEnumerable): UpdateOnboardingStateAction => ({
  businessType,
  type: UPDATE_ONBOARDING_STATE
});
