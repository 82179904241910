import { createReducer } from 'redux-starter-kit';
import { Country, GlobalImportVolume } from './interfaces';
import {
  RETRIEVE_GLOBAL_IMPORT_VOLUME,
  RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE,
  RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS,
  RetrieveGlobalImportVolumeFailureAction,
  RetrieveGlobalImportVolumeSuccessAction,
  RETRIEVE_LARGEST_IMPORTERS,
  RETRIEVE_LARGEST_IMPORTERS_SUCCESS,
  RETRIEVE_LARGEST_IMPORTERS_FAILURE,
  RetrieveLargestImportersSuccessAction,
  RetrieveLargestImportersFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface DashboardReducerState {
  globalImportLoading: boolean;
  largestImportersLoading: boolean;
  globalImportError?: RequestResult<void>;
  largestImportersError?: RequestResult<void>;
  globalImportVolume?: GlobalImportVolume;
  largestImporters: Country[];
}

const initialState: DashboardReducerState = {
  globalImportLoading: false,
  largestImportersLoading: false,
  largestImporters: []
};

export default createReducer<DashboardReducerState>(initialState, {
  [RETRIEVE_GLOBAL_IMPORT_VOLUME]: state => {
    state.globalImportError = undefined;
    state.globalImportLoading = true;
  },
  [RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS]: (state, action: RetrieveGlobalImportVolumeSuccessAction) => {
    state.globalImportError = undefined;
    state.globalImportLoading = false;
    state.globalImportVolume = action.globalImportVolume;
  },
  [RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE]: (state, action: RetrieveGlobalImportVolumeFailureAction) => {
    state.globalImportError = action.error;
    state.globalImportLoading = false;
  },
  [RETRIEVE_LARGEST_IMPORTERS]: state => {
    state.largestImportersError = undefined;
    state.largestImportersLoading = true;
  },
  [RETRIEVE_LARGEST_IMPORTERS_SUCCESS]: (state, action: RetrieveLargestImportersSuccessAction) => {
    state.largestImportersError = undefined;
    state.largestImportersLoading = false;
    state.largestImporters = action.largestImporters;
  },
  [RETRIEVE_LARGEST_IMPORTERS_FAILURE]: (state, action: RetrieveLargestImportersFailureAction) => {
    state.largestImportersError = action.error;
    state.largestImportersLoading = false;
  }
});
