// Modules
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import {
  addWatchlistCompany,
  removeWatchlistCompanies,
  removeWatchlistCompany,
  retrieveWatchlistCompanies
} from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import {
  doAddWatchlistCompany,
  doAddWatchlistCompanyFailure,
  doAddWatchlistCompanySuccess,
  doRetrieveWatchlistCompanies,
  doRetrieveWatchlistCompaniesFailure,
  doRetrieveWatchlistCompaniesSuccess,
  doRemoveWatchlistCompanyFailure,
  doRemoveWatchlistCompany,
  doRemoveWatchlistCompanySuccess,
  doRemoveWatchlistCompaniesFailure,
  doRemoveWatchlistCompanies,
  doRemoveWatchlistCompaniesSuccess
} from './actions';
import {
  ADD_WATCHLIST_COMPANY,
  AddWatchlistCompanyAction,
  REMOVE_WATCHLIST_COMPANIES,
  REMOVE_WATCHLIST_COMPANY,
  RETRIEVE_WATCHLIST_COMPANIES,
  RemoveWatchlistCompaniesAction,
  RemoveWatchlistCompanyAction,
  RetrieveWatchlistCompaniesAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveWatchlistCompanies(action: RetrieveWatchlistCompaniesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveWatchlistCompanies, profile.accessToken, locale, action.watchlistId);
      if (response.data) {
        yield put(doRetrieveWatchlistCompaniesSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistCompanies(action.watchlistId)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistCompanies(action.watchlistId)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistCompaniesFailure(error));
    }
  }
}

function* workerAddWatchlistCompany(action: AddWatchlistCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(addWatchlistCompany, profile.accessToken, locale, action.watchlistId, action.data);
      if (response.data) {
        yield put(doAddWatchlistCompanySuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistCompany(action.watchlistId, action.data))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistCompany(action.watchlistId, action.data))
      );
    } else {
      logging.error(error);
      yield put(doAddWatchlistCompanyFailure(error?.response?.data));
    }
  }
}

function* workerRemoveWatchlistCompany(action: RemoveWatchlistCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(
        removeWatchlistCompany,
        profile.accessToken,
        locale,
        action.watchlistId,
        action.companyId
      );
      if (response.data) {
        yield put(doRemoveWatchlistCompanySuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistCompany(action.watchlistId, action.companyId))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistCompany(action.watchlistId, action.companyId))
      );
    } else {
      logging.error(error);
      yield put(doRemoveWatchlistCompanyFailure(error));
    }
  }
}

function* workerRemoveWatchlistCompanies(action: RemoveWatchlistCompaniesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(
        removeWatchlistCompanies,
        profile.accessToken,
        locale,
        action.watchlistId,
        action.companyIds
      );
      if (response.data) {
        yield put(doRemoveWatchlistCompaniesSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistCompanies(action.watchlistId, action.companyIds))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistCompanies(action.watchlistId, action.companyIds))
      );
    } else {
      logging.error(error);
      yield put(doRemoveWatchlistCompaniesFailure(error));
    }
  }
}

export function* watcherRemoveWatchlistCompanies() {
  yield takeLatest(REMOVE_WATCHLIST_COMPANIES, workerRemoveWatchlistCompanies);
}

export function* watcherRemoveWatchlistCompany() {
  yield takeLatest(REMOVE_WATCHLIST_COMPANY, workerRemoveWatchlistCompany);
}

export function* watcherAddWatchlistCompany() {
  yield takeLatest(ADD_WATCHLIST_COMPANY, workerAddWatchlistCompany);
}

export function* watcherRetrieveWatchlistCompanies() {
  yield takeLatest(RETRIEVE_WATCHLIST_COMPANIES, workerRetrieveWatchlistCompanies);
}

export function* watchlistCompanySaga() {
  yield all([
    call(watcherRetrieveWatchlistCompanies),
    call(watcherAddWatchlistCompany),
    call(watcherRemoveWatchlistCompany),
    call(watcherRemoveWatchlistCompanies)
  ]);
}
