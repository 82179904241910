import { createReducer } from 'redux-starter-kit';
import { SupsWatchlistCommentRaw } from './interfaces';
import {
  RETRIEVE_WATCHLIST_COMMENTS,
  RETRIEVE_WATCHLIST_COMMENTS_SUCCESS,
  RETRIEVE_WATCHLIST_COMMENTS_FAILURE,
  ADD_WATCHLIST_COMMENT,
  ADD_WATCHLIST_COMMENT_SUCCESS,
  ADD_WATCHLIST_COMMENT_FAILURE,
  RetrieveWatchlistCommentsFailureAction,
  RetrieveWatchlistCommentsSuccessAction,
  REMOVE_WATCHLIST_COMMENT,
  REMOVE_WATCHLIST_COMMENT_SUCCESS,
  REMOVE_WATCHLIST_COMMENT_FAILURE,
  CLEAR_WATCHLIST_COMMENT_MODIFICATIONS
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface WatchlistCommentsReducerState {
  comments?: SupsWatchlistCommentRaw[];
  isCommentsLoading: boolean;
  commentsError: RequestResult<void> | null;

  modifiedWatchlistComment?: SupsWatchlistCommentRaw;
  isModificationLoading: boolean;
  isModificationSuccess?: boolean;
  modifiedWatchlistCommentError: RequestResult<void> | null;
}

const initialState: WatchlistCommentsReducerState = {
  comments: undefined,
  isCommentsLoading: false,
  commentsError: null,

  modifiedWatchlistComment: undefined,
  isModificationLoading: false,
  isModificationSuccess: undefined,
  modifiedWatchlistCommentError: null
};

const modifyHandler = (state: WatchlistCommentsReducerState) => {
  state.isModificationLoading = true;
  state.isModificationSuccess = undefined;
  state.modifiedWatchlistCommentError = null;
};

const modifySuccessHandler = <T extends { comment: SupsWatchlistCommentRaw }>(
  state: WatchlistCommentsReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistComment = action.comment;
  state.modifiedWatchlistCommentError = null;
  state.isModificationSuccess = true;
};

const modifyErrorHandler = <T extends { error?: RequestResult<void> }>(
  state: WatchlistCommentsReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistComment = undefined;
  state.isModificationSuccess = false;
  if (action.error) {
    state.modifiedWatchlistCommentError = action.error;
  }
};

export default createReducer<WatchlistCommentsReducerState>(initialState, {
  [RETRIEVE_WATCHLIST_COMMENTS]: state => {
    state.isCommentsLoading = true;
    state.commentsError = null;
  },
  [RETRIEVE_WATCHLIST_COMMENTS_SUCCESS]: (state, action: RetrieveWatchlistCommentsSuccessAction) => {
    state.isCommentsLoading = false;
    state.comments = action.watchlistComments;
    state.commentsError = null;
  },
  [RETRIEVE_WATCHLIST_COMMENTS_FAILURE]: (state, action: RetrieveWatchlistCommentsFailureAction) => {
    state.isCommentsLoading = false;
    state.comments = undefined;
    if (action.error) {
      state.commentsError = action.error;
    }
  },
  [ADD_WATCHLIST_COMMENT]: modifyHandler,
  [ADD_WATCHLIST_COMMENT_SUCCESS]: modifySuccessHandler,
  [ADD_WATCHLIST_COMMENT_FAILURE]: modifyErrorHandler,
  [REMOVE_WATCHLIST_COMMENT]: modifyHandler,
  [REMOVE_WATCHLIST_COMMENT_SUCCESS]: modifySuccessHandler,
  [REMOVE_WATCHLIST_COMMENT_FAILURE]: modifyErrorHandler,
  [CLEAR_WATCHLIST_COMMENT_MODIFICATIONS]: state => {
    state.modifiedWatchlistComment = undefined;
    state.isModificationLoading = false;
    state.isModificationSuccess = undefined;
    state.modifiedWatchlistCommentError = null;
  }
});
