import { createReducer } from 'redux-starter-kit';
import { SupsRecord, SupsResult, SupsSuggestResult } from './interfaces';
import {
  RETRIEVE_SUPS_COMPANY,
  RETRIEVE_SUPS_COMPANY_FAILURE,
  RETRIEVE_SUPS_COMPANY_SUCCESS,
  RETRIEVE_SUPS_DETAILS,
  RETRIEVE_SUPS_DETAILS_FAILURE,
  RETRIEVE_SUPS_DETAILS_SUCCESS,
  RetrieveSupsCompanyFailureAction,
  RetrieveSupsCompanySuccessAction,
  RetrieveSupsDetailsFailureAction,
  RetrieveSupsDetailsSuccessAction,
  SEARCH_SUPS,
  SEARCH_SUPS_FAILURE,
  SEARCH_SUPS_SUCCESS,
  SUGGEST_SUPS_SEARCH,
  SUGGEST_SUPS_SEARCH_FAILURE,
  SUGGEST_SUPS_SEARCH_SUCCESS,
  SearchSupsFailureAction,
  SearchSupsSuccessAction,
  SuggestSupsSearchFailureAction,
  SuggestSupsSearchSuccessAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface SupsReducerState {
  searchResult?: SupsResult;
  searchUrl?: string;
  isSearchLoading: boolean;
  searchError: RequestResult<void> | null;

  detailsResult?: SupsResult;
  detailsUrl?: string;
  isDetailsLoading: boolean;
  detailsError: RequestResult<void> | null;

  companyResult?: SupsRecord;
  companyUrl?: string;
  isCompanyLoading: boolean;
  companyError: RequestResult<void> | null;

  suggestResult?: SupsSuggestResult;
  isSuggestLoading: boolean;
  suggestError: RequestResult<void> | null;
}

const initialState: SupsReducerState = {
  searchResult: undefined,
  searchUrl: undefined,
  isSearchLoading: false,
  searchError: null,

  detailsResult: undefined,
  detailsUrl: undefined,
  isDetailsLoading: false,
  detailsError: null,

  companyResult: undefined,
  companyUrl: undefined,
  isCompanyLoading: false,
  companyError: null,

  suggestResult: undefined,
  isSuggestLoading: false,
  suggestError: null
};

export default createReducer<SupsReducerState>(initialState, {
  [SEARCH_SUPS]: state => {
    state.isSearchLoading = true;
    state.searchError = null;
  },
  [SEARCH_SUPS_SUCCESS]: (state, action: SearchSupsSuccessAction) => {
    state.isSearchLoading = false;
    state.searchResult = action.sups;
    state.searchError = null;
    state.searchUrl = action.currentUrl;
  },
  [SEARCH_SUPS_FAILURE]: (state, action: SearchSupsFailureAction) => {
    state.isSearchLoading = false;
    state.searchResult = undefined;
    if (action.error) {
      state.searchError = action.error;
    }
  },
  [RETRIEVE_SUPS_DETAILS]: state => {
    state.isDetailsLoading = true;
    state.detailsError = null;
  },
  [RETRIEVE_SUPS_DETAILS_SUCCESS]: (state, action: RetrieveSupsDetailsSuccessAction) => {
    state.isDetailsLoading = false;
    state.detailsResult = action.sups;
    state.detailsError = null;
    state.detailsUrl = action.currentUrl;
  },
  [RETRIEVE_SUPS_DETAILS_FAILURE]: (state, action: RetrieveSupsDetailsFailureAction) => {
    state.isDetailsLoading = false;
    state.detailsResult = undefined;
    if (action.error) {
      state.detailsError = action.error;
    }
  },
  [RETRIEVE_SUPS_COMPANY]: state => {
    state.isCompanyLoading = true;
    state.companyError = null;
  },
  [RETRIEVE_SUPS_COMPANY_SUCCESS]: (state, action: RetrieveSupsCompanySuccessAction) => {
    state.isCompanyLoading = false;
    state.companyResult = action.record;
    state.companyError = null;
    state.companyUrl = action.currentUrl;
  },
  [RETRIEVE_SUPS_COMPANY_FAILURE]: (state, action: RetrieveSupsCompanyFailureAction) => {
    state.isCompanyLoading = false;
    state.companyResult = undefined;
    if (action.error) {
      state.companyError = action.error;
    }
  },
  [SUGGEST_SUPS_SEARCH]: state => {
    state.isSuggestLoading = true;
    state.suggestError = null;
  },
  [SUGGEST_SUPS_SEARCH_SUCCESS]: (state, action: SuggestSupsSearchSuccessAction) => {
    state.isSuggestLoading = false;
    state.suggestResult = action.result;
    state.suggestError = null;
  },
  [SUGGEST_SUPS_SEARCH_FAILURE]: (state, action: SuggestSupsSearchFailureAction) => {
    state.isSuggestLoading = false;
    state.suggestResult = undefined;
    if (action.error) {
      state.suggestError = action.error;
    }
  }
});
