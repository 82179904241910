import { createReducer } from 'redux-starter-kit';
import { CRMContact } from './interfaces';
import {
  RETRIEVE_CRM_CONTACT,
  RETRIEVE_CRM_CONTACT_SUCCESS,
  RETRIEVE_CRM_CONTACT_FAILURE,
  RetrieveCRMContactSuccessAction,
  RetrieveCRMContactFailureAction,
  UPDATE_ONBOARDING_USER,
  UPDATE_ONBOARDING_COMPANY,
  UPDATE_ONBOARDING_INDUSTRIES,
  UpdateOnboardingUserAction,
  UpdateOnboardingCompanyAction,
  UpdateOnboardingIndustriesAction,
  ONBOARD_USER,
  ONBOARD_USER_SUCCESS,
  ONBOARD_USER_FAILURE,
  OnboardUserFailureAction,
  UPDATE_ONBOARDING_STATE,
  UpdateOnboardingStateAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '../user/interfaces';
import { Company } from '../company/interfaces';
import { Industry } from '../industry/interfaces';
import { LOGOUT_USER } from '../auth/types';
import { BusinessTypeEnumerable } from 'src/utils/userUtils';

export interface OnboardingReducerState {
  CRMContact?: CRMContact;
  loading: boolean;
  onboardingUser?: Partial<User>;
  onboardingCompany?: Partial<Company>;
  onboardingIndustries?: Industry[];
  error?: RequestResult<void> | null;
  onboardingInProcess?: boolean;
  onboardingFormsCurrentValues: {
    businessType: BusinessTypeEnumerable | null;
  };
}

const initialState: OnboardingReducerState = {
  loading: false,
  onboardingInProcess: false,
  onboardingFormsCurrentValues: { businessType: null }
};

export default createReducer<OnboardingReducerState>(initialState, {
  [RETRIEVE_CRM_CONTACT]: state => {
    state.loading = true;
    state.error = null;
  },
  [RETRIEVE_CRM_CONTACT_SUCCESS]: (state, action: RetrieveCRMContactSuccessAction) => {
    state.loading = false;
    state.CRMContact = action.CRMContact;
    state.error = null;
    if (action.CRMContact.industries) {
      state.onboardingIndustries = action.CRMContact.industries;
    }
  },
  [RETRIEVE_CRM_CONTACT_FAILURE]: (state, action: RetrieveCRMContactFailureAction) => {
    state.loading = false;
    state.error = action.error;
  },
  [UPDATE_ONBOARDING_USER]: (state, action: UpdateOnboardingUserAction) => {
    state.loading = false;
    state.onboardingUser = { ...state.onboardingUser, ...action.user };
  },
  [UPDATE_ONBOARDING_COMPANY]: (state, action: UpdateOnboardingCompanyAction) => {
    state.loading = false;
    if (action.company) {
      state.onboardingCompany = { ...state.onboardingCompany, ...action.company };
    } else {
      state.onboardingCompany = undefined;
    }
  },
  [UPDATE_ONBOARDING_INDUSTRIES]: (state, action: UpdateOnboardingIndustriesAction) => {
    state.loading = false;
    state.onboardingIndustries = action.industries;
  },
  [ONBOARD_USER]: state => {
    state.loading = true;
    state.onboardingInProcess = true;
    state.error = null;
  },
  [ONBOARD_USER_SUCCESS]: state => {
    state.loading = false;
    state.onboardingInProcess = true;
    state.error = null;
  },
  [ONBOARD_USER_FAILURE]: (state, action: OnboardUserFailureAction) => {
    state.loading = false;
    state.onboardingInProcess = false;
    state.error = action.error;
  },
  [LOGOUT_USER]: state => {
    state.onboardingUser = undefined;
    state.onboardingInProcess = false;
    state.onboardingCompany = undefined;
    state.onboardingIndustries = undefined;
    state.CRMContact = undefined;
  },
  [UPDATE_ONBOARDING_STATE]: (state, action: UpdateOnboardingStateAction) => {
    state.onboardingFormsCurrentValues.businessType = action.businessType;
  }
});
