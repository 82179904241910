import { createReducer } from 'redux-starter-kit';
import { BusinessOpportunityDetailsMMBrain } from './interfaces';
import {
  RETRIEVE_BUSINESS_OPPORTUNITY,
  RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE,
  RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS,
  RetrieveBusinessOpportunityFailureAction,
  RetrieveBusinessOpportunitySuccessAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface BusinessOpportunityReducerState {
  businessOpportunityDetails?: BusinessOpportunityDetailsMMBrain;
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
  submitBusinessOpportunityEmailLoading: boolean;
  submitBusinessOpportunityEmailLoadingError: RequestResult<void> | null;
}

const initialState: BusinessOpportunityReducerState = {
  businessOpportunityDetails: undefined,
  retrieveLoading: false,
  error: null,
  submitBusinessOpportunityEmailLoading: false,
  submitBusinessOpportunityEmailLoadingError: null
};

export default createReducer<BusinessOpportunityReducerState>(initialState, {
  [RETRIEVE_BUSINESS_OPPORTUNITY]: state => {
    state.retrieveLoading = true;
    state.businessOpportunityDetails = undefined;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITY_SUCCESS]: (state, action: RetrieveBusinessOpportunitySuccessAction) => {
    state.retrieveLoading = false;
    state.businessOpportunityDetails = action.businessOpportunityDetails;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITY_FAILURE]: (state, action: RetrieveBusinessOpportunityFailureAction) => {
    state.retrieveLoading = false;
    state.businessOpportunityDetails = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
