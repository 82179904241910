// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveCountries } from './operations';

// Types
import { AppState } from '../../reducers';
import { Country } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import { doRetrieveCountriesSuccess, doRetrieveCountries, doRetrieveCountriesFailure } from './actions';
import { RETRIEVE_COUNTRIES } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveCountries() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Country[]>> = yield call(
        retrieveCountries,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveCountriesSuccess(response.data.result));
      } else {
        yield put(doRetrieveCountriesFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCountries()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCountries()));
    } else {
      logging.error(error);
      yield put(doRetrieveCountriesFailure());
    }
  }
}

export function* watcherRetrieveCountries() {
  yield takeLatest(RETRIEVE_COUNTRIES, workerRetrieveCountries);
}

export function* countrySaga() {
  yield all([call(watcherRetrieveCountries)]);
}
