// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrievePostingNationalities } from './operations';

// Types
import { AppState } from '../../reducers';
import { PostingNationality } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrievePostingNationalitiesSuccess,
  doRetrievePostingNationalities,
  doRetrievePostingNationalitiesFailure
} from './actions';
import { RETRIEVE_POSTING_NATIONALITIES } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrievePostingNationalities() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<PostingNationality[]>> = yield call(
        retrievePostingNationalities,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrievePostingNationalitiesSuccess(response.data.result));
      } else {
        yield put(doRetrievePostingNationalitiesFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingNationalities()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingNationalities()));
    } else {
      logging.error(error);
      yield put(doRetrievePostingNationalitiesFailure());
    }
  }
}

export function* watcherRetrievePostingNationalities() {
  yield takeLatest(RETRIEVE_POSTING_NATIONALITIES, workerRetrievePostingNationalities);
}

export function* postingNationalitySaga() {
  yield all([call(watcherRetrievePostingNationalities)]);
}
