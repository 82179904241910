import { clearCachedTranslations, Locale } from 'src/locales';
import { NextComponentType, NextPageContext } from 'next';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'src/components/GlobalStyles';
import Head from 'next/head';
import ActionsProvider from './components/Actions';
import IntercomContainer from './components/Intercom';
import OnPageEditor from './components/OnPageEditor';
import ScrollUpButton from './components/ScrollUpButton';
import { AppState } from 'src/redux/reducers';
import { connect } from 'react-redux';
import { doRefreshingAppSuccess, doRetrieveTranslations } from 'src/redux/modules/app/actions';
import { useRouter } from 'next/router';
import { Navbar, Contact } from '@elements';
import { User } from '@redux-modules/user/interfaces';
import Theme from 'src/components/Theme';
import MomentLanguage from './components/MomentLanguage';
import Styles from './styles';
import NotificationContainer from './components/NotificationContainer';

const AppComponent = (
  props: {
    language: Locale;
    pageProps: object;
    Component: NextComponentType<NextPageContext<any, AnyAction>, any, object>;
  } & AppProps
) => {
  const router = useRouter();

  function storePathValues() {
    if (!localStorage) return;
    // Set the previous path as the value of the current path.
    const prevPath = localStorage.getItem('current-path');
    localStorage.setItem('prev-path', prevPath || '');
    // Set the current path value by looking at the browser's location object.
    localStorage.setItem('current-path', globalThis.location.pathname);
  }

  useEffect(() => storePathValues, [router.asPath]);

  useEffect(() => {
    if (props.translationUpdatingStatus == 'storing-in-db-success') {
      clearCachedTranslations();
      props.doRefreshingAppSuccess();
    }
  }, [props.translationUpdatingStatus]);

  // TODO: find better solution
  const isAuthPage = ['/login', '/join', '/signup', '/resetpassword', '/logout'].some(path =>
    router.route.startsWith(path)
  );
  const isLoggedIn = props.user && props.profile && !isAuthPage;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
        <title>S-GE</title>
      </Head>
      <IntlProvider key={props.language} locale={props.language} messages={props.translations}>
        <ThemeProvider theme={Theme}>
          <MomentLanguage locale={props.language}>
            <GlobalStyles />
            <ActionsProvider>
              <Styles.TopRoot id="top-root"></Styles.TopRoot>
              <div id="modal-root"></div>
              {isLoggedIn && <Navbar user={props.user as User} />}
              <NotificationContainer />
              <props.Component {...props.pageProps} />
              <OnPageEditor />
              <IntercomContainer />
              {isLoggedIn && process.browser && (
                <>
                  <ScrollUpButton />
                  <Contact />
                </>
              )}
            </ActionsProvider>
          </MomentLanguage>
        </ThemeProvider>
      </IntlProvider>
    </>
  );
};

export type AppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = ({ appReducer, userReducer, authReducer }: AppState) => ({
  currentTranslationKey: appReducer.currentTranslationKey,
  translationUpdatingStatus: appReducer.translationUpdatingStatus,
  profile: authReducer.profile,
  user: userReducer.user,
  translations: appReducer.translations
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doRetrieveTranslations,
      doRefreshingAppSuccess
    },
    dispatch
  );

export type OnPageEditorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
