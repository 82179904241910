import {
  RETRIEVE_BUSINESS_TYPES,
  RETRIEVE_BUSINESS_TYPES_FAILURE,
  RETRIEVE_BUSINESS_TYPES_SUCCESS,
  RetrieveBusinessTypesAction,
  RetrieveBusinessTypesFailureAction,
  RetrieveBusinessTypesSuccessAction
} from './types';
import { BusinessType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveBusinessTypes = (): RetrieveBusinessTypesAction => ({
  type: RETRIEVE_BUSINESS_TYPES
});

export const doRetrieveBusinessTypesFailure = (error?: RequestResult<void>): RetrieveBusinessTypesFailureAction => ({
  error,
  type: RETRIEVE_BUSINESS_TYPES_FAILURE
});

export const doRetrieveBusinessTypesSuccess = (businessTypes: BusinessType[]): RetrieveBusinessTypesSuccessAction => ({
  type: RETRIEVE_BUSINESS_TYPES_SUCCESS,
  businessTypes
});
