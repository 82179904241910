import React from 'react';
import Styles from './styles';

import { useScrollPosition } from '@utils/viewportUtils';

const ScrollUpButton: React.FC = () => {
  const scrollPosition = useScrollPosition();
  const scrollToTop = () => window.scrollTo(0, 0);
  const isSecondaryNavigationExists = document.querySelector('[data-test-id="table-of-contents-navbar"]');
  const isVisible = !isSecondaryNavigationExists && scrollPosition > 3600;
  return (
    <Styles.Container onClick={scrollToTop} visible={isVisible}>
      <img src="/static/icons/semiArrowUpTinyWhite.svg" />
    </Styles.Container>
  );
};

export default ScrollUpButton;
