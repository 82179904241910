import { ConsultingPackagesCondition } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_CONSULTING_PACKAGES_CONDITIONS =
  'consultingPackagesConditions/RETRIEVE_CONSULTING_PACKAGES_CONDITIONS';
export const RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS =
  'consultingPackagesConditions/RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS';
export const RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE =
  'consultingPackagesConditions/RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE';

export interface RetrieveConsultingPackagesConditionsAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES_CONDITIONS;
  businessTypeId?: string;
  consultingPackageId?: string;
  marketId?: string;
  pageType?: string;
}

export interface RetrieveConsultingPackagesConditionsSuccessAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_SUCCESS;
  consultingPackagesCondition: ConsultingPackagesCondition[];
}

export interface RetrieveConsultingPackagesConditionsFailureAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES_CONDITIONS_FAILURE;
  error?: RequestResult<void>;
}
