import { createReducer } from 'redux-starter-kit';
import { RequestResult } from '../../../interfaces/requests';
import { CMSEvent } from './interfaces';
import {
  RetrieveEventsFailureAction,
  RetrieveEventsSuccessAction,
  RetrieveEventTeasersFailureAction,
  RetrieveEventTeasersSuccessAction,
  RetrieveMarketEventsFailureAction,
  RetrieveMarketEventsSuccessAction,
  RetrieveMenuEventsFailureAction,
  RetrieveMenuEventsSuccessAction,
  RetrieveMyPastEventsFailureAction,
  RetrieveMyPastEventsSuccessAction,
  RetrieveMyUpcomingEventsFailureAction,
  RetrieveMyUpcomingEventsSuccessAction,
  RetrieveRelevantUserEventsFailureAction,
  RetrieveRelevantUserEventsSuccessAction,
  RetrieveRelevantUserMarketEventsFailureAction,
  RetrieveRelevantUserMarketEventsSuccessAction,
  RETRIEVE_EVENTS_FAILURE,
  RETRIEVE_EVENTS_LOADING,
  RETRIEVE_EVENTS_SUCCESS,
  RETRIEVE_EVENT_TEASERS_FAILURE,
  RETRIEVE_EVENT_TEASERS_LOADING,
  RETRIEVE_EVENT_TEASERS_SUCCESS,
  RETRIEVE_MARKET_EVENTS_FAILURE,
  RETRIEVE_MARKET_EVENTS_LOADING,
  RETRIEVE_MARKET_EVENTS_SUCCESS,
  RETRIEVE_MENU_EVENTS,
  RETRIEVE_MENU_EVENTS_FAILURE,
  RETRIEVE_MENU_EVENTS_SUCCESS,
  RETRIEVE_MY_PAST_EVENTS_FAILURE,
  RETRIEVE_MY_PAST_EVENTS_LOADING,
  RETRIEVE_MY_PAST_EVENTS_SUCCESS,
  RETRIEVE_MY_UPCOMING_EVENTS_FAILURE,
  RETRIEVE_MY_UPCOMING_EVENTS_LOADING,
  RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS,
  RETRIEVE_RELEVANT_USER_EVENTS_FAILURE,
  RETRIEVE_RELEVANT_USER_EVENTS_LOADING,
  RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING,
  RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS,
  SelectEventFailureAction,
  SelectEventSuccessAction,
  SELECT_EVENT,
  SELECT_EVENT_FAILURE,
  SELECT_EVENT_SUCCESS
} from './types';

export interface IndustriesReducerState {
  marketEvents: CMSEvent[];
  marketEventsLoading: boolean;
  marketEventsError?: RequestResult;
  relevantUserMarketEvents: CMSEvent[];
  relevantUserMarketEventsLoading: boolean;
  relevantUserMarketEventsError?: RequestResult;
  events: CMSEvent[];
  eventsLoading: boolean;
  eventsError?: RequestResult;
  eventsTotal: number;
  relevantUserEvents: CMSEvent[];
  relevantUserEventsLoading: boolean;
  relevantUserEventsError?: RequestResult;
  relevantUserEventsTotal: number;
  eventTeasers: CMSEvent[];
  eventTeasersLoading: boolean;
  eventTeasersError?: RequestResult;
  eventTeasersTotal: number;
  myUpcomingEvents: CMSEvent[];
  myUpcomingEventsLoading: boolean;
  myUpcomingEventsError?: RequestResult;
  myUpcomingEventsTotal: number;
  myPastEvents: CMSEvent[];
  myPastEventsLoading: boolean;
  myPastEventsError?: RequestResult;
  myPastEventsTotal: number;
  event?: CMSEvent;
  eventLoading: boolean;
  eventError?: RequestResult;
  menuEvents: CMSEvent[];
  menuEventsLoading: boolean;
  menuEventsError?: RequestResult;
}

const initialState: IndustriesReducerState = {
  marketEvents: [],
  marketEventsLoading: false,
  relevantUserMarketEvents: [],
  relevantUserMarketEventsLoading: false,
  events: [],
  eventsLoading: false,
  eventsTotal: 0,
  relevantUserEvents: [],
  relevantUserEventsLoading: false,
  relevantUserEventsTotal: 0,
  eventTeasers: [],
  eventTeasersLoading: false,
  eventTeasersTotal: 0,
  eventLoading: false,
  myUpcomingEvents: [],
  myUpcomingEventsLoading: false,
  myUpcomingEventsTotal: 0,
  myPastEvents: [],
  myPastEventsLoading: false,
  myPastEventsTotal: 0,
  menuEvents: [],
  menuEventsLoading: false
};

export default createReducer<IndustriesReducerState>(initialState, {
  [RETRIEVE_MARKET_EVENTS_LOADING]: state => {
    state.marketEventsLoading = true;
  },
  [RETRIEVE_MARKET_EVENTS_SUCCESS]: (state, action: RetrieveMarketEventsSuccessAction) => {
    state.marketEventsLoading = false;
    state.marketEvents = action.events;
    state.marketEventsError = undefined;
  },
  [RETRIEVE_MARKET_EVENTS_FAILURE]: (state, action: RetrieveMarketEventsFailureAction) => {
    state.marketEventsLoading = false;
    state.marketEvents = [];
    if (action.error) {
      state.marketEventsError = action.error;
    }
  },
  [RETRIEVE_RELEVANT_USER_MARKET_EVENTS_LOADING]: state => {
    state.relevantUserMarketEventsLoading = true;
  },
  [RETRIEVE_RELEVANT_USER_MARKET_EVENTS_SUCCESS]: (state, action: RetrieveRelevantUserMarketEventsSuccessAction) => {
    state.relevantUserMarketEventsLoading = false;
    state.relevantUserMarketEvents = action.events;
    state.relevantUserMarketEventsError = undefined;
  },
  [RETRIEVE_RELEVANT_USER_MARKET_EVENTS_FAILURE]: (state, action: RetrieveRelevantUserMarketEventsFailureAction) => {
    state.relevantUserMarketEventsLoading = false;
    state.relevantUserMarketEvents = [];
    if (action.error) {
      state.relevantUserMarketEventsError = action.error;
    }
  },
  [RETRIEVE_EVENTS_LOADING]: state => {
    state.eventsLoading = true;
  },
  [RETRIEVE_EVENTS_SUCCESS]: (state, action: RetrieveEventsSuccessAction) => {
    state.eventsLoading = false;
    state.eventsTotal = action.total;
    state.events = action.events;
    state.eventsError = undefined;
  },
  [RETRIEVE_EVENTS_FAILURE]: (state, action: RetrieveEventsFailureAction) => {
    state.eventsLoading = false;
    state.eventsTotal = 0;
    state.events = [];
    if (action.error) {
      state.eventsError = action.error;
    }
  },
  [RETRIEVE_RELEVANT_USER_EVENTS_LOADING]: state => {
    state.relevantUserEventsLoading = true;
  },
  [RETRIEVE_RELEVANT_USER_EVENTS_SUCCESS]: (state, action: RetrieveRelevantUserEventsSuccessAction) => {
    state.relevantUserEventsLoading = false;
    state.relevantUserEventsTotal = action.total;
    state.relevantUserEvents = action.events;
    state.relevantUserEventsError = undefined;
  },
  [RETRIEVE_RELEVANT_USER_EVENTS_FAILURE]: (state, action: RetrieveRelevantUserEventsFailureAction) => {
    state.relevantUserEventsLoading = false;
    state.relevantUserEventsTotal = 0;
    state.relevantUserEvents = [];
    if (action.error) {
      state.relevantUserEventsError = action.error;
    }
  },
  [RETRIEVE_EVENT_TEASERS_LOADING]: state => {
    state.eventTeasersLoading = true;
  },
  [RETRIEVE_EVENT_TEASERS_SUCCESS]: (state, action: RetrieveEventTeasersSuccessAction) => {
    state.eventTeasersLoading = false;
    state.eventTeasersTotal = action.total;
    state.eventTeasers = action.events;
    state.eventTeasersError = undefined;
  },
  [RETRIEVE_EVENT_TEASERS_FAILURE]: (state, action: RetrieveEventTeasersFailureAction) => {
    state.eventTeasersLoading = false;
    state.eventTeasersTotal = 0;
    state.eventTeasers = [];
    if (action.error) {
      state.eventTeasersError = action.error;
    }
  },
  [RETRIEVE_MY_UPCOMING_EVENTS_LOADING]: state => {
    state.myUpcomingEventsLoading = true;
  },
  [RETRIEVE_MY_UPCOMING_EVENTS_SUCCESS]: (state, action: RetrieveMyUpcomingEventsSuccessAction) => {
    state.myUpcomingEventsLoading = false;
    state.myUpcomingEventsTotal = action.total;
    state.myUpcomingEvents = action.events;
    state.myUpcomingEventsError = undefined;
  },
  [RETRIEVE_MY_UPCOMING_EVENTS_FAILURE]: (state, action: RetrieveMyUpcomingEventsFailureAction) => {
    state.myUpcomingEventsLoading = false;
    state.myUpcomingEventsTotal = 0;
    state.myUpcomingEvents = [];
    if (action.error) {
      state.myUpcomingEventsError = action.error;
    }
  },
  [RETRIEVE_MY_PAST_EVENTS_LOADING]: state => {
    state.myPastEventsLoading = true;
  },
  [RETRIEVE_MY_PAST_EVENTS_SUCCESS]: (state, action: RetrieveMyPastEventsSuccessAction) => {
    state.myPastEventsLoading = false;
    state.myPastEventsTotal = action.total;
    state.myPastEvents = action.events;
    state.myPastEventsError = undefined;
  },
  [RETRIEVE_MY_PAST_EVENTS_FAILURE]: (state, action: RetrieveMyPastEventsFailureAction) => {
    state.myPastEventsLoading = false;
    state.myPastEventsTotal = 0;
    state.myPastEvents = [];
    if (action.error) {
      state.myPastEventsError = action.error;
    }
  },
  [SELECT_EVENT]: state => {
    state.eventLoading = true;
    state.eventError = initialState.eventError;
  },
  [SELECT_EVENT_SUCCESS]: (state, action: SelectEventSuccessAction) => {
    state.event = action.event;
    state.eventLoading = false;
  },
  [SELECT_EVENT_FAILURE]: (state, action: SelectEventFailureAction) => {
    state.eventError = action.error;
    state.eventLoading = false;
  },
  [RETRIEVE_MENU_EVENTS]: state => {
    state.menuEventsLoading = true;
    state.menuEventsError = undefined;
  },
  [RETRIEVE_MENU_EVENTS_SUCCESS]: (state, action: RetrieveMenuEventsSuccessAction) => {
    state.menuEventsLoading = false;
    state.menuEvents = action.events;
  },
  [RETRIEVE_MENU_EVENTS_FAILURE]: (state, action: RetrieveMenuEventsFailureAction) => {
    state.menuEventsLoading = false;
    state.myPastEvents = [];
    if (action.error) {
      state.menuEventsError = action.error;
    }
  }
});
