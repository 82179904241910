// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveSectors } from './operations';

// Types
import { AppState } from '../../reducers';
import { Sector } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import { doRetrieveSectorsSuccess, doRetrieveSectors, doRetrieveSectorsFailure } from './actions';
import { RetrieveSectorsAction, RETRIEVE_SECTORS } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveSectors({ language }: RetrieveSectorsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Sector[]>> = yield call(
        retrieveSectors,
        profile.accessToken,
        language || locale
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveSectorsSuccess(response.data.result));
      } else {
        yield put(doRetrieveSectorsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSectors()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSectors()));
    } else {
      logging.error(error);
      yield put(doRetrieveSectorsFailure());
    }
  }
}

export function* watcherRetrieveSectors() {
  yield takeLatest(RETRIEVE_SECTORS, workerRetrieveSectors);
}

export function* sectorSaga() {
  yield all([call(watcherRetrieveSectors)]);
}
