import axios from 'axios';
import getConfig from 'next/config';
import { WorkType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveWorkTypes(accessToken: string, locale: string) {
  return axios.get<RequestResult<WorkType[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/work-types`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
