import axios from 'axios';
import getConfig from 'next/config';
import { PostingNationality } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrievePostingNationalities(accessToken: string, locale: string) {
  return axios.get<RequestResult<PostingNationality[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/posting-nationalities`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
