// Modules
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import {
  addWatchlistEditor,
  removeWatchlistEditor,
  retrieveAvailableWatchlistEditors,
  retrieveWatchlistEditors
} from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import {
  doAddWatchlistEditor,
  doAddWatchlistEditorFailure,
  doAddWatchlistEditorSuccess,
  doRetrieveWatchlistEditors,
  doRetrieveWatchlistEditorsFailure,
  doRetrieveWatchlistEditorsSuccess,
  doRemoveWatchlistEditorFailure,
  doRemoveWatchlistEditor,
  doRemoveWatchlistEditorSuccess,
  doRetrieveAvailableWatchlistEditors,
  doRetrieveAvailableWatchlistEditorsFailure,
  doRetrieveAvailableWatchlistEditorsSuccess
} from './actions';
import {
  ADD_WATCHLIST_EDITOR,
  AddWatchlistEditorAction,
  REMOVE_WATCHLIST_EDITOR,
  RETRIEVE_AVAILABLE_WATCHLIST_EDITORS,
  RETRIEVE_WATCHLIST_EDITORS,
  RemoveWatchlistEditorAction,
  RetrieveAvailableWatchlistEditorsAction,
  RetrieveWatchlistEditorsAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveWatchlistEditors(action: RetrieveWatchlistEditorsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveWatchlistEditors, profile.accessToken, locale, action.watchlistId);
      if (response.data) {
        yield put(doRetrieveWatchlistEditorsSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistEditors(action.watchlistId)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistEditors(action.watchlistId)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistEditorsFailure(error));
    }
  }
}

function* workerRetrieveAvailableWatchlistEditors(action: RetrieveAvailableWatchlistEditorsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveAvailableWatchlistEditors, profile.accessToken, locale, action.watchlistId);
      if (response.data) {
        yield put(doRetrieveAvailableWatchlistEditorsSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRetrieveAvailableWatchlistEditors(action.watchlistId))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRetrieveAvailableWatchlistEditors(action.watchlistId))
      );
    } else {
      logging.error(error);
      yield put(doRetrieveAvailableWatchlistEditorsFailure(error));
    }
  }
}

function* workerAddWatchlistEditor(action: AddWatchlistEditorAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(addWatchlistEditor, profile.accessToken, locale, action.watchlistId, action.data);
      if (response.data) {
        yield put(doAddWatchlistEditorSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistEditor(action.watchlistId, action.data))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistEditor(action.watchlistId, action.data))
      );
    } else {
      logging.error(error);
      yield put(doAddWatchlistEditorFailure(error));
    }
  }
}

function* workerRemoveWatchlistEditor(action: RemoveWatchlistEditorAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(
        removeWatchlistEditor,
        profile.accessToken,
        locale,
        action.watchlistId,
        action.editorId
      );
      if (response.data) {
        yield put(doRemoveWatchlistEditorSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistEditor(action.watchlistId, action.editorId))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistEditor(action.watchlistId, action.editorId))
      );
    } else {
      logging.error(error);
      yield put(doRemoveWatchlistEditorFailure(error));
    }
  }
}

export function* watcherRetrieveWatchlistEditors() {
  yield takeLatest(RETRIEVE_WATCHLIST_EDITORS, workerRetrieveWatchlistEditors);
}

export function* watcherAvaialbleRetrieveWatchlistEditors() {
  yield takeLatest(RETRIEVE_AVAILABLE_WATCHLIST_EDITORS, workerRetrieveAvailableWatchlistEditors);
}

export function* watcherRemoveWatchlistEditor() {
  yield takeLatest(REMOVE_WATCHLIST_EDITOR, workerRemoveWatchlistEditor);
}

export function* watcherAddWatchlistEditor() {
  yield takeLatest(ADD_WATCHLIST_EDITOR, workerAddWatchlistEditor);
}

export function* watchlistEditorSaga() {
  yield all([
    call(watcherRetrieveWatchlistEditors),
    call(watcherAddWatchlistEditor),
    call(watcherRemoveWatchlistEditor),
    call(watcherAvaialbleRetrieveWatchlistEditors)
  ]);
}
