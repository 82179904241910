import axios from 'axios';
import getConfig from 'next/config';
import { BusinessOpportunityType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveBusinessOpportunityTypes(accessToken: string, locale: string) {
  return axios.get<RequestResult<BusinessOpportunityType[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/business-opportunity-types`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
