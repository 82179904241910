import { createReducer } from 'redux-starter-kit';
import { PostingNationality } from './interfaces';
import {
  RETRIEVE_POSTING_NATIONALITIES,
  RETRIEVE_POSTING_NATIONALITIES_SUCCESS,
  RETRIEVE_POSTING_NATIONALITIES_FAILURE,
  RetrievePostingNationalitiesSuccessAction,
  RetrievePostingNationalitiesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface PostingNationalitiesReducerState {
  postingNationalities?: PostingNationality[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: PostingNationalitiesReducerState = {
  postingNationalities: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<PostingNationalitiesReducerState>(initialState, {
  [RETRIEVE_POSTING_NATIONALITIES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_POSTING_NATIONALITIES_SUCCESS]: (state, action: RetrievePostingNationalitiesSuccessAction) => {
    state.retrieveLoading = false;
    state.postingNationalities = action.postingNationalities;
    state.error = null;
  },
  [RETRIEVE_POSTING_NATIONALITIES_FAILURE]: (state, action: RetrievePostingNationalitiesFailureAction) => {
    state.retrieveLoading = false;
    state.postingNationalities = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
