// Modules
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import { getSupsCompany, searchSups, suggestSupsSearch } from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import {
  doRetrieveSupsCompany,
  doRetrieveSupsCompanySuccess,
  doRetrieveSupsDetails,
  doRetrieveSupsDetailsSuccess,
  doSearchSups,
  doSearchSupsSuccess,
  doSuggestSupsSearch,
  doSuggestSupsSearchSuccess
} from './actions';
import {
  SearchSupsAction,
  SEARCH_SUPS,
  RetrieveSupsDetailsAction,
  RETRIEVE_SUPS_DETAILS,
  RetrieveSupsCompanyAction,
  RETRIEVE_SUPS_COMPANY,
  SuggestSupsSearchAction,
  SUGGEST_SUPS_SEARCH
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerSearchSups(action: SearchSupsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(searchSups, profile.accessToken, locale, action.url);
      if (response.data) {
        yield put(doSearchSupsSuccess(response.data, action.url));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSearchSups(action.url)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSearchSups(action.url)));
    } else {
      logging.error(error);
    }
  }
}

function* workerRetrieveSupsDetails(action: RetrieveSupsDetailsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(searchSups, profile.accessToken, locale, action.url);
      if (response.data) {
        yield put(doRetrieveSupsDetailsSuccess(response.data, action.url));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSupsDetails(action.url)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSupsDetails(action.url)));
    } else {
      logging.error(error);
    }
  }
}

function* workerRetrieveSupsCompany(action: RetrieveSupsCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(getSupsCompany, profile.accessToken, locale, action.url);
      if (response.data) {
        yield put(doRetrieveSupsCompanySuccess(response.data, action.url));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSupsCompany(action.url)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSupsCompany(action.url)));
    } else {
      logging.error(error);
    }
  }
}

function* workerSuggestSupsSearch(action: SuggestSupsSearchAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(suggestSupsSearch, profile.accessToken, locale, action.term);
      if (response.data) {
        yield put(doSuggestSupsSearchSuccess(response.data));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSuggestSupsSearch(action.term)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSuggestSupsSearch(action.term)));
    } else {
      logging.error(error);
    }
  }
}

export function* watcherSearchSups() {
  yield takeLatest(SEARCH_SUPS, workerSearchSups);
}

export function* watcherRetrieveSupsDetails() {
  yield takeLatest(RETRIEVE_SUPS_DETAILS, workerRetrieveSupsDetails);
}

export function* watcherRetrieveSupsCompany() {
  yield takeLatest(RETRIEVE_SUPS_COMPANY, workerRetrieveSupsCompany);
}

export function* watcherSuggestSupsSearch() {
  yield takeLatest(SUGGEST_SUPS_SEARCH, workerSuggestSupsSearch);
}

export function* supsSaga() {
  yield all([
    call(watcherSearchSups),
    call(watcherRetrieveSupsDetails),
    call(watcherRetrieveSupsCompany),
    call(watcherSuggestSupsSearch)
  ]);
}
