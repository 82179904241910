// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { saveCompany, retrieveCompany, searchCompany, certifyCRMCompany } from './operations';

// Types
import { AppState } from '../../reducers';
import { Company } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doSaveCompany,
  doSaveCompanyFailure,
  doSaveCompanySuccess,
  doRetrieveCompanySuccess,
  doRetrieveCompany,
  doRetrieveCompanyFailure,
  doSearchCompanySuccess,
  doSearchCompanyFailure,
  doSearchCompany,
  doCertifyCRMCompany,
  doCertifyCRMCompanySuccess,
  doCertifyCRMCompanyFailure
} from './actions';
import {
  RETRIEVE_COMPANY,
  CERTIFY_CRM_COMPANY,
  CertifyCRMCompanyAction,
  SAVE_COMPANY,
  SaveCompanyAction,
  SaveCompanyBody,
  SEARCH_COMPANY,
  SearchCompanyAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { parseError } from '../../../utils/validationUtils';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerSaveCompany({ body, onSuccess }: SaveCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<SaveCompanyBody>> = yield call(
        saveCompany,
        // The user properties are being read from the auth0 sub property on the backend
        body,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doSaveCompanySuccess(response.data.result));
        if (onSuccess) {
          onSuccess(response.data.result);
        }
      } else {
        yield put(doSaveCompanyFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSaveCompany(body, onSuccess)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSaveCompany(body, onSuccess)));
    } else {
      logging.error(error);
      yield put(doSaveCompanyFailure(parsedError));
    }
  }
}

function* workerRetrieveCompany() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Company>> = yield call(retrieveCompany, profile.accessToken, locale);

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveCompanySuccess(response.data.result));
      } else {
        yield put(doRetrieveCompanyFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCompany()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCompany()));
    } else {
      logging.error(error);
      yield put(doRetrieveCompanyFailure(parsedError));
    }
  }
}

function* workerCertifyCRMCompany({ duns, onFinish }: CertifyCRMCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Company>> = yield call(
        certifyCRMCompany,
        duns,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doCertifyCRMCompanySuccess(response.data.result));
        if (onFinish) {
          onFinish(response.data.result);
        }
      } else {
        yield put(doCertifyCRMCompanyFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCertifyCRMCompany(duns, onFinish)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCertifyCRMCompany(duns, onFinish)));
    } else if (parsedError.error === 'createFailed') {
      // Failed to create on CRM, send user to company form
      if (onFinish) {
        onFinish(null);
      }
    } else {
      logging.error(error);
      yield put(doCertifyCRMCompanyFailure(parsedError));
    }
  }
}

function* workerSearchCompany({ companyName }: SearchCompanyAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Partial<Company>[]>> = yield call(
        searchCompany,
        companyName,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doSearchCompanySuccess(response.data.result));
      } else {
        yield put(doSearchCompanyFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSearchCompany(companyName)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSearchCompany(companyName)));
    } else if (parsedError.status === 404) {
      yield put(doSearchCompanySuccess([]));
    } else {
      logging.error(error);
      yield put(doSearchCompanyFailure(parsedError));
    }
  }
}

export function* watcherSaveCompany() {
  yield takeLatest(SAVE_COMPANY, workerSaveCompany);
}

export function* watcherRetrieveCompany() {
  yield takeLatest(RETRIEVE_COMPANY, workerRetrieveCompany);
}

export function* watcherCertifyCRMCompany() {
  yield takeLatest(CERTIFY_CRM_COMPANY, workerCertifyCRMCompany);
}

export function* watcherSearchCompany() {
  yield takeLatest(SEARCH_COMPANY, workerSearchCompany);
}

export function* companySaga() {
  yield all([
    call(watcherSaveCompany),
    call(watcherRetrieveCompany),
    call(watcherSearchCompany),
    call(watcherCertifyCRMCompany)
  ]);
}
