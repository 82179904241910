import { createReducer } from 'redux-starter-kit';
import { Company } from './interfaces';
import {
  RETRIEVE_COMPANY,
  RETRIEVE_COMPANY_SUCCESS,
  RETRIEVE_COMPANY_FAILURE,
  RetrieveCompanySuccessAction,
  RetrieveCompanyFailureAction,
  CERTIFY_CRM_COMPANY,
  CERTIFY_CRM_COMPANY_SUCCESS,
  CERTIFY_CRM_COMPANY_FAILURE,
  CertifyCRMCompanySuccessAction,
  CertifyCRMCompanyFailureAction,
  SAVE_COMPANY,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAILURE,
  SaveCompanyFailureAction,
  SaveCompanySuccessAction,
  SEARCH_COMPANY,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
  SearchCompanySuccessAction,
  SearchCompanyFailureAction,
  SELECT_COMPANY,
  SelectCompanyAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface CompanyReducerState {
  company?: Company;
  companies: Partial<Company & { duns: string }>[];
  selectedCompany?: Partial<Company & { duns: string }> | null;
  retrieveLoading: boolean;
  saveLoading: boolean;
  searchLoading: boolean;
  error: RequestResult<void> | null;
  notFound: boolean;
}

const initialState: CompanyReducerState = {
  retrieveLoading: false,
  saveLoading: false,
  searchLoading: false,
  error: null,
  companies: [],
  notFound: false
};

export default createReducer<CompanyReducerState>(initialState, {
  [SAVE_COMPANY]: state => {
    state.saveLoading = true;
    state.error = null;
  },
  [SAVE_COMPANY_SUCCESS]: (state, action: SaveCompanySuccessAction) => {
    state.company = action.company;
    state.saveLoading = false;
    state.error = null;
  },
  [SAVE_COMPANY_FAILURE]: (state, action: SaveCompanyFailureAction) => {
    state.saveLoading = false;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_COMPANY]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_COMPANY_SUCCESS]: (state, action: RetrieveCompanySuccessAction) => {
    state.retrieveLoading = false;
    state.company = action.company;
    state.error = null;
  },
  [RETRIEVE_COMPANY_FAILURE]: (state, action: RetrieveCompanyFailureAction) => {
    state.retrieveLoading = false;
    state.company = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [CERTIFY_CRM_COMPANY]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [CERTIFY_CRM_COMPANY_SUCCESS]: (state, action: CertifyCRMCompanySuccessAction) => {
    state.retrieveLoading = false;
    if (action.company) {
      state.selectedCompany = action.company;
    }
    state.error = null;
  },
  [CERTIFY_CRM_COMPANY_FAILURE]: (state, action: CertifyCRMCompanyFailureAction) => {
    state.retrieveLoading = false;
    if (action.error) {
      state.error = action.error;
    }
  },
  [SEARCH_COMPANY]: state => {
    state.searchLoading = true;
    state.error = null;
    state.selectedCompany = undefined;
    state.companies = [];
  },
  [SEARCH_COMPANY_SUCCESS]: (state, action: SearchCompanySuccessAction) => {
    state.searchLoading = false;
    state.companies = action.companies;
    state.error = null;
    state.notFound = action.companies.length === 0;
  },
  [SEARCH_COMPANY_FAILURE]: (state, action: SearchCompanyFailureAction) => {
    state.searchLoading = false;
    state.companies = [];
    if (action.error) {
      state.error = action.error;
    }
  },
  [SELECT_COMPANY]: (state, action: SelectCompanyAction) => {
    state.retrieveLoading = false;
    state.selectedCompany = action.selectedCompany;
  }
});
