import { createReducer } from 'redux-starter-kit';
import { User } from './interfaces';
import {
  SIGNUP_OR_LOGIN_SUCCESS,
  SignupOrLoginSuccessAction,
  SIGNUP_OR_LOGIN_FAILURE,
  RETRIEVE_USER,
  RETRIEVE_USER_SUCCESS,
  RETRIEVE_USER_FAILURE,
  RetrieveUserSuccessAction,
  RetrieveUserFailureAction,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UpdateUserFailureAction,
  UpdateUserSuccessAction,
  SYNC_DB_USER,
  SYNC_DB_USER_SUCCESS,
  SYNC_DB_USER_FAILURE,
  SyncDBUserSuccessAction,
  SyncDBUserFailureAction,
  CLEAR_GENERAL_ERRORS,
  LOGIN_REDIRECT
} from './types';
import { SAVE_COMPANY_SUCCESS, SaveCompanySuccessAction } from '../company/types';
import { RequestResult } from '../../../interfaces/requests';
import { ONBOARD_USER_SUCCESS, OnboardUserSuccessAction } from '../onboarding/types';

export interface AuthReducerState {
  user?: User;
  loading: boolean;
  updateUserLoading: boolean;
  successMessageId?: string;
  error?: RequestResult<void> | null;
  syncing?: boolean;
  redirecting?: boolean;
}

const initialState: AuthReducerState = {
  loading: false,
  updateUserLoading: false,
  syncing: false,
  redirecting: false
};

export default createReducer<AuthReducerState>(initialState, {
  [SIGNUP_OR_LOGIN_SUCCESS]: (state, action: SignupOrLoginSuccessAction) => {
    state.user = action.user;
    state.loading = false;
    state.error = null;
  },
  [SIGNUP_OR_LOGIN_FAILURE]: state => {
    // Maybe add an error message?
    state.user = undefined;
  },
  [LOGIN_REDIRECT]: state => {
    state.redirecting = true;
  },
  [RETRIEVE_USER]: state => {
    state.loading = true;
    state.error = null;
    state.successMessageId = undefined;
  },
  [RETRIEVE_USER_SUCCESS]: (state, action: RetrieveUserSuccessAction) => {
    state.loading = false;
    state.user = action.user;
    state.error = null;
  },
  [RETRIEVE_USER_FAILURE]: (state, action: RetrieveUserFailureAction) => {
    state.loading = false;
    state.error = action.error;
    state.successMessageId = undefined;
  },
  [UPDATE_USER]: state => {
    state.updateUserLoading = true;
    state.error = null;
    state.successMessageId = undefined;
  },
  [UPDATE_USER_SUCCESS]: (state, action: UpdateUserSuccessAction) => {
    state.updateUserLoading = false;
    state.user = action.user;
    state.error = null;
    state.successMessageId = action.successMessageId;
  },
  [UPDATE_USER_FAILURE]: (state, action: UpdateUserFailureAction) => {
    state.updateUserLoading = false;
    state.error = action.error;
    state.successMessageId = undefined;
  },
  [SYNC_DB_USER]: state => {
    state.syncing = true;
    state.error = null;
  },
  [SYNC_DB_USER_SUCCESS]: (state, action: SyncDBUserSuccessAction) => {
    state.syncing = false;
    state.user = action.user;
    state.error = null;
  },
  [SYNC_DB_USER_FAILURE]: (state, action: SyncDBUserFailureAction) => {
    state.syncing = false;
    state.error = action.error;
  },
  [CLEAR_GENERAL_ERRORS]: state => {
    state.error = null;
    state.successMessageId = undefined;
  },
  [SAVE_COMPANY_SUCCESS]: (state, action: SaveCompanySuccessAction) => {
    state.user = action.user;
  },
  [ONBOARD_USER_SUCCESS]: (state, action: OnboardUserSuccessAction) => {
    state.user = action.user;
  }
});
