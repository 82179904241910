import axios from 'axios';
import getConfig from 'next/config';
import { Report, Question } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

/**
 *
 * @param accessToken
 * @param locale
 * @param uuid
 */
export function retrieveReport(accessToken: string, locale: string, uuid: string) {
  return axios.get<RequestResult<Report>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/reports/${uuid}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveReportUuid(accessToken: string, locale: string) {
  return axios.get<RequestResult<Report>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/reports/uuid`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

/**
 *
 * @param accessToken
 * @param locale
 * @param questions
 * @param marketId
 * @param HSCodeId
 */
export function sendReport(
  accessToken: string,
  locale: string,
  questions: Question[],
  marketId: number,
  HSCodeId: number,
  keepReport?: boolean
) {
  return axios.post<RequestResult<Report>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/reports`,
    {
      questions,
      marketId,
      HSCodeId
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { keepReport }
    }
  );
}

/**
 * Updates the users answer
 * @param accessToken JWT
 * @param locale language
 * @param question Question object
 */
export function updateReport(accessToken: string, locale: string, question: Question) {
  return axios.put<RequestResult<Report>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/reports`,
    {
      question: {
        id: question.id,
        optionId: question.optionId
      }
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

/**
 *
 * @param accessToken
 * @param locale
 * @param todoId
 * @param done
 */
export function toggleReportTodo(accessToken: string, locale: string, todoId: number, done: boolean) {
  return axios.put<RequestResult<any>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/todos/${todoId}`,
    {
      done
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
