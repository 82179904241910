import { createReducer } from 'redux-starter-kit';
import { Market, SortMarketsEnum } from './interfaces';
import {
  RETRIEVE_USER_MARKETS,
  RETRIEVE_USER_MARKETS_SUCCESS,
  RETRIEVE_USER_MARKETS_FAILURE,
  RetrieveUserMarketsFailureAction,
  RetrieveUserMarketsSuccessAction,
  SET_MARKET_SELECTED,
  SetMarketSelectedAction,
  UPDATE_USER_MARKET,
  UpdateUserMarketAction,
  SET_ALL_MARKETS_SORT,
  SetAllMarketsSortAction
} from './types';

export interface MarketsReducerState {
  loading: boolean;
  errorMessageId?: string;
  userMarkets?: Market[];
  sortAllMarketsBy: SortMarketsEnum;
}

const initialState: MarketsReducerState = {
  loading: false,
  sortAllMarketsBy: 'sortByOpportunityScore'
};

export default createReducer<MarketsReducerState>(initialState, {
  [RETRIEVE_USER_MARKETS]: state => {
    state.errorMessageId = initialState.errorMessageId;
    state.loading = true;
  },
  [RETRIEVE_USER_MARKETS_SUCCESS]: (state, action: RetrieveUserMarketsSuccessAction) => {
    state.errorMessageId = initialState.errorMessageId;
    state.loading = false;
    state.userMarkets = action.markets;
  },
  [RETRIEVE_USER_MARKETS_FAILURE]: (state, action: RetrieveUserMarketsFailureAction) => {
    state.errorMessageId = action.errorMessageId;
    state.loading = false;
  },
  [SET_MARKET_SELECTED]: (state, action: SetMarketSelectedAction) => {
    const setSelectedMarket = (marketList: Market[]) => {
      // Get the index of the market and then its value
      const marketIndex = marketList.findIndex(x => x.id === action.market.id);
      if (marketIndex > -1) {
        const market: Market = { ...marketList[marketIndex] };

        // Update if the market is selected or not
        market.userMarket = market.userMarket
          ? { ...market.userMarket, isUserSelection: action.isUserSelection }
          : {
              isUserSelection: action.isUserSelection
            };

        // Updates the array on this specific index
        marketList[marketIndex] = {
          ...market
        };
      } else {
        marketList = [
          ...marketList,
          {
            ...action.market,
            userMarket: action.market.userMarket
              ? { ...action.market.userMarket, isUserSelection: action.isUserSelection }
              : {
                  isUserSelection: action.isUserSelection
                }
          }
        ];
      }

      return marketList;
    };

    state.userMarkets = setSelectedMarket(state.userMarkets ? state.userMarkets : []);
  },
  [UPDATE_USER_MARKET]: (state, action: UpdateUserMarketAction) => {
    const updateMarket = (marketList: Market[]) => {
      // Get the index of the market and then its value
      const marketIndex = marketList.findIndex(x => x.id === action.market.id);

      if (marketIndex > -1) {
        // Updates the array on this specific index
        marketList[marketIndex] = action.market;
      } else {
        marketList = [...marketList, action.market];
      }

      return marketList;
    };

    state.userMarkets = updateMarket(state.userMarkets ? state.userMarkets : []);
  },
  [SET_ALL_MARKETS_SORT]: (state, action: SetAllMarketsSortAction) => {
    state.sortAllMarketsBy = action.sortAllMarketsBy;
  }
});
