import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import {
  doRetrieveMarketPublications,
  doRetrieveMarketPublicationsFailure,
  doRetrieveMarketPublicationsSuccess
} from './actions';
import { CMSPublication } from './interfaces';
import { retrieveMarketPublications } from './operations';
import { RetrieveMarketPublicationsAction, RETRIEVE_MARKET_PUBLICATIONS } from './types';

function* workerRetrieveMarketPublications({ slug }: RetrieveMarketPublicationsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSPublication[]>> = yield call(
        retrieveMarketPublications,
        profile.accessToken,
        locale,
        slug
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveMarketPublicationsSuccess(response.data.result));
      } else {
        yield put(doRetrieveMarketPublicationsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketPublications(slug)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketPublications(slug)));
    } else {
      logging.error(error);
      yield put(doRetrieveMarketPublicationsFailure());
    }
  }
}

export function* watcherRetrieveMarketPublications() {
  yield takeLatest(RETRIEVE_MARKET_PUBLICATIONS, workerRetrieveMarketPublications);
}

export function* publicationSaga() {
  yield all([call(watcherRetrieveMarketPublications)]);
}
