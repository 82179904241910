import { Task } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const SET_EXPORT_HSCODEMARKET = 'navigator/SET_EXPORT_HSCODEMARKET';
export const CLEAR_EXPORT_HSCODEMARKET = 'navigator/CLEAR_EXPORT_HSCODEMARKET';

export const RETRIEVE_TASKS = 'navigator/RETRIEVE_TASKS';
export const RETRIEVE_TASKS_SUCCESS = 'navigator/RETRIEVE_TASKS_SUCCESS';
export const RETRIEVE_TASKS_FAILURE = 'navigator/RETRIEVE_TASKS_FAILURE';

export const TOGGLE_TODO_DONE = 'navigator/TOGGLE_TODO_DONE';
export const SET_TODO_DONE = 'navigator/SET_TODO_DONE';

export interface SetExportHSCodeMarketsAction {
  type: typeof SET_EXPORT_HSCODEMARKET;
  market: any;
  hscode: any;
}

export interface ClearExportHSCodeMarketsAction {
  type: typeof CLEAR_EXPORT_HSCODEMARKET;
}

export interface RetrieveNavigatorTasksAction {
  type: typeof RETRIEVE_TASKS;
}

export interface RetrieveNavigatorTasksSuccessAction {
  type: typeof RETRIEVE_TASKS_SUCCESS;
  tasks: Task[];
}

export interface RetrieveNavigatorTasksFailureAction {
  type: typeof RETRIEVE_TASKS_FAILURE;
  error?: RequestResult<void>;
}

export interface ToggleTodoDoneAction {
  type: typeof TOGGLE_TODO_DONE;
  task: Task;
  checked: boolean;
}

export interface SetTodoDoneAction {
  type: typeof SET_TODO_DONE;
  task: Task;
  checked: boolean;
}
