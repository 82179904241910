// Modules
import { all, call, takeLatest, select, put } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';
import {
  RetrieveMarketConsultantAction,
  RETRIEVE_MARKET_CONSULTANT,
  RETRIEVE_USER_CONSULTANT_LIST,
  RetrieveUserConsultantListAction,
  RetrieveConsultantBySlugAction,
  RETRIEVE_CONSULTANT_BY_SLUG,
  RETRIEVE_GENERAL_CONSULTANT,
  SendConsultantEmailAction,
  SEND_CONSULTANT_EMAIL
} from './types';
import { AppState } from '../../reducers';
import { AuthenticatedProfile } from '../auth/interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { CMSConsultant } from './interfaces';
import {
  retrieveMarketConsultant,
  retrieveUserConsultantList,
  retrieveConsultantBySlug,
  sendConsultantEmail
} from './operations';
import {
  doRetrieveMarketConsultantSuccess,
  doRetrieveMarketConsultantFailure,
  doRetrieveMarketConsultant,
  doRetrieveUserConsultantList,
  doRetrieveUserConsultantListSuccess,
  doRetrieveUserConsultantListFailure,
  doRetrieveConsultantBySlug,
  doRetrieveConsultantBySlugSuccess,
  doRetrieveConsultantBySlugFailure,
  doRetrieveGeneralConsultantSuccess,
  doRetrieveGeneralConsultantFailure,
  doRetrieveGeneralConsultant,
  doSendConsultantEmailSuccess,
  doSendConsultantEmailFailure,
  doSendConsultantEmail
} from './actions';
import { parseError } from '../../../utils/validationUtils';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { doRefreshTokenAndRetry } from '../auth/actions';

function* workerRetrieveMarketConsultant({ marketSlug }: RetrieveMarketConsultantAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSConsultant[]>> = yield call(
        retrieveMarketConsultant,
        profile.accessToken,
        locale,
        marketSlug
      );

      if (response.data.succeeded && response.data && response.data.result && response.data.result.length > 0) {
        yield put(doRetrieveMarketConsultantSuccess(response.data.result[0]));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketConsultant(marketSlug)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketConsultant(marketSlug)));
    } else {
      logging.error(error);
      yield put(doRetrieveMarketConsultantFailure(error));
    }
  }
}

function* workerRetrieveUserConsultantList({}: RetrieveUserConsultantListAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSConsultant[]>> = yield call(
        retrieveUserConsultantList,
        profile.accessToken,
        locale
      );

      if (response.data.succeeded && response.data && response.data.result) {
        yield put(
          doRetrieveUserConsultantListSuccess(response.data.result.map(x => ({ ...x, categories: [{ name: 'S-GE' }] })))
        );
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUserConsultantList()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveUserConsultantList()));
    } else {
      logging.error(error);
      yield put(doRetrieveUserConsultantListFailure(error));
    }
  }
}

function* workerRetrieveConsultantBySlug({ consultantSlug }: RetrieveConsultantBySlugAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSConsultant>> = yield call(
        retrieveConsultantBySlug,
        profile.accessToken,
        locale,
        consultantSlug
      );

      if (response.data.succeeded && response.data && response.data.result) {
        yield put(doRetrieveConsultantBySlugSuccess(response.data.result));
      } else {
        yield put(doRetrieveConsultantBySlugFailure(response.request));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveConsultantBySlug(consultantSlug)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveConsultantBySlug(consultantSlug)));
    } else {
      logging.error(error);
      yield put(doRetrieveConsultantBySlugFailure(error));
    }
  }
}

const GENERAL_CONSULTANT_SLUG = 'alice-roy';

function* workerRetrieveGeneralConsultant() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSConsultant>> = yield call(
        retrieveConsultantBySlug,
        profile.accessToken,
        locale,
        GENERAL_CONSULTANT_SLUG
      );

      if (response.data.succeeded && response.data && response.data.result) {
        yield put(doRetrieveGeneralConsultantSuccess(response.data.result));
      } else {
        yield put(doRetrieveGeneralConsultantFailure(response.request));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveGeneralConsultant()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveGeneralConsultant()));
    } else {
      logging.error(error);
      yield put(doRetrieveGeneralConsultantFailure(error));
    }
  }
}

function* workerSendConsultantEmail({ data }: SendConsultantEmailAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const { data: result }: AxiosResponse<RequestResult<{ successed: boolean }>> = yield call(
        sendConsultantEmail,
        { ...data, page: window.location.href },
        locale,
        profile.accessToken
      );

      if (result.succeeded) {
        yield put(doSendConsultantEmailSuccess());
      } else {
        yield put(doSendConsultantEmailFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSendConsultantEmail(data)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSendConsultantEmail(data)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doSendConsultantEmailFailure(parsedError));
    }
  }
}

export function* watcherRetrieveMarketConsultant() {
  yield takeLatest(RETRIEVE_MARKET_CONSULTANT, workerRetrieveMarketConsultant);
}

export function* watcherRetrieveUserConsultantList() {
  yield takeLatest(RETRIEVE_USER_CONSULTANT_LIST, workerRetrieveUserConsultantList);
}

export function* watcherRetrieveConsultantBySlug() {
  yield takeLatest(RETRIEVE_CONSULTANT_BY_SLUG, workerRetrieveConsultantBySlug);
}

export function* watcherRetrieveGeneralConsultant() {
  yield takeLatest(RETRIEVE_GENERAL_CONSULTANT, workerRetrieveGeneralConsultant);
}

export function* watcherSendConsultantEmail() {
  yield takeLatest(SEND_CONSULTANT_EMAIL, workerSendConsultantEmail);
}

export function* consultantSaga() {
  yield all([
    call(watcherRetrieveMarketConsultant),
    call(watcherRetrieveUserConsultantList),
    call(watcherRetrieveConsultantBySlug),
    call(watcherRetrieveGeneralConsultant),
    call(watcherSendConsultantEmail)
  ]);
}
