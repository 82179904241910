import { createReducer } from 'redux-starter-kit';
import { WorkType } from './interfaces';
import {
  WORK_TYPES,
  WORK_TYPES_FAILURE,
  WORK_TYPES_SUCCESS,
  RetrieveWorkTypesSuccessAction,
  RetrieveWorkTypesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface WorkTypesReducerState {
  workTypes?: WorkType[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: WorkTypesReducerState = {
  workTypes: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<WorkTypesReducerState>(initialState, {
  [WORK_TYPES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [WORK_TYPES_SUCCESS]: (state, action: RetrieveWorkTypesSuccessAction) => {
    state.retrieveLoading = false;
    state.workTypes = action.workTypes;
    state.error = null;
  },
  [WORK_TYPES_FAILURE]: (state, action: RetrieveWorkTypesFailureAction) => {
    state.retrieveLoading = false;
    state.workTypes = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
