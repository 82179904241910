// Modules
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import { addWatchlistComment, removeWatchlistComment, retrieveWatchlistComments } from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import {
  doAddWatchlistComment,
  doAddWatchlistCommentFailure,
  doAddWatchlistCommentSuccess,
  doRetrieveWatchlistComments,
  doRetrieveWatchlistCommentsFailure,
  doRetrieveWatchlistCommentsSuccess,
  doRemoveWatchlistCommentFailure,
  doRemoveWatchlistComment,
  doRemoveWatchlistCommentSuccess
} from './actions';
import {
  ADD_WATCHLIST_COMMENT,
  AddWatchlistCommentAction,
  REMOVE_WATCHLIST_COMMENT,
  RETRIEVE_WATCHLIST_COMMENTS,
  RemoveWatchlistCommentAction,
  RetrieveWatchlistCommentsAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveWatchlistComments(action: RetrieveWatchlistCommentsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveWatchlistComments, profile.accessToken, locale, action.watchlistId);
      if (response.data) {
        yield put(doRetrieveWatchlistCommentsSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistComments(action.watchlistId)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistComments(action.watchlistId)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistCommentsFailure(error));
    }
  }
}

function* workerAddWatchlistComment(action: AddWatchlistCommentAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(addWatchlistComment, profile.accessToken, locale, action.watchlistId, action.data);
      if (response.data) {
        yield put(doAddWatchlistCommentSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistComment(action.watchlistId, action.data))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doAddWatchlistComment(action.watchlistId, action.data))
      );
    } else {
      logging.error(error);
      yield put(doAddWatchlistCommentFailure(error));
    }
  }
}

function* workerRemoveWatchlistComment(action: RemoveWatchlistCommentAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(
        removeWatchlistComment,
        profile.accessToken,
        locale,
        action.watchlistId,
        action.commentId
      );
      if (response.data) {
        yield put(doRemoveWatchlistCommentSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistComment(action.watchlistId, action.commentId))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doRemoveWatchlistComment(action.watchlistId, action.commentId))
      );
    } else {
      logging.error(error);
      yield put(doRemoveWatchlistCommentFailure(error));
    }
  }
}

export function* watcherRetrieveWatchlistComments() {
  yield takeLatest(RETRIEVE_WATCHLIST_COMMENTS, workerRetrieveWatchlistComments);
}

export function* watcherRemoveWatchlistComment() {
  yield takeLatest(REMOVE_WATCHLIST_COMMENT, workerRemoveWatchlistComment);
}

export function* watcherAddWatchlistComment() {
  yield takeLatest(ADD_WATCHLIST_COMMENT, workerAddWatchlistComment);
}

export function* watchlistCommentSaga() {
  yield all([
    call(watcherRetrieveWatchlistComments),
    call(watcherAddWatchlistComment),
    call(watcherRemoveWatchlistComment)
  ]);
}
