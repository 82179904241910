// Modules
import { all, call, takeLatest, put, select, takeEvery } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveReport, sendReport, retrieveReportUuid, toggleReportTodo, updateReport } from './operations';

// Types
import { AppState } from '../../reducers';
import { Report, ReportTodo } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrieveReport,
  doRetrieveReportSuccess,
  doRetrieveReportFailure,
  doSendReport,
  doSendReportSuccess,
  doSendReportFailure,
  doRetrieveReportUuid,
  doRetrieveReportUuidSuccess,
  doRetrieveReportUuidFailure,
  doRetrieveReportFull,
  doRetrieveReportFullSuccess,
  doRetrieveReportFullFailure,
  doSetReportTodoDone,
  doToggleReportTodoDone,
  doUpdateReport,
  doUpdateReportFailure,
  doUpdateReportSuccess
} from './actions';
import {
  RETRIEVE_REPORT,
  RETRIEVE_REPORT_FULL,
  UPDATE_REPORT,
  RETRIEVE_REPORT_UUID,
  TOGGLE_REPORT_TODO_DONE,
  SEND_REPORT,
  RetrieveNavigatorReportAction,
  RetrieveNavigatorReportFullAction,
  SendNavigatorReportAction,
  UpdateNavigatorReportAction,
  RetrieveNavigatorReportUuidAction,
  ToggleReportTodoDoneAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

/**
 *
 * @param param0
 */
function* workerRetrieveReport({ uuid }: RetrieveNavigatorReportAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<any>> = yield call(retrieveReport, profile.accessToken, locale, uuid);
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveReportSuccess(response.data.result));
      } else {
        yield put(doRetrieveReportFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReport(uuid)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReport(uuid)));
    } else {
      logging.error(error);
      yield put(doRetrieveReportFailure());
    }
  }
}

/**
 *
 */
export function* watcherRetrieveReport() {
  yield takeLatest(RETRIEVE_REPORT, workerRetrieveReport);
}

/**
 *
 * @param param0
 */
function* workerSendReport({ questions, marketId, HSCodeId, keepCurrentReport }: SendNavigatorReportAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Report>> = yield call(
        sendReport,
        profile.accessToken,
        locale,
        questions,
        marketId,
        HSCodeId,
        keepCurrentReport
      );
      if (response.data && response.data.succeeded && response.data.result && response.data.result.uuid) {
        yield put(doSendReportSuccess(response.data.result.uuid));
        yield put(doRetrieveReport(response.data.result.uuid));
      } else {
        yield put(doSendReportFailure());
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doSendReport(questions, marketId, HSCodeId, keepCurrentReport))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doSendReport(questions, marketId, HSCodeId, keepCurrentReport))
      );
    } else {
      logging.error(error);
      yield put(doSendReportFailure(error));
    }
  }
}

/**
 *
 */
export function* watcherSendReport() {
  yield takeLatest(SEND_REPORT, workerSendReport);
}

/**
 *
 * @param param0
 */
function* workerUpdateReport({ question }: UpdateNavigatorReportAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Report>> = yield call(
        updateReport,
        profile.accessToken,
        locale,
        question
      );
      if (response.data && response.data.succeeded && response.data.result && response.data.result.uuid) {
        yield put(doRetrieveReportFull());
        yield put(doUpdateReportSuccess());
      } else {
        yield put(doUpdateReportFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateReport(question)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateReport(question)));
    } else {
      logging.error(error);
      yield put(doUpdateReportFailure());
    }
  }
}

/**
 *
 */
export function* watcherUpdateReport() {
  yield takeLatest(UPDATE_REPORT, workerUpdateReport);
}

/**
 *
 * @param param0
 */
function* workerRetrieveReportUuid({}: RetrieveNavigatorReportUuidAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Report>> = yield call(
        retrieveReportUuid,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result && response.data.result.uuid) {
        yield put(doRetrieveReportUuidSuccess(response.data.result.uuid));
      } else {
        yield put(doRetrieveReportUuidFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReportUuid()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReportUuid()));
    } else {
      logging.error(error);
      yield put(doRetrieveReportUuidFailure());
    }
  }
}

/**
 *
 */
export function* watcherRetrieveReportUuid() {
  yield takeLatest(RETRIEVE_REPORT_UUID, workerRetrieveReportUuid);
}

/**
 *
 * @param param0
 */
function* workerRetrieveReportFull({}: RetrieveNavigatorReportFullAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<Report>> = yield call(
        retrieveReportUuid,
        profile.accessToken,
        locale
      );

      if (response.data && response.data.succeeded && response.data.result && response.data.result.uuid) {
        const responseReport: AxiosResponse<RequestResult<any>> = yield call(
          retrieveReport,
          profile.accessToken,
          locale,
          response.data.result.uuid
        );
        if (responseReport.data && responseReport.data.succeeded && responseReport.data.result) {
          yield put(doRetrieveReportFullSuccess(responseReport.data.result));
        } else {
          yield put(doRetrieveReportFullFailure());
        }
      } else {
        yield put(doRetrieveReportFullFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReportFull()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveReportFull()));
    } else {
      logging.error(error);
      yield put(doRetrieveReportFullFailure());
    }
  }
}

/**
 *
 */
export function* watcherRetrieveReportFull() {
  yield takeLatest(RETRIEVE_REPORT_FULL, workerRetrieveReportFull);
}

function* workerToggleReportTodoDone({ reportTask, checked, questionId }: ToggleReportTodoDoneAction) {
  // Get the task list to select the item from
  const report: AppState['reportReducer']['report'] | undefined = yield select(
    (state: AppState) => state.reportReducer.report
  );

  if (report) {
    const question = report.questions?.find(({ id }) => id === questionId);

    if (question) {
      let taskToToggle: ReportTodo | undefined;

      // If the task list has any items
      if (question && question.option.todos && question.option.todos.length > 0) {
        taskToToggle = question.option.todos.find((reportTodo: ReportTodo) => reportTodo.id === reportTask.id);
      }

      // If the task was found
      if (taskToToggle) {
        const previousSelectedState = taskToToggle.userTodo.done;
        try {
          // Optimistically update the item before sending or waiting the api
          yield put(doSetReportTodoDone(questionId, reportTask, !previousSelectedState));
          const locale = yield select((state: AppState) => state.appReducer.language);
          const profile: AuthenticatedProfile | undefined = yield select(
            (state: AppState) => state.authReducer.profile
          );
          // If the user is logged in
          if (profile) {
            const toggleTaskResult: AxiosResponse<RequestResult<any>> = yield call(
              toggleReportTodo,
              profile.accessToken,
              locale,
              reportTask.id,
              checked
            );
            if (!toggleTaskResult || !toggleTaskResult.data.succeeded) {
              // If some error occurred, rollback
              yield put(doSetReportTodoDone(questionId, reportTask, previousSelectedState));
            }
          } else {
            // If some error occurred, rollback
            yield put(doSetReportTodoDone(questionId, reportTask, previousSelectedState));
            // If the user not logged in
            yield call(
              workerRefreshTokenAndRetry,
              doRefreshTokenAndRetry(doToggleReportTodoDone(questionId, reportTask, checked))
            );
          }
        } catch (error) {
          // If some error occurred, rollback
          yield put(doSetReportTodoDone(questionId, reportTask, previousSelectedState));
          if (error && error.response && error.response.status && error.response.status === 401) {
            // If the token is expired
            yield call(
              workerRefreshTokenAndRetry,
              doRefreshTokenAndRetry(doToggleReportTodoDone(questionId, reportTask, checked))
            );
          } else {
            logging.error(error);
          }
        }
      }
    }
  }
}

export function* watcherToggleTodoDone() {
  // It SHOULD take every
  yield takeEvery(TOGGLE_REPORT_TODO_DONE, workerToggleReportTodoDone);
}

/**
 * All
 */
export function* reportSaga() {
  yield all([
    call(watcherRetrieveReport),
    call(watcherSendReport),
    call(watcherRetrieveReportUuid),
    call(watcherRetrieveReportFull),
    call(watcherToggleTodoDone),
    call(watcherUpdateReport)
  ]);
}
