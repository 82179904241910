import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { Industry } from '../industry/interfaces';
import { Market } from '../markets/interfaces';
import { SelectedProduct } from '../product/interfaces';
import { User } from '../user/interfaces';
import {
  doOnboardUser,
  doOnboardUserFailure,
  doOnboardUserSuccess,
  doRetrieveCRMContact,
  doRetrieveCRMContactFailure,
  doRetrieveCRMContactSuccess
} from './actions';
import { CRMContact } from './interfaces';
import { onboardUser, retrieveCRMContact } from './operations';
import { OnboardUserAction, ONBOARD_USER, RETRIEVE_CRM_CONTACT } from './types';

function* workerOnboardUser({ onFinish, onError }: OnboardUserAction) {
  const locale = yield select((state: AppState) => state.appReducer.language);
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      // Mounting body
      const user = yield select((state: AppState) => state.onboardingReducer.onboardingUser);
      const company = yield select((state: AppState) => state.onboardingReducer.onboardingCompany);
      const industries: Industry[] = yield select((state: AppState) => state.onboardingReducer.onboardingIndustries);
      const products: SelectedProduct[] = yield select((state: AppState) => state.productReducer.selected);
      let markets: Market['id'][] = [];

      const CRMContact: CRMContact = yield select((state: AppState) => state.onboardingReducer.CRMContact);
      if (CRMContact && CRMContact.markets && CRMContact.markets.length > 0) {
        markets = CRMContact.markets;
      }

      const body = {
        user: user,
        company: company,
        industries: (industries || []).map(ind => ind.id),
        products: (products || []).map(prod => prod.HSCode),
        markets
      };

      const result: AxiosResponse<RequestResult<User>> = yield call(onboardUser, profile.accessToken, locale, body);
      if (result.data && result.data.succeeded && result.data.result) {
        const onboardedUser = result.data.result;
        yield put(doOnboardUserSuccess(onboardedUser));
        if (onFinish) {
          onFinish(onboardedUser);
        }
      } else {
        yield put(doOnboardUserFailure());
        if (onFinish) {
          onFinish();
        }
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doOnboardUser(onFinish, onError)));
    }
  } catch (error) {
    console.error(error);
    if ([412, 422].includes(error?.response?.status)) {
      onError(error.response.status);
    } else if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doOnboardUser(onFinish, onError)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doOnboardUserFailure(parsedError));
    }
  }
}

function* workerRetrieveCRMContact() {
  const locale = yield select((state: AppState) => state.appReducer.language);
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      const retrieveUserResult: AxiosResponse<RequestResult<CRMContact>> = yield call(
        retrieveCRMContact,
        profile.accessToken,
        locale
      );
      if (retrieveUserResult.data && retrieveUserResult.data.succeeded && retrieveUserResult.data.result) {
        yield put(doRetrieveCRMContactSuccess(retrieveUserResult.data.result));
      } else {
        yield put(doRetrieveCRMContactFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveCRMContact()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 404) {
      yield put(doRetrieveCRMContactFailure());
    } else if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveCRMContact()));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveCRMContactFailure(parsedError));
    }
  }
}

export function* watcherRetrieveCRMContact() {
  yield takeLatest(RETRIEVE_CRM_CONTACT, workerRetrieveCRMContact);
}

export function* watcherOnboardUser() {
  yield takeLatest(ONBOARD_USER, workerOnboardUser);
}

export function* onboardingSaga() {
  yield all([call(watcherRetrieveCRMContact), call(watcherOnboardUser)]);
}
