import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { Market } from '../markets/interfaces';

const { publicRuntimeConfig } = getConfig();

export function getUserMarkets(accessToken: string, locale: string) {
  return axios.get<RequestResult<Market[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/markets`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
