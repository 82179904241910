// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { savePostingEmployeesReport, retrievePostingEmployeesReport } from './operations';

// Types
import { AppState } from '../../reducers';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doAddPostingEmployeesReport,
  doAddPostingEmployeesReportSuccess,
  doAddPostingEmployeesReportFailure,
  doRetrievePostingEmployeesReportFailure,
  doRetrievePostingEmployeesReport,
  doRetrievePostingEmployeesReportSuccess
} from './actions';
import {
  ADD_POSTING_EMPLOYEES_REPORT,
  SavePostingEmployeesReportAction,
  RETRIEVE_POSTING_EMPLOYEES_REPORT
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { PostingEmployeesReport } from './interfaces';

function* workerSavePostingEmployeesReport({
  postingReasonId,
  targetMarketId,
  postingNationalityId,
  postingEmployeesDurationId
}: SavePostingEmployeesReportAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<PostingEmployeesReport>> = yield call(
        savePostingEmployeesReport,
        profile.accessToken,
        locale,
        postingReasonId,
        targetMarketId,
        postingNationalityId,
        postingEmployeesDurationId
      );
      if (response.data && response.data.succeeded && response.data.result && response.data.result.uuid) {
        yield put(doAddPostingEmployeesReportSuccess(response.data.result));
      } else {
        yield put(doAddPostingEmployeesReportFailure());
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(
          doAddPostingEmployeesReport(postingReasonId, targetMarketId, postingNationalityId, postingEmployeesDurationId)
        )
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(
          doAddPostingEmployeesReport(postingReasonId, targetMarketId, postingNationalityId, postingEmployeesDurationId)
        )
      );
    } else {
      logging.error(error);
      yield put(doAddPostingEmployeesReportFailure(error));
    }
  }
}

export function* watcherSavePostingEmployeesReport() {
  yield takeLatest(ADD_POSTING_EMPLOYEES_REPORT, workerSavePostingEmployeesReport);
}

function* workerRetrievePostingEmployeesReport() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<PostingEmployeesReport>> = yield call(
        retrievePostingEmployeesReport,
        profile.accessToken,
        locale
      );
      if (response.data && response.data.succeeded && response.data.result != null) {
        yield put(doRetrievePostingEmployeesReportSuccess(response.data.result));
      } else {
        yield put(doRetrievePostingEmployeesReportFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingEmployeesReport()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrievePostingEmployeesReport()));
    } else {
      logging.error(error);
      yield put(doRetrievePostingEmployeesReportFailure());
    }
  }
}

export function* watcherRetrievePostingEmployeesReport() {
  yield takeLatest(RETRIEVE_POSTING_EMPLOYEES_REPORT, workerRetrievePostingEmployeesReport);
}

export function* postingEmployeesReportSaga() {
  yield all([call(watcherRetrievePostingEmployeesReport), call(watcherSavePostingEmployeesReport)]);
}
