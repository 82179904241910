import { SupsRecord, SupsResult, SupsSuggestResult } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const SEARCH_SUPS = 'sups/SEARCH_SUPS';
export const SEARCH_SUPS_SUCCESS = 'sups/SEARCH_SUPS_SUCCESS';
export const SEARCH_SUPS_FAILURE = 'sups/SEARCH_SUPS_FAILURE';

export const RETRIEVE_SUPS_DETAILS = 'sups/RETRIEVE_SUPS_DETAILS';
export const RETRIEVE_SUPS_DETAILS_SUCCESS = 'sups/RETRIEVE_SUPS_DETAILS_SUCCESS';
export const RETRIEVE_SUPS_DETAILS_FAILURE = 'sups/RETRIEVE_SUPS_DETAILS_FAILURE';

export const RETRIEVE_SUPS_COMPANY = 'sups/RETRIEVE_SUPS_COMPANY';
export const RETRIEVE_SUPS_COMPANY_SUCCESS = 'sups/RETRIEVE_SUPS_COMPANY_SUCCESS';
export const RETRIEVE_SUPS_COMPANY_FAILURE = 'sups/RETRIEVE_SUPS_COMPANY_FAILURE';

export const SUGGEST_SUPS_SEARCH = 'sups/SUGGEST_SUPS_SEARCH';
export const SUGGEST_SUPS_SEARCH_SUCCESS = 'sups/SUGGEST_SUPS_SEARCH_SUCCESS';
export const SUGGEST_SUPS_SEARCH_FAILURE = 'sups/SUGGEST_SUPS_SEARCH_FAILURE';

export interface SearchSupsAction {
  type: typeof SEARCH_SUPS;
  url: string;
}

export interface SearchSupsSuccessAction {
  type: typeof SEARCH_SUPS_SUCCESS;
  sups: SupsResult;
  currentUrl: string;
}

export interface SearchSupsFailureAction {
  type: typeof SEARCH_SUPS_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveSupsCompanyAction {
  type: typeof RETRIEVE_SUPS_COMPANY;
  url: string;
}

export interface RetrieveSupsCompanySuccessAction {
  type: typeof RETRIEVE_SUPS_COMPANY_SUCCESS;
  record: SupsRecord;
  currentUrl: string;
}

export interface RetrieveSupsCompanyFailureAction {
  type: typeof RETRIEVE_SUPS_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveSupsDetailsAction {
  type: typeof RETRIEVE_SUPS_DETAILS;
  url: string;
}

export interface RetrieveSupsDetailsSuccessAction {
  type: typeof RETRIEVE_SUPS_DETAILS_SUCCESS;
  sups: SupsResult;
  currentUrl: string;
}

export interface RetrieveSupsDetailsFailureAction {
  type: typeof RETRIEVE_SUPS_DETAILS_FAILURE;
  error?: RequestResult<void>;
}

export interface SuggestSupsSearchAction {
  type: typeof SUGGEST_SUPS_SEARCH;
  term: string;
}

export interface SuggestSupsSearchSuccessAction {
  type: typeof SUGGEST_SUPS_SEARCH_SUCCESS;
  result: SupsSuggestResult;
}

export interface SuggestSupsSearchFailureAction {
  type: typeof SUGGEST_SUPS_SEARCH_FAILURE;
  error?: RequestResult<void>;
}
