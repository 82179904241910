import axios from 'axios';
import getConfig from 'next/config';
import { ConsultingPackagesCondition } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveConsultingPackagesConditions(
  accessToken: string,
  locale: string,
  businessTypeId?: string,
  consultingPackageId?: string,
  marketId?: string,
  pageType?: string
) {
  return axios.get<RequestResult<ConsultingPackagesCondition[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/consulting-packages-conditions`,
    {
      params: {
        businessTypeId,
        consultingPackageId,
        marketId,
        pageType
      },
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
