import { MarketGuide } from './interfaces';
import {
  RetrieveMarketGuideAction,
  RetrieveMarketGuidePublicAction,
  RetrieveMarketGuidePublicFailureAction,
  RetrieveMarketGuideSuccessAction,
  RetrieveMarketProductPotentialAction,
  RetrieveMarketProductPotentialFailureAction,
  RetrieveMarketProductPotentialSuccessAction,
  RetrieveMarketSwissExportAction,
  RetrieveMarketSwissExportFailureAction,
  RetrieveMarketSwissExportSuccessAction,
  RetrieveMarketSwissTraitsAction,
  RetrieveMarketSwissTraitsFailureAction,
  RetrieveMarketSwissTraitsSuccessAction,
  RetrieveMarketTopExportersAction,
  RetrieveMarketTopExportersFailureAction,
  RetrieveMarketTopExportersSuccessAction,
  RETRIEVE_MARKET_GUIDE,
  RETRIEVE_MARKET_GUIDE_PUBLIC,
  RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE,
  RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS,
  RETRIEVE_MARKET_GUIDE_SUCCESS,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS,
  RETRIEVE_MARKET_SWISS_EXPORT,
  RETRIEVE_MARKET_SWISS_EXPORT_FAILURE,
  RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS,
  RETRIEVE_MARKET_SWISS_TRAITS,
  RETRIEVE_MARKET_SWISS_TRAITS_FAILURE,
  RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS,
  RETRIEVE_MARKET_TOP_EXPORTERS,
  RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE,
  RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS,
  SubmitConsultantContactAction,
  SubmitConsultantContactFailureAction,
  SubmitConsultantContactSuccessAction,
  SUBMIT_CONSULTANT_CONTACT,
  SUBMIT_CONSULTANT_CONTACT_FAILURE,
  SUBMIT_CONSULTANT_CONTACT_SUCCESS
} from './types';

export const doRetrieveMarketGuide = (slug: string): RetrieveMarketGuideAction => ({
  type: RETRIEVE_MARKET_GUIDE,
  slug
});

export const doRetrieveMarketGuideSuccess = (marketGuide: MarketGuide): RetrieveMarketGuideSuccessAction => ({
  type: RETRIEVE_MARKET_GUIDE_SUCCESS,
  marketGuide
});

export const doRetrieveMarketGuidePublic = (slug: string): RetrieveMarketGuidePublicAction => ({
  type: RETRIEVE_MARKET_GUIDE_PUBLIC,
  slug
});

export const doRetrieveMarketGuidePublicSuccess = (marketGuide: MarketGuide): RetrieveMarketGuideSuccessAction => ({
  type: RETRIEVE_MARKET_GUIDE_PUBLIC_SUCCESS,
  marketGuide
});

export const doRetrieveMarketGuidePublicFailure = (
  error?: RetrieveMarketGuidePublicFailureAction['error']
): RetrieveMarketGuidePublicFailureAction => ({
  type: RETRIEVE_MARKET_GUIDE_PUBLIC_FAILURE,
  error
});

export const doRetrieveTopExporters = (slug: string): RetrieveMarketTopExportersAction => ({
  type: RETRIEVE_MARKET_TOP_EXPORTERS,
  slug
});

export const doRetrieveTopExportersSuccess = (
  topExporters: RetrieveMarketTopExportersSuccessAction['topExporters']
): RetrieveMarketTopExportersSuccessAction => ({
  type: RETRIEVE_MARKET_TOP_EXPORTERS_SUCCESS,
  topExporters
});

export const doRetrieveTopExportersFailure = (
  error?: RetrieveMarketTopExportersFailureAction['error']
): RetrieveMarketTopExportersFailureAction => ({
  type: RETRIEVE_MARKET_TOP_EXPORTERS_FAILURE,
  error
});

export const doRetrieveSwissExport = (slug: string): RetrieveMarketSwissExportAction => ({
  type: RETRIEVE_MARKET_SWISS_EXPORT,
  slug
});

export const doRetrieveSwissExportSuccess = (
  swissExport: RetrieveMarketSwissExportSuccessAction['swissExport']
): RetrieveMarketSwissExportSuccessAction => ({
  type: RETRIEVE_MARKET_SWISS_EXPORT_SUCCESS,
  swissExport
});

export const doRetrieveSwissExportFailure = (
  error?: RetrieveMarketSwissExportFailureAction['error']
): RetrieveMarketSwissExportFailureAction => ({
  type: RETRIEVE_MARKET_SWISS_EXPORT_FAILURE,
  error
});

export const doRetrieveSwissTraits = (): RetrieveMarketSwissTraitsAction => ({
  type: RETRIEVE_MARKET_SWISS_TRAITS
});

export const doRetrieveSwissTraitsSuccess = (
  traits: RetrieveMarketSwissTraitsSuccessAction['traits']
): RetrieveMarketSwissTraitsSuccessAction => ({
  type: RETRIEVE_MARKET_SWISS_TRAITS_SUCCESS,
  traits
});

export const doRetrieveSwissTraitsFailure = (
  error?: RetrieveMarketSwissTraitsFailureAction['error']
): RetrieveMarketSwissTraitsFailureAction => ({
  type: RETRIEVE_MARKET_SWISS_TRAITS_FAILURE,
  error
});
export const doRetrieveMarketProductPotential = (slug: string): RetrieveMarketProductPotentialAction => ({
  type: RETRIEVE_MARKET_PRODUCT_POTENTIAL,
  slug
});

export const doRetrieveMarketProductPotentialSuccess = (
  marketProductPotential: RetrieveMarketProductPotentialSuccessAction['marketProductPotential']
) => ({
  type: RETRIEVE_MARKET_PRODUCT_POTENTIAL_SUCCESS,
  marketProductPotential
});

export const doRetrieveMarketProductPotentialFailure = (
  error?: RetrieveMarketProductPotentialFailureAction['error']
) => ({
  type: RETRIEVE_MARKET_PRODUCT_POTENTIAL_FAILURE,
  error
});

export const doSubmitConsultantContactAction = (
  contactForm: SubmitConsultantContactAction['contactForm']
): SubmitConsultantContactAction => ({
  type: SUBMIT_CONSULTANT_CONTACT,
  contactForm
});

export const doSubmitConsultantContactActionSuccess = (): SubmitConsultantContactSuccessAction => ({
  type: SUBMIT_CONSULTANT_CONTACT_SUCCESS
});

export const doSubmitConsultantContactActionFailure = (
  error?: SubmitConsultantContactFailureAction['error']
): SubmitConsultantContactFailureAction => ({
  type: SUBMIT_CONSULTANT_CONTACT_FAILURE,
  error
});
