import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { CMSArticle } from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveArticles(
  limit: number,
  offset: number,
  accessToken: string,
  locale: string,
  market?: string,
  industry?: string
) {
  return axios.get<RequestResult<CMSArticle[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/cms/articles/user`, {
    params: { limit, offset, market, industry },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function selectArticle(locale: string, drupalNodeId: string | number) {
  return axios.get<RequestResult<CMSArticle>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/cms/articles/${drupalNodeId}`
  );
}
