import {
  ADD_POSTING_EMPLOYEES_REPORT,
  ADD_POSTING_EMPLOYEES_REPORT_SUCCESS,
  ADD_POSTING_EMPLOYEES_REPORT_FAILURE,
  RETRIEVE_POSTING_EMPLOYEES_REPORT,
  RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE,
  RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS,
  SavePostingEmployeesReportAction,
  SavePostingEmployeesReportSuccessAction,
  SavePostingEmployeesReportFailureAction,
  RetrievePostingEmployeesReportAction,
  RetrievePostingEmployeesReportFailureAction,
  RetrievePostingEmployeesReportSuccessAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { PostingEmployeesReport } from './interfaces';

export const doAddPostingEmployeesReport = (
  postingReasonId?: string,
  targetMarketId?: string,
  postingNationalityId?: string,
  postingEmployeesDurationId?: string
): SavePostingEmployeesReportAction => ({
  type: ADD_POSTING_EMPLOYEES_REPORT,
  postingReasonId,
  targetMarketId,
  postingNationalityId,
  postingEmployeesDurationId
});

export const doAddPostingEmployeesReportSuccess = (
  postingEmployeesReport: PostingEmployeesReport
): SavePostingEmployeesReportSuccessAction => ({
  type: ADD_POSTING_EMPLOYEES_REPORT_SUCCESS,
  postingEmployeesReport
});

export const doAddPostingEmployeesReportFailure = (
  error?: RequestResult<void>
): SavePostingEmployeesReportFailureAction => ({
  type: ADD_POSTING_EMPLOYEES_REPORT_FAILURE,
  error
});

export const doRetrievePostingEmployeesReport = (): RetrievePostingEmployeesReportAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_REPORT
});

export const doRetrievePostingEmployeesReportFailure = (
  error?: RequestResult<void>
): RetrievePostingEmployeesReportFailureAction => ({
  error,
  type: RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE
});

export const doRetrievePostingEmployeesReportSuccess = (
  postingEmployeesReport: PostingEmployeesReport
): RetrievePostingEmployeesReportSuccessAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS,
  postingEmployeesReport
});
