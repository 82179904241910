import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction, combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist';
import actionsReducer from './modules/actions/reducer';
import appReducer from './modules/app/reducer';
import articleReducer from './modules/article/reducer';
import authReducer from './modules/auth/reducer';
import { AUTH_USER, LOGOUT_USER } from './modules/auth/types';
import consultingPackagesConditionsReducer from './modules/consultingPackagesCondition/reducer';
import cachedMarketListReducer from './modules/cachedMarketList/reducer';
import companyReducer from './modules/company/reducer';
import consultantReducer from './modules/consultant/reducer';
import dashboardReducer from './modules/dashboard/reducer';
import eventReducer from './modules/event/reducer';
import exportNavigatorReducer from './modules/exportNavigator/reducer';
import industryReducer from './modules/industry/reducer';
import sectorReducer from './modules/sector/reducer';
import businessTypeReducer from './modules/businessType/reducer';
import marketGuideReducer from './modules/marketGuide/reducer';
import marketsReducer from './modules/markets/reducer';
import onboardingReducer from './modules/onboarding/reducer';
import productReducer from './modules/product/reducer';
import publicationReducer from './modules/publication/reducer';
import questionsReducer from './modules/questions/reducer';
import reportReducer from './modules/report/reducer';
import tariffTaxesReducer from './modules/tariffTaxes/reducer';
import userReducer from './modules/user/reducer';
import oecReducer from './modules/oec/reducer';
import postingReasonsReducer from './modules/postingReason/reducer';
import postingEmployeesDurationsReducer from './modules/postingEmployeesDuration/reducer';
import postingNationalitiesReducer from './modules/postingNationality/reducer';
import postingEmployeesReportsReducer from './modules/postingEmployeesReport/reducer';

import businessOpportunityTypeReducer from './modules/businessOpportunityType/reducer';
import workTypeReducer from './modules/workType/reducer';
import bOSearchCriteriaReducer from './modules/bOSearchCriteria/reducer';
import businessOpportunityMMBrainReducer from './modules/businessOpportunityMMBrain/reducer';
import businessOpportunityDetailsMMBrainReducer from './modules/businessOpportunityDetailsMMBrain/reducer';
import cPVCodeReducer from './modules/cPVCode/reducer';
import countryReducer from './modules/country/reducer';
import supsReducer from './modules/sups/reducer';
import watchlistReducer from './modules/watchlists/reducer';
import watchlistCompanyReducer from './modules/watchlistCompanies/reducer';
import watchlistEditorReducer from './modules/watchlistEditors/reducer';
import watchlistCommentReducer from './modules/watchlistComments/reducer';

import postingEmployeesContentsReducer from './modules/postingEmployeesContent/reducer';
import intercomReducer from './modules/intercom/reducer';

const combinedReducers = combineReducers({
  appReducer,
  authReducer,
  userReducer,
  actionsReducer,
  consultingPackagesConditionsReducer,
  marketsReducer,
  postingReasonsReducer,
  postingNationalitiesReducer,
  postingEmployeesDurationsReducer,
  postingEmployeesContentsReducer,
  postingEmployeesReportsReducer,
  companyReducer,
  industryReducer,
  sectorReducer,
  productReducer,
  eventReducer,
  articleReducer,
  dashboardReducer,
  marketGuideReducer,
  onboardingReducer,
  consultantReducer,
  publicationReducer,
  cachedMarketListReducer,
  tariffTaxesReducer,
  exportNavigatorReducer,
  questionsReducer,
  reportReducer,
  oecReducer,
  businessTypeReducer,
  workTypeReducer,
  bOSearchCriteriaReducer,
  businessOpportunityTypeReducer,
  cPVCodeReducer,
  countryReducer,
  businessOpportunityDetailsMMBrainReducer,
  businessOpportunityMMBrainReducer,
  intercomReducer,
  supsReducer,
  watchlistReducer,
  watchlistCompanyReducer,
  watchlistEditorReducer,
  watchlistCommentReducer
});

export type AppState = ReturnType<typeof combinedReducers>;

const reducers = (state: any, { type, payload = {}, ...rest }: AnyAction) => {
  switch (type) {
    case HYDRATE:
      state = {
        ...state,
        ...payload,
        consultantReducer: {
          ...payload.consultantReducer,
          modalConsultant: state.consultantReducer.modalConsultant,
          consultantEmailData: state.consultantReducer.consultantEmailData,
          userConsultantListLoading: state.consultantReducer.userConsultantListLoading,
          marketConsultantLoading: state.consultantReducer.marketConsultantLoading
        },
        marketsReducer: {
          ...payload.marketsReducer,
          loading: state.marketsReducer.loading
        },
        marketGuideReducer: {
          ...payload.marketGuideReducer,
          loading: state.marketGuideReducer.loading,
          topExportersLoading: state.marketGuideReducer.topExportersLoading,
          swissTraitsLoading: state.marketGuideReducer.swissTraitsLoading,
          marketProductProtentialLoading: state.marketGuideReducer.marketProductProtentialLoading
        },
        productReducer: {
          ...payload.productReducer,
          loading: state.productReducer.loading
        },
        eventReducer: {
          ...payload.eventReducer,
          relevantUserEventsLoading: state.eventReducer.relevantUserEventsLoading,
          eventsLoading: state.eventReducer.eventsLoading,
          myUpcomingEventsLoading: state.eventReducer.myUpcomingEventsLoading,
          relevantUserMarketEventsLoading: state.eventReducer.relevantUserMarketEventsLoading,
          marketEventsLoading: state.eventReducer.marketEventsLoading
        },
        dashboardReducer: {
          ...payload.dashboardReducer,
          globalImportLoading: state.dashboardReducer.globalImportLoading,
          largestImportersLoading: state.dashboardReducer.largestImportersLoading
        },
        articleReducer: {
          ...payload.articleReducer,
          loading: state.articleReducer.loading
        },
        countryReducer: {
          ...payload.countryReducer,
          retrieveLoading: state.countryReducer.retrieveLoading
        },
        publicationReducer: {
          ...payload.publicationReducer,
          marketPublicationsLoading: state.publicationReducer.marketPublicationsLoading
        },
        supsReducer: {
          ...state.supsReducer
        },
        watchlistReducer: {
          ...state.watchlistReducer
        },
        watchlistCompanyReducer: {
          ...state.watchlistCompanyReducer
        },
        watchlistEditorReducer: {
          ...state.watchlistEditorReducer
        },
        watchlistCommentReducer: {
          ...state.watchlistCommentReducer
        }
      };
      break;

    case LOGOUT_USER:
    case AUTH_USER:
      // When logging out or logging in, "clear" the redux state
      state = { appReducer: state.appReducer };
      break;

    case REHYDRATE:
      // It is possible that the HYDRATE has already loaded the report via SSR and the
      // PERSIST action had run before that, therefore if there is a report on the payload
      // use this because it is the local one which the user is already "working" with, otherwise
      // use the one from the state if there is one
      const reportReducer = !!payload.reportReducer?.report
        ? payload.reportReducer
        : state.reportReducer.report
        ? state.reportReducer
        : payload.reportReducer;

      state = {
        ...state,
        ...payload,
        reportReducer,
        onboardingReducer: {
          ...state.onboardingReducer,
          ...payload.onboardingReducer,
          loading: false
        },
        appReducer: {
          ...state.appReducer,
          ...payload.appReducer,
          // If we're rehydrating, keep the language from the state IF there's any,
          // if there isn't, use the payload.
          language: state.appReducer?.language || payload.appReducer?.language,
          translations: state.appReducer.translations || payload.appReducer?.translations
        }
      };

      // We don't want to persist redirecting state at all.
      state.userReducer.redirecting = false;
      break;
  }

  // When rehydrating it adds an undesired state prop _persist which we therefore remove
  delete state?._persist;

  return combinedReducers(state, { type, payload, ...rest });
};

export default reducers;
