import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { doRetrieveGlobalImportVolume, doRetrieveLargestImporters } from '../dashboard/actions';
import { doRetrieveArticles } from './../article/actions';
import { doRetrieveUserConsultantList } from './../consultant/actions';
import { doRetrieveRelevantUserEvents } from './../event/actions';
import { doRetrieveUserMarkets } from './../markets/actions';
import { doRetrieveUser } from './../user/actions';
import {
  doRetrieveProducts,
  doRetrieveProductsFailure,
  doRetrieveProductsFull,
  doRetrieveProductsFullFailure,
  doRetrieveProductsFullSuccess,
  doRetrieveProductsSuccess,
  doSaveProducts,
  doSaveProductsFailure,
  doSaveProductsSuccess,
  doSearchProducts,
  doSearchProductsFailure,
  doSearchProductsSuccess
} from './actions';
import { Product, SelectedProduct, SelectedProductFull } from './interfaces';
import { getProducts, getProductsFull, hsSearch, saveProducts } from './operations';
import { RETRIEVE_PRODUCTS, RETRIEVE_PRODUCTS_FULL, SaveProductsAction, SAVE_PRODUCTS, SEARCH_PRODUCTS } from './types';

function* workerProductsSearch() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const keyword = yield select((state: AppState) => state.productReducer.searchTerms);

    const response: AxiosResponse<Product[] | void> = yield call(hsSearch, keyword, locale);

    if (response.data && response.data) {
      yield put(doSearchProductsSuccess(response.data));
    } else {
      yield put(doSearchProductsFailure());
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSearchProducts()));
    } else {
      logging.error(error);
      yield put(doSearchProductsFailure(parsedError));
    }
  }
}

function* workerSaveProducts({ onFinish }: SaveProductsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const selectedProducts: SelectedProduct[] = yield select((state: AppState) => state.productReducer.selected);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const HSCodes = selectedProducts.map(product => product.HSCode);
      const response: AxiosResponse<RequestResult<Product>> = yield call(
        saveProducts,
        HSCodes,
        locale,
        profile.accessToken
      );

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doSaveProductsSuccess());
        // Update all data
        yield put(doRetrieveProducts());
        yield put(doRetrieveProductsFull());
        yield put(doRetrieveUser());
        yield put(doRetrieveUserMarkets());
        yield put(doRetrieveUserConsultantList());
        yield put(doRetrieveRelevantUserEvents());
        yield put(doRetrieveArticles());
        yield put(doRetrieveGlobalImportVolume());
        yield put(doRetrieveLargestImporters());

        if (onFinish && typeof onFinish === 'function') {
          onFinish();
        }
      } else {
        yield put(doSaveProductsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSaveProducts(onFinish)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSaveProducts(onFinish)));
    } else {
      logging.error(error);
      yield put(doSaveProductsFailure(parsedError));
    }
  }
}

function* workerRetrieveProducts() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<SelectedProduct[]>> = yield call(
        getProducts,
        locale,
        profile.accessToken
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveProductsSuccess(response.data.result));
      } else {
        yield put(doRetrieveProductsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveProducts()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveProducts()));
    } else {
      logging.error(error);
      yield put(doRetrieveProductsFailure(parsedError));
    }
  }
}

function* workerRetrieveProductsFull() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<SelectedProductFull[]>> = yield call(
        getProductsFull,
        locale,
        profile.accessToken
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveProductsFullSuccess(response.data.result));
      } else {
        yield put(doRetrieveProductsFullFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveProductsFull()));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveProductsFull()));
    } else {
      logging.error(error);
      yield put(doRetrieveProductsFullFailure(parsedError));
    }
  }
}

export function* watcherProductsSearch() {
  yield takeLatest(SEARCH_PRODUCTS, workerProductsSearch);
}
export function* watcherSaveProducts() {
  yield takeLatest(SAVE_PRODUCTS, workerSaveProducts);
}
export function* watcherRetrieveProducts() {
  yield takeLatest(RETRIEVE_PRODUCTS, workerRetrieveProducts);
}
export function* watcherRetrieveProductsFull() {
  yield takeLatest(RETRIEVE_PRODUCTS_FULL, workerRetrieveProductsFull);
}

export function* productSaga() {
  yield all([
    call(watcherProductsSearch),
    call(watcherSaveProducts),
    call(watcherRetrieveProducts),
    call(watcherRetrieveProductsFull)
  ]);
}
