import axios from 'axios';
import getConfig from 'next/config';
import { Task } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

/**
 * @TODO : Refactor with actual http requests
 */
export function retrieveTasks(accessToken: string, locale: string) {
  return axios.get<RequestResult<Task[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/todos`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function toggleTaskTodo(accessToken: string, locale: string, todoId: number, done: boolean) {
  return axios.put<RequestResult<Task>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/todos/${todoId}`,
    {
      done
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
