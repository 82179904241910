import { createReducer } from 'redux-starter-kit';
import {
  BusinessOpportunitiesResultsAggregationsMMBrain,
  BusinessOpportunityMMBrain,
  BusinessOpportunityModificationResponseData,
  BusinessOpportunityOperation,
  ManualBusinessOpportunityMMBrain
} from './interfaces';
import {
  RETRIEVE_BUSINESS_OPPORTUNITIES,
  RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE,
  CREATE_BUSINESS_OPPORTUNITY,
  CREATE_BUSINESS_OPPORTUNITY_SUCCESS,
  CREATE_BUSINESS_OPPORTUNITY_FAILURE,
  UPDATE_BUSINESS_OPPORTUNITY,
  UPDATE_BUSINESS_OPPORTUNITY_SUCCESS,
  UPDATE_BUSINESS_OPPORTUNITY_FAILURE,
  DELETE_BUSINESS_OPPORTUNITY,
  DELETE_BUSINESS_OPPORTUNITY_SUCCESS,
  DELETE_BUSINESS_OPPORTUNITY_FAILURE,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE,
  CreateBusinessOpportunitySuccessAction,
  UpdateBusinessOpportunitySuccessAction,
  RetrieveBusinessOpportunitiesSuccessAction,
  RetrieveManualBusinessOpportunitiesSuccessAction,
  RetrieveManualBusinessOpportunitiesFailureAction,
  RetrieveBusinessOpportunitiesStatsSuccessAction,
  RetrieveBusinessOpportunitiesFailureAction,
  UpdateBusinessOpportunityAction,
  DO_CLEAR_LAST_OPERATION_INFORMATION,
  UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS,
  UpdateBusinessOpportunityNewProgressAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface BusinessOpportunitiesReducerState {
  businessOpportunitiess: BusinessOpportunityMMBrain[];
  businessOpportunitiesAggregations: BusinessOpportunitiesResultsAggregationsMMBrain | null;
  loading: boolean;
  error?: RequestResult;
  businessOpportunitiesLoading: boolean;
  businessOpportunitiesError?: RequestResult;
  businessOpportunitiesStatsLoading: boolean;
  lastModificationOperation?: BusinessOpportunityOperation | null;
  currentModificationOperation?: BusinessOpportunityOperation | null;
  modificationOperationFailed?: boolean;
  total: number;
  normalizedProgress?: number;
  notFound: boolean;
  manualBusinessOpportunities?: ManualBusinessOpportunityMMBrain[];
  businessOpportunityModificationResponseData: BusinessOpportunityModificationResponseData | null;
  modifyBusinessOpportunityLoading: boolean;
  manualBusinessOpportunitiesTotal: number;
  manualBusinessOpportunitiesLoading: boolean;
  manualBusinessOpportunitiesError?: RequestResult;
}

const initialState: BusinessOpportunitiesReducerState = {
  businessOpportunitiess: [],
  businessOpportunitiesAggregations: null,
  loading: false,
  businessOpportunitiesLoading: false,
  businessOpportunitiesStatsLoading: false,
  total: -1,
  notFound: false,
  lastModificationOperation: undefined,
  currentModificationOperation: undefined,
  modificationOperationFailed: undefined,
  businessOpportunityModificationResponseData: null,
  modifyBusinessOpportunityLoading: false,
  manualBusinessOpportunities: undefined,
  manualBusinessOpportunitiesTotal: -1,
  manualBusinessOpportunitiesLoading: false,
  normalizedProgress: undefined
};

export default createReducer<BusinessOpportunitiesReducerState>(initialState, {
  [RETRIEVE_BUSINESS_OPPORTUNITIES]: state => {
    state.loading = true;
    state.error = undefined;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE]: (state, action: RetrieveBusinessOpportunitiesFailureAction) => {
    state.loading = false;
    if (action.error) {
      state.error = action.error;
    }
  },
  [RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS]: (state, action: RetrieveBusinessOpportunitiesSuccessAction) => {
    state.loading = false;
    state.businessOpportunitiess = action.opportunities;
    state.total = action.total;
    state.error = undefined;
    state.businessOpportunitiesAggregations = action.aggregations;
  },
  [RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES]: state => {
    state.manualBusinessOpportunitiesLoading = true;
    state.manualBusinessOpportunitiesError = undefined;
  },
  [RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE]: (
    state,
    action: RetrieveManualBusinessOpportunitiesFailureAction
  ) => {
    state.manualBusinessOpportunitiesLoading = false;
    if (action.error) {
      state.manualBusinessOpportunitiesError = action.error;
    }
  },
  [RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS]: (
    state,
    action: RetrieveManualBusinessOpportunitiesSuccessAction
  ) => {
    state.manualBusinessOpportunitiesLoading = false;
    state.manualBusinessOpportunities = action.opportunities;
    state.manualBusinessOpportunitiesTotal = action.total;
    state.manualBusinessOpportunitiesError = undefined;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITIES_STATS]: state => {
    state.businessOpportunitiesLoading = true;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE]: state => {
    state.businessOpportunitiesLoading = false;
  },
  [RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS]: (state, action: RetrieveBusinessOpportunitiesStatsSuccessAction) => {
    state.businessOpportunitiesLoading = false;
    state.businessOpportunitiesAggregations = action.aggregations;
  },
  [CREATE_BUSINESS_OPPORTUNITY]: state => {
    state.modifyBusinessOpportunityLoading = true;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = 'create';
    state.modificationOperationFailed = false;
  },
  [CREATE_BUSINESS_OPPORTUNITY_FAILURE]: state => {
    state.modifyBusinessOpportunityLoading = false;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = true;
  },
  [CREATE_BUSINESS_OPPORTUNITY_SUCCESS]: (state, action: CreateBusinessOpportunitySuccessAction) => {
    state.modifyBusinessOpportunityLoading = false;
    state.businessOpportunityModificationResponseData = action.businessOpportunityModificationResponseData;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = false;
  },
  [UPDATE_BUSINESS_OPPORTUNITY]: (state, action: UpdateBusinessOpportunityAction) => {
    state.modifyBusinessOpportunityLoading = true;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = action.operation;
    state.modificationOperationFailed = false;
  },
  [UPDATE_BUSINESS_OPPORTUNITY_FAILURE]: state => {
    state.modifyBusinessOpportunityLoading = false;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = true;
  },
  [UPDATE_BUSINESS_OPPORTUNITY_SUCCESS]: (state, action: UpdateBusinessOpportunitySuccessAction) => {
    state.modifyBusinessOpportunityLoading = false;
    state.businessOpportunityModificationResponseData = action.businessOpportunityModificationResponseData;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = false;
  },
  [UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS]: (state, action: UpdateBusinessOpportunityNewProgressAction) => {
    state.normalizedProgress = action.normalizedProgress;
  },
  [DELETE_BUSINESS_OPPORTUNITY]: state => {
    state.modifyBusinessOpportunityLoading = true;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = 'delete';
    state.modificationOperationFailed = false;
  },
  [DELETE_BUSINESS_OPPORTUNITY_FAILURE]: state => {
    state.modifyBusinessOpportunityLoading = false;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = true;
  },
  [DELETE_BUSINESS_OPPORTUNITY_SUCCESS]: state => {
    state.modifyBusinessOpportunityLoading = false;
    state.lastModificationOperation = state.currentModificationOperation;
    state.currentModificationOperation = null;
    state.modificationOperationFailed = false;
  },
  [DO_CLEAR_LAST_OPERATION_INFORMATION]: state => {
    state.lastModificationOperation = null;
    state.currentModificationOperation = null;
  }
});
