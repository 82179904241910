import {
  RETRIEVE_SECTORS,
  RETRIEVE_SECTORS_FAILURE,
  RETRIEVE_SECTORS_SUCCESS,
  RetrieveSectorsAction,
  RetrieveSectorsFailureAction,
  RetrieveSectorsSuccessAction
} from './types';
import { Sector } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveSectors = (language?: string): RetrieveSectorsAction => ({
  type: RETRIEVE_SECTORS,
  language: language
});

export const doRetrieveSectorsFailure = (error?: RequestResult<void>): RetrieveSectorsFailureAction => ({
  error,
  type: RETRIEVE_SECTORS_FAILURE
});

export const doRetrieveSectorsSuccess = (sectors: Sector[]): RetrieveSectorsSuccessAction => ({
  type: RETRIEVE_SECTORS_SUCCESS,
  sectors
});
