import { createReducer } from 'redux-starter-kit';
import { PostingEmployeesContent } from './interfaces';
import {
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS,
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS,
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE,
  RetrievePostingEmployeesContentsSuccessAction,
  RetrievePostingEmployeesContentsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface PostingEmployeesContentsReducerState {
  postingEmployeesContents?: PostingEmployeesContent[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: PostingEmployeesContentsReducerState = {
  postingEmployeesContents: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<PostingEmployeesContentsReducerState>(initialState, {
  [RETRIEVE_POSTING_EMPLOYEES_CONTENTS]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS]: (state, action: RetrievePostingEmployeesContentsSuccessAction) => {
    state.retrieveLoading = false;
    state.postingEmployeesContents = action.postingEmployeesContent;
    state.error = null;
  },
  [RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE]: (state, action: RetrievePostingEmployeesContentsFailureAction) => {
    state.retrieveLoading = false;
    state.postingEmployeesContents = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
