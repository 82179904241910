import { Company } from './interfaces';
import { User } from '../user/interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const SAVE_COMPANY = 'company/SAVE_COMPANY';
export const SAVE_COMPANY_SUCCESS = 'company/SAVE_COMPANY_SUCCESS';
export const SAVE_COMPANY_FAILURE = 'company/SAVE_COMPANY_FAILURE';
export const RETRIEVE_COMPANY = 'company/RETRIEVE_COMPANY';
export const RETRIEVE_COMPANY_SUCCESS = 'company/RETRIEVE_COMPANY_SUCCESS';
export const RETRIEVE_COMPANY_FAILURE = 'company/RETRIEVE_COMPANY_FAILURE';
export const CERTIFY_CRM_COMPANY = 'company/CERTIFY_CRM_COMPANY';
export const CERTIFY_CRM_COMPANY_SUCCESS = 'company/CERTIFY_CRM_COMPANY_SUCCESS';
export const CERTIFY_CRM_COMPANY_FAILURE = 'company/CERTIFY_CRM_COMPANY_FAILURE';
export const SEARCH_COMPANY = 'company/SEARCH_COMPANY';
export const SEARCH_COMPANY_SUCCESS = 'company/SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_FAILURE = 'company/SEARCH_COMPANY_FAILURE';
export const SELECT_COMPANY = 'company/SELECT_COMPANY';

export type SaveCompanyBody = {
  company: Partial<Company>;
  user: Partial<User>;
  industries: string[];
};

export interface SaveCompanyAction {
  type: typeof SAVE_COMPANY;
  body: SaveCompanyBody;
  onSuccess?: (data: SaveCompanyBody) => void;
}

export interface SaveCompanySuccessAction {
  type: typeof SAVE_COMPANY_SUCCESS;
  company: SaveCompanyBody['company'];
  user: SaveCompanyBody['user'];
  industries: SaveCompanyBody['industries'];
}

export interface SaveCompanyFailureAction {
  type: typeof SAVE_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveCompanyAction {
  type: typeof RETRIEVE_COMPANY;
}

export interface RetrieveCompanySuccessAction {
  type: typeof RETRIEVE_COMPANY_SUCCESS;
  company: Company;
}

export interface RetrieveCompanyFailureAction {
  type: typeof RETRIEVE_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface CertifyCRMCompanyAction {
  type: typeof CERTIFY_CRM_COMPANY;
  duns: string;
  onFinish?: (data: Partial<Company> | null) => void;
}

export interface CertifyCRMCompanySuccessAction {
  type: typeof CERTIFY_CRM_COMPANY_SUCCESS;
  company: Partial<Company>;
}

export interface CertifyCRMCompanyFailureAction {
  type: typeof CERTIFY_CRM_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface SearchCompanyAction {
  type: typeof SEARCH_COMPANY;
  companyName: string;
}

export interface SearchCompanySuccessAction {
  type: typeof SEARCH_COMPANY_SUCCESS;
  companies: Partial<Company & { duns: string }>[];
}

export interface SearchCompanyFailureAction {
  type: typeof SEARCH_COMPANY_FAILURE;
  error?: RequestResult<void>;
}

export interface SelectCompanyAction {
  type: typeof SELECT_COMPANY;
  selectedCompany: Partial<Company & { duns: string }> | null;
}
