import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { Market } from './interfaces';

const { publicRuntimeConfig } = getConfig();

export function getUserMarkets(accessToken: string, locale: string) {
  return axios.get<RequestResult<Market[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/markets`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function getMarkets(accessToken: string, locale: string) {
  return axios.get<RequestResult<Market[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/markets`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function selectMarket(marketId: string | number, accessToken: string, locale: string) {
  return axios.post<RequestResult<Market>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/users/markets/${marketId}`,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

export function deselectMarket(marketId: string | number, accessToken: string, locale: string) {
  return axios.delete<RequestResult<Market>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/markets/${marketId}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
