import { createReducer } from 'redux-starter-kit';
import { OPEN_INTERCOM_CHAT, CLOSE_INTERCOM_CHAT } from './types';

export interface IntercomReducerState {
  isChatOpen: boolean;
}

const initialState: IntercomReducerState = {
  isChatOpen: false
};

export default createReducer<IntercomReducerState>(initialState, {
  [OPEN_INTERCOM_CHAT]: state => {
    state.isChatOpen = true;
  },
  [CLOSE_INTERCOM_CHAT]: state => {
    state.isChatOpen = false;
  }
});
