import { createReducer } from 'redux-starter-kit';
import { BusinessType } from './interfaces';
import {
  RETRIEVE_BUSINESS_TYPES,
  RETRIEVE_BUSINESS_TYPES_SUCCESS,
  RETRIEVE_BUSINESS_TYPES_FAILURE,
  RetrieveBusinessTypesSuccessAction,
  RetrieveBusinessTypesFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { SAVE_COMPANY_SUCCESS } from '../company/types';

export interface BusinessTypesReducerState {
  businessTypes?: BusinessType[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: BusinessTypesReducerState = {
  businessTypes: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<BusinessTypesReducerState>(initialState, {
  [RETRIEVE_BUSINESS_TYPES]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_TYPES_SUCCESS]: (state, action: RetrieveBusinessTypesSuccessAction) => {
    state.retrieveLoading = false;
    state.businessTypes = action.businessTypes;
    state.error = null;
  },
  [RETRIEVE_BUSINESS_TYPES_FAILURE]: (state, action: RetrieveBusinessTypesFailureAction) => {
    state.retrieveLoading = false;
    state.businessTypes = undefined;
    if (action.error) {
      state.error = action.error;
    }
  },
  [SAVE_COMPANY_SUCCESS]: () => {
    //
  }
});
