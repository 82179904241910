import { Country, GlobalImportVolume } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_GLOBAL_IMPORT_VOLUME = 'dashboard/RETRIEVE_GLOBAL_IMPORT_VOLUME';
export const RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS = 'dashboard/RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS';
export const RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE = 'dashboard/RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE';
export const RETRIEVE_LARGEST_IMPORTERS = 'dashboard/RETRIEVE_LARGEST_IMPORTERS';
export const RETRIEVE_LARGEST_IMPORTERS_SUCCESS = 'dashboard/RETRIEVE_LARGEST_IMPORTERS_SUCCESS';
export const RETRIEVE_LARGEST_IMPORTERS_FAILURE = 'dashboard/RETRIEVE_LARGEST_IMPORTERS_FAILURE';

export interface RetrieveGlobalImportVolumeAction {
  type: typeof RETRIEVE_GLOBAL_IMPORT_VOLUME;
}

export interface RetrieveGlobalImportVolumeSuccessAction {
  type: typeof RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS;
  globalImportVolume: GlobalImportVolume;
}

export interface RetrieveGlobalImportVolumeFailureAction {
  type: typeof RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveLargestImportersAction {
  type: typeof RETRIEVE_LARGEST_IMPORTERS;
}

export interface RetrieveLargestImportersSuccessAction {
  type: typeof RETRIEVE_LARGEST_IMPORTERS_SUCCESS;
  largestImporters: Country[];
}

export interface RetrieveLargestImportersFailureAction {
  type: typeof RETRIEVE_LARGEST_IMPORTERS_FAILURE;
  error?: RequestResult<void>;
}
