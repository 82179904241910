import XLSX from 'sheetjs-style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToExcel = (excelData: object[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(excelData);
  const excelBuffer = XLSX.write(
    {
      Sheets: { data: ws },
      SheetNames: ['data']
    },
    { bookType: 'xlsx', type: 'array' }
  );
  const data = new Blob([excelBuffer], { type: fileType });

  const a = document.createElement('a');
  const url = URL.createObjectURL(data);
  a.href = url;
  a.download = `${fileName}${fileExtension}`;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};
