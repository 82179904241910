// Modules
import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { parseError } from '../../../utils/validationUtils';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';
import { Country } from '../dashboard/interfaces';
import { SelectedProduct } from '../product/interfaces';
import {
  doRetrieveMarketGuidePublicSuccess,
  doRetrieveMarketGuideSuccess,
  doRetrieveMarketProductPotential,
  doRetrieveMarketProductPotentialFailure,
  doRetrieveMarketProductPotentialSuccess,
  doRetrieveSwissExport,
  doRetrieveSwissExportFailure,
  doRetrieveSwissExportSuccess,
  doRetrieveSwissTraits,
  doRetrieveSwissTraitsFailure,
  doRetrieveSwissTraitsSuccess,
  doRetrieveTopExporters,
  doRetrieveTopExportersFailure,
  doRetrieveTopExportersSuccess,
  doSubmitConsultantContactAction,
  doSubmitConsultantContactActionFailure,
  doSubmitConsultantContactActionSuccess
} from './actions';
import { ConsultantContact, MarketGuide, MarketProductPotential, MarketTrait, SwissExport } from './interfaces';
import {
  retrieveMarketGuide,
  retrieveMarketGuidePublic,
  retrieveMarketProductPotential,
  retrieveSwissExport,
  retrieveSwissStats,
  retrieveTopExporters,
  submitConsultantContact
} from './operations';
import {
  RetrieveMarketGuideAction,
  RetrieveMarketGuidePublicAction,
  RetrieveMarketProductPotentialAction,
  RetrieveMarketSwissExportAction,
  RetrieveMarketSwissTraitsAction,
  RetrieveMarketTopExportersAction,
  RETRIEVE_MARKET_GUIDE,
  RETRIEVE_MARKET_GUIDE_PUBLIC,
  RETRIEVE_MARKET_PRODUCT_POTENTIAL,
  RETRIEVE_MARKET_SWISS_EXPORT,
  RETRIEVE_MARKET_SWISS_TRAITS,
  RETRIEVE_MARKET_TOP_EXPORTERS,
  SubmitConsultantContactAction,
  SUBMIT_CONSULTANT_CONTACT
} from './types';

function* workerRetrieveMarketGuide({ slug }: RetrieveMarketGuideAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<MarketGuide>> = yield call(
        retrieveMarketGuide,
        profile.accessToken,
        locale,
        slug
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveMarketGuideSuccess(response.data.result));
      }
    }
  } catch (error) {
    logging.error(error);
  }
}

function* workerRetrieveMarketGuidePublic({ slug }: RetrieveMarketGuidePublicAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<MarketGuide>> = yield call(retrieveMarketGuidePublic, locale, slug);
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveMarketGuidePublicSuccess(response.data.result));
      }
    }
  } catch (error) {
    logging.error(error);
  }
}

function* workerRetrieveMarketSwissExport({ slug }: RetrieveMarketSwissExportAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: any | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const market = yield select((state: AppState) => state.marketGuideReducer.marketGuide);

      const selectMarketResult: AxiosResponse<SwissExport> = yield call(
        retrieveSwissExport,
        profile.accessToken,
        locale,
        market.ISOCode3Letter
      );

      if (selectMarketResult.data) {
        yield put(doRetrieveSwissExportSuccess(selectMarketResult.data));
      } else {
        yield put(doRetrieveSwissExportFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSwissExport(slug)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSwissExport(slug)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveSwissExportFailure(parsedError));
    }
  }
}

function* workerRetrieveMarketTopExporters({ slug }: RetrieveMarketTopExportersAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const market = yield select((state: AppState) => state.marketGuideReducer.marketGuide);
      const selectMarketResult: AxiosResponse<Country[]> = yield call(
        retrieveTopExporters,
        profile.accessToken,
        locale,
        market.ISOCode3Letter
      );
      if (selectMarketResult.data) {
        yield put(doRetrieveTopExportersSuccess(selectMarketResult.data));
      } else {
        yield put(doRetrieveTopExportersFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveTopExporters(slug)));
    }
  } catch (error) {
    console.error(error);
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveTopExporters(slug)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveTopExportersFailure(parsedError));
    }
  }
}

function* workerRetrieveMarketSwissTraits({}: RetrieveMarketSwissTraitsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<MarketTrait[]> = yield call(retrieveSwissStats, profile.accessToken, locale);
      if (response.data) {
        yield put(doRetrieveSwissTraitsSuccess(response.data));
      } else {
        yield put(doRetrieveSwissTraitsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSwissTraits()));
    }
  } catch (error) {
    console.error(error);
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSwissTraits()));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveSwissTraitsFailure(parsedError));
    }
  }
}

function* workerRetrieveMarketProductPotential({ slug }: RetrieveMarketProductPotentialAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const market = yield select((state: AppState) => state.marketGuideReducer.marketGuide);
      const products: SelectedProduct[] = yield select((state: AppState) => state.productReducer.products);

      const marketProductPotential: AxiosResponse<MarketProductPotential[]> = yield call(
        retrieveMarketProductPotential,
        locale,
        market.ISOCode3Letter,
        products.map(item => item.HSCode)
      );

      if (marketProductPotential.data) {
        yield put(doRetrieveMarketProductPotentialSuccess(marketProductPotential.data));
      } else {
        yield put(doRetrieveMarketProductPotentialFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketProductPotential(slug)));
    }
  } catch (error) {
    console.error(error);
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveMarketProductPotential(slug)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveMarketProductPotentialFailure(parsedError));
    }
  }
}

function* workerSubmitConsultantContact({ contactForm }: SubmitConsultantContactAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const marketProductPotential: AxiosResponse<RequestResult<ConsultantContact>> = yield call(
        submitConsultantContact,
        contactForm,
        locale,
        profile.accessToken
      );

      if (marketProductPotential.data) {
        yield put(doSubmitConsultantContactActionSuccess());
      } else {
        yield put(doSubmitConsultantContactActionFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSubmitConsultantContactAction(contactForm)));
    }
  } catch (error) {
    console.error(error);
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSubmitConsultantContactAction(contactForm)));
    } else {
      const parsedError = parseError(error);
      logging.error(error);
      yield put(doRetrieveMarketProductPotentialFailure(parsedError));
    }
  }
}

export function* watcherRetrieveMarketGuide() {
  yield takeLatest(RETRIEVE_MARKET_GUIDE, workerRetrieveMarketGuide);
}
export function* watcherRetrieveMarketGuidePublic() {
  yield takeLatest(RETRIEVE_MARKET_GUIDE_PUBLIC, workerRetrieveMarketGuidePublic);
}

export function* watcherRetrieveMarketSwissExport() {
  yield takeLatest(RETRIEVE_MARKET_SWISS_EXPORT, workerRetrieveMarketSwissExport);
}

export function* watcherRetrieveMarketTopExporters() {
  yield takeLatest(RETRIEVE_MARKET_TOP_EXPORTERS, workerRetrieveMarketTopExporters);
}

export function* watcherRetrieveMarketSwissTraits() {
  yield takeLatest(RETRIEVE_MARKET_SWISS_TRAITS, workerRetrieveMarketSwissTraits);
}
export function* watcherRetrieveMarketProductPotential() {
  yield takeLatest(RETRIEVE_MARKET_PRODUCT_POTENTIAL, workerRetrieveMarketProductPotential);
}

export function* watcherSubmitConsultantContact() {
  yield takeLatest(SUBMIT_CONSULTANT_CONTACT, workerSubmitConsultantContact);
}

export function* marketGuideSaga() {
  yield all([
    call(watcherRetrieveMarketGuide),
    call(watcherRetrieveMarketGuidePublic),
    call(watcherRetrieveMarketSwissExport),
    call(watcherRetrieveMarketTopExporters),
    call(watcherRetrieveMarketSwissTraits),
    call(watcherRetrieveMarketProductPotential),
    call(watcherSubmitConsultantContact)
  ]);
}
