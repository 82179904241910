import React, { ChangeEvent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Styles from './styles';
import { connect, useDispatch } from 'react-redux';

import { doEditTranslation, doUpdateTranslation, doUpdateTranslationCancelled } from 'src/redux/modules/app/actions';

import { bindActionCreators, Dispatch } from 'redux';
import { doDeselectProduct, doSearchProducts, doSelectProduct } from 'src/redux/modules/product/actions';
import { AppState } from 'src/redux/reducers';
import { Spacing, Input, Flex, Text, SVGIcon } from '@components';
import { useLocale } from 'src/locales';
import { capitalizeFirstCharacter } from '@utils/stringUtils';

const OnPageEditor = (props: OnPageEditorProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const dispatch = useDispatch();
  const [maximized, setMaximized] = useState(false);

  const [currentValue, setCurrentValue] = useState(
    String(intl.formatMessage({ id: props.currentTranslationKey || 'none' }))
  );
  const onCurrentValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentValue(e.target.value);
  };

  useEffect(() => {
    const val = intl.formatMessage({ id: props.currentTranslationKey || 'none' });
    setCurrentValue(String(val));
  }, [props.currentTranslationKey]);

  return (
    <>
      {(props.translationUpdatingStatus == 'editing' ||
        props.translationUpdatingStatus == 'updating-translation' ||
        props.translationUpdatingStatus == 'storing-in-db-success') && (
        <Flex justifyContent="center">
          <Styles.EditorContainer maximized={maximized}>
            <Spacing padding="md">
              <Flex vertical gap="sm">
                <Flex justifyContent="space-between" vertical={false} gap="sm">
                  <div>Translation key:</div>

                  <Flex justifyContent="right">
                    {maximized ? (
                      <SVGIcon
                        onClick={() => {
                          setMaximized(false);
                        }}
                        paddingLeft="xsm"
                        size="20px"
                        src="/static/icons/semiArrowDownTiny.svg"
                      />
                    ) : (
                      <SVGIcon
                        onClick={() => {
                          setMaximized(true);
                        }}
                        paddingLeft="xsm"
                        size="20px"
                        src="/static/icons/open.svg"
                      />
                    )}
                  </Flex>
                </Flex>
                <strong>{props.currentTranslationKey}</strong>

                <Flex gap="default">
                  <Input.Group fullWidth>
                    <label>
                      <Text.H6 weight="bold"></Text.H6>
                    </label>
                    <Input.TextArea onChange={onCurrentValueChange} value={currentValue} />
                  </Input.Group>
                </Flex>
              </Flex>

              <Flex justifyContent="space-between" vertical={false}>
                <Flex>
                  {props.translationUpdatingStatus == 'updating-translation' ||
                  props.translationUpdatingStatus == 'storing-in-db-success' ? (
                    <SVGIcon size="19px" src="/static/icons/dashboard-loader.svg" />
                  ) : (
                    <Flex justifyContent="left" gap="xsm">
                      <span>
                        {capitalizeFirstCharacter(intl.formatMessage({ id: 'globalNav.language' }).toLocaleLowerCase())}
                        :
                      </span>
                      <b> {locale}</b>
                    </Flex>
                  )}
                </Flex>
                <Flex justifyContent="right" gap="md">
                  <Text.Link
                    highlightOnHover={true}
                    underlineOnHover={false}
                    weight="bold"
                    onClick={() => {
                      dispatch(doUpdateTranslationCancelled());
                    }}
                  >
                    <FormattedMessage id="onPageEditing.cancel" />
                  </Text.Link>
                  <Text.Link
                    highlightOnHover={true}
                    underlineOnHover={false}
                    weight="bold"
                    onClick={() => {
                      dispatch(doUpdateTranslation(props.currentTranslationKey || '', currentValue));
                    }}
                  >
                    <FormattedMessage id="onPageEditing.save" />
                  </Text.Link>
                </Flex>
              </Flex>
            </Spacing>
          </Styles.EditorContainer>
        </Flex>
      )}
    </>
  );
};

const mapStateToProps = ({ appReducer }: AppState) => ({
  currentTranslationKey: appReducer.currentTranslationKey,
  translationUpdatingStatus: appReducer.translationUpdatingStatus
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doEditTranslation, doSearchProducts, doSelectProduct, doDeselectProduct }, dispatch);

export type OnPageEditorProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OnPageEditor);
