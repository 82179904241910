import axios from 'axios';
import { RequestResult } from '../../../interfaces/requests';
import { Product, SelectedProduct, SelectedProductFull } from './interfaces';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 *
 * @param keyword
 * @param locale
 */
export function hsSearch(keyword: string, locale: string) {
  return axios.get(`${publicRuntimeConfig.TRADE_INSIGHTS_URI}/${locale}/hscodes/search/?q=${keyword}`, {
    headers: { authorization: publicRuntimeConfig.TRADE_INSIGHTS_API_KEY },
    params: { year: publicRuntimeConfig.TRADE_INSIGHTS_YEAR }
  });
}

/**
 *
 * @param HSCodes
 * @param locale
 * @param accessToken
 */
export function saveProducts(HSCodes: string[], locale: string, accessToken: string) {
  return axios.post<RequestResult<Partial<Product>>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/hscodes`,
    { hscodes: HSCodes },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

/**
 *
 * @param locale
 * @param accessToken
 */
export function getProducts(locale: string, accessToken: string) {
  return axios.get<RequestResult<Partial<SelectedProduct[]>>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/gti/hscodes`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

/**
 *
 * @param locale
 * @param accessToken
 */
export function getProductsFull(locale: string, accessToken: string) {
  return axios.get<RequestResult<Partial<SelectedProductFull[]>>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/hscodes`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
