import {
  CREATEUPDATE_BO_SEARCH_CRITERIA,
  CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS,
  CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE,
  RETRIEVE_BO_SEARCH_CRITERIAS,
  RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE,
  RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS,
  CreateUpdateBOSearchCriteriaAction,
  CreateUpdateBOSearchCriteriaFailureAction,
  RetrieveBOSearchCriteriaAction,
  RetrieveBOSearchCriteriaFailureAction,
  RetrieveBOSearchCriteriaSuccessAction,
  CreateUpdateBOSearchCriteriaSuccessAction,
  DeleteBOSearchCriteriaAction,
  DELETE_BO_SEARCH_CRITERIA,
  DELETE_BO_SEARCH_CRITERIA_SUCCESS,
  DeleteBOSearchCriteriaSuccessAction,
  DELETE_BO_SEARCH_CRITERIA_FAILURE,
  DeleteBOSearchCriteriaFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import { BOSearchCriteria, BOSearchCriteriaCreateUpdateParams, BOSearchCriteriaType } from './interfaces';

//Create + Update
export const doCreateUpdateBOSearchCriteria = (
  //Criteria to be created/updated
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams
): CreateUpdateBOSearchCriteriaAction => ({
  type: CREATEUPDATE_BO_SEARCH_CRITERIA,
  bOSearchCriteriaToBeStored
});

export const doCreateUpdateBOSearchCriteriaSuccess = (
  //Criteria that is being created/updated
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams,
  bOSearchCriteriaStored: BOSearchCriteria
): CreateUpdateBOSearchCriteriaSuccessAction => ({
  type: CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS,
  bOSearchCriteriaToBeStored,
  bOSearchCriteriaStored
});

export const doCreateUpdateBOSearchCriteriaFailure = (
  //Criteria that is being created/updated
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams,
  error?: RequestResult<void>
): CreateUpdateBOSearchCriteriaFailureAction => ({
  type: CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE,
  error,
  bOSearchCriteriaToBeStored
});

//Delete
export const doDeleteBOSearchCriteria = (
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType
): DeleteBOSearchCriteriaAction => ({
  type: DELETE_BO_SEARCH_CRITERIA,
  bOSearchCriteriaTypeToBeDeleted
});

export const doDeleteBOSearchCriteriaSuccess = (
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType
): DeleteBOSearchCriteriaSuccessAction => ({
  type: DELETE_BO_SEARCH_CRITERIA_SUCCESS,
  bOSearchCriteriaTypeToBeDeleted
});

export const doDeleteBOSearchCriteriaFailure = (
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType
): DeleteBOSearchCriteriaFailureAction => ({
  type: DELETE_BO_SEARCH_CRITERIA_FAILURE,
  bOSearchCriteriaTypeToBeDeleted
});

//List
export const doRetrieveBOSearchCriteria = (): RetrieveBOSearchCriteriaAction => ({
  type: RETRIEVE_BO_SEARCH_CRITERIAS
});

export const doRetrieveBOSearchCriteriaFailure = (
  error?: RequestResult<void>
): RetrieveBOSearchCriteriaFailureAction => ({
  error,
  type: RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE
});

export const doRetrieveBOSearchCriteriaSuccess = (
  bOSearchCriterias: BOSearchCriteria[]
): RetrieveBOSearchCriteriaSuccessAction => ({
  type: RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS,
  bOSearchCriterias
});
