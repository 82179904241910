import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { BOSearchCriteria, BOSearchCriteriaCreateUpdateParams, BOSearchCriteriaType } from './interfaces';

const { publicRuntimeConfig } = getConfig();

/**
 *
 * @param accessToken
 * @param locale
 */
export function retrieveBOSearchCriterias(accessToken: string, locale: string) {
  return axios.get<RequestResult<BOSearchCriteria>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/bo-search-criterias/list?type=email-notifications-criterias`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}

/**
 *
 * @param accessToken
 * @param locale
 */
export function createUpdateBOSearchCriteria(
  accessToken: string,
  locale: string,
  bOSearchCriteria?: BOSearchCriteriaCreateUpdateParams
) {
  return axios.post<RequestResult<BOSearchCriteria>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/bo-search-criterias/create-update`,
    bOSearchCriteria,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {}
    }
  );
}

/**
 *
 * @param accessToken
 * @param locale
 */
export function deleteBOSearchCriteria(
  accessToken: string,
  locale: string,
  bOSearchCriteriaType?: BOSearchCriteriaType
) {
  return axios.post<RequestResult<null>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/bo-search-criterias/delete`,
    { bOSearchCriteriaType },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {}
    }
  );
}
