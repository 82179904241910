import {
  RETRIEVE_GLOBAL_IMPORT_VOLUME,
  RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS,
  RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE,
  RetrieveGlobalImportVolumeAction,
  RetrieveGlobalImportVolumeSuccessAction,
  RetrieveGlobalImportVolumeFailureAction,
  RETRIEVE_LARGEST_IMPORTERS,
  RETRIEVE_LARGEST_IMPORTERS_FAILURE,
  RETRIEVE_LARGEST_IMPORTERS_SUCCESS,
  RetrieveLargestImportersAction,
  RetrieveLargestImportersFailureAction,
  RetrieveLargestImportersSuccessAction
} from './types';
import { GlobalImportVolume, Country } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveGlobalImportVolume = (): RetrieveGlobalImportVolumeAction => ({
  type: RETRIEVE_GLOBAL_IMPORT_VOLUME
});

export const doRetrieveGlobalImportVolumeSuccess = (
  globalImportVolume: GlobalImportVolume
): RetrieveGlobalImportVolumeSuccessAction => ({
  type: RETRIEVE_GLOBAL_IMPORT_VOLUME_SUCCESS,
  globalImportVolume
});

export const doRetrieveGlobalImportVolumeFailure = (
  error?: RequestResult<void>
): RetrieveGlobalImportVolumeFailureAction => ({
  type: RETRIEVE_GLOBAL_IMPORT_VOLUME_FAILURE,
  error
});

export const doRetrieveLargestImporters = (): RetrieveLargestImportersAction => ({
  type: RETRIEVE_LARGEST_IMPORTERS
});

export const doRetrieveLargestImportersSuccess = (
  largestImporters: Country[]
): RetrieveLargestImportersSuccessAction => ({
  type: RETRIEVE_LARGEST_IMPORTERS_SUCCESS,
  largestImporters
});

export const doRetrieveLargestImportersFailure = (
  error?: RequestResult<void>
): RetrieveLargestImportersFailureAction => ({
  type: RETRIEVE_LARGEST_IMPORTERS_FAILURE,
  error
});
