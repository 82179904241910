import { PostingNationality } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_POSTING_NATIONALITIES = 'postingnationalities/RETRIEVE_POSTING_NATIONALITIES';
export const RETRIEVE_POSTING_NATIONALITIES_SUCCESS = 'postingnationalities/RETRIEVE_POSTING_NATIONALITIES_SUCCESS';
export const RETRIEVE_POSTING_NATIONALITIES_FAILURE = 'postingnationalities/RETRIEVE_POSTING_NATIONALITIES_FAILURE';

export interface RetrievePostingNationalitiesAction {
  type: typeof RETRIEVE_POSTING_NATIONALITIES;
}

export interface RetrievePostingNationalitiesSuccessAction {
  type: typeof RETRIEVE_POSTING_NATIONALITIES_SUCCESS;
  postingNationalities: PostingNationality[];
}

export interface RetrievePostingNationalitiesFailureAction {
  type: typeof RETRIEVE_POSTING_NATIONALITIES_FAILURE;
  error?: RequestResult<void>;
}
