import axios from 'axios';
import getConfig from 'next/config';
import { CRMContact } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { User } from '../user/interfaces';
import { Company } from '../company/interfaces';
import { Industry } from '../industry/interfaces';
import { HSCode } from '../product/interfaces';

const { publicRuntimeConfig } = getConfig();

export function retrieveCRMContact(accessToken: string, locale: string) {
  return axios.get<RequestResult<CRMContact>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/crm/contacts/user`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

type OnboardBody = {
  user: User;
  company: Company;
  industries: Industry['id'][];
  products: HSCode['HSCode'][];
};

export function onboardUser(accessToken: string, locale: string, body: OnboardBody) {
  return axios.post<RequestResult<CRMContact>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/onboard`, body, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
