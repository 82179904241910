import { RequestResult } from '../../../interfaces/requests';
import { BOSearchCriteria, BOSearchCriteriaCreateUpdateParams, BOSearchCriteriaType } from './interfaces';

export const CREATEUPDATE_BO_SEARCH_CRITERIA = 'bosearchcriteria/CREATEUPDATE_BO_SEARCH_CRITERIA';
export const CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS = 'bosearchcriteria/CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS';
export const CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE = 'bosearchcriteria/CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE';

export const DELETE_BO_SEARCH_CRITERIA = 'bosearchcriteria/DELETE_BO_SEARCH_CRITERIA';
export const DELETE_BO_SEARCH_CRITERIA_SUCCESS = 'bosearchcriteria/DELETE_BO_SEARCH_CRITERIA_SUCCESS';
export const DELETE_BO_SEARCH_CRITERIA_FAILURE = 'bosearchcriteria/DELETE_BO_SEARCH_CRITERIA_FAILURE';

export const RETRIEVE_BO_SEARCH_CRITERIAS = 'bosearchcriteria/RETRIEVE_BO_SEARCH_CRITERIAS';
export const RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE = 'bosearchcriteria/RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE';
export const RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS = 'bosearchcriteria/RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS';

//Create + Update
export interface CreateUpdateBOSearchCriteriaAction {
  type: typeof CREATEUPDATE_BO_SEARCH_CRITERIA;
  //Criteria to be created/updated
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams;
}

export interface CreateUpdateBOSearchCriteriaSuccessAction {
  type: typeof CREATEUPDATE_BO_SEARCH_CRITERIA_SUCCESS;
  //Criteria that is being created/updated
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams;
  bOSearchCriteriaStored: BOSearchCriteriaCreateUpdateParams;
}

//Criteria that is being created/updated
export interface CreateUpdateBOSearchCriteriaFailureAction {
  type: typeof CREATEUPDATE_BO_SEARCH_CRITERIA_FAILURE;
  error?: RequestResult<void>;
  bOSearchCriteriaToBeStored: BOSearchCriteriaCreateUpdateParams;
}

//Delete
export interface DeleteBOSearchCriteriaAction {
  type: typeof DELETE_BO_SEARCH_CRITERIA;
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType;
}

export interface DeleteBOSearchCriteriaSuccessAction {
  type: typeof DELETE_BO_SEARCH_CRITERIA_SUCCESS;
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType;
}

export interface DeleteBOSearchCriteriaFailureAction {
  type: typeof DELETE_BO_SEARCH_CRITERIA_FAILURE;
  error?: RequestResult<void>;
  bOSearchCriteriaTypeToBeDeleted: BOSearchCriteriaType;
}

//List
export interface RetrieveBOSearchCriteriaAction {
  type: typeof RETRIEVE_BO_SEARCH_CRITERIAS;
}

export interface RetrieveBOSearchCriteriaSuccessAction {
  type: typeof RETRIEVE_BO_SEARCH_CRITERIAS_SUCCESS;
  bOSearchCriterias: BOSearchCriteria[];
}

export interface RetrieveBOSearchCriteriaFailureAction {
  type: typeof RETRIEVE_BO_SEARCH_CRITERIAS_FAILURE;
  error?: RequestResult<void>;
}
