import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function checkOec(accessToken: string, locale: string, url: string) {
  return axios.get<RequestResult>(`${publicRuntimeConfig.API_URI}/v1/${locale}/oec/check`, {
    params: { url },
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
