import axios from 'axios';
import getConfig from 'next/config';
import { Country } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveCountries(accessToken: string, locale: string) {
  return axios.get<RequestResult<Country[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/countries`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
