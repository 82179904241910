import { createReducer } from 'redux-starter-kit';
import {
  RETRIEVE_ALL_CACHED_MARKETS,
  RETRIEVE_ALL_CACHED_MARKETS_FAILURE,
  RETRIEVE_ALL_CACHED_MARKETS_SUCCESS,
  RetrieveAllCachedMarketsSuccessAction,
  RetrieveAllCachedMarketsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface CachedMarketListReducerState {
  cachedMarketList: RetrieveAllCachedMarketsSuccessAction['marketList'];
  loading: boolean;
  error?: RequestResult;
}

const initialState: CachedMarketListReducerState = {
  cachedMarketList: [],
  loading: false
};

export default createReducer<CachedMarketListReducerState>(initialState, {
  [RETRIEVE_ALL_CACHED_MARKETS]: state => {
    state.loading = true;
    state.error = initialState.error;
  },
  [RETRIEVE_ALL_CACHED_MARKETS_SUCCESS]: (state, action: RetrieveAllCachedMarketsSuccessAction) => {
    state.loading = initialState.loading;
    state.error = initialState.error;
    state.cachedMarketList = action.marketList;
  },
  [RETRIEVE_ALL_CACHED_MARKETS_FAILURE]: (state, action: RetrieveAllCachedMarketsFailureAction) => {
    state.loading = initialState.loading;
    state.error = action.error;
  }
});
