// Modules
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';

// Operations(API Calls)
import {
  createWatchlist,
  deleteWatchlist,
  duplicateWatchlist,
  exportWatchlist,
  getWatchlistDetails,
  retrieveWatchlists,
  retrieveWatchlistsPaginated,
  updateWatchlist
} from './operations';

// Types
import { AppState } from '../../reducers';

// Redux
import {
  doRetrieveWatchlists,
  doRetrieveWatchlistsPaginated,
  doCreateWatchlist,
  doUpdateWatchlist,
  doRetrieveWatchlistsPaginatedSuccess,
  doRetrieveWatchlistsSuccess,
  doCreateWatchlistSuccess,
  doUpdateWatchlistSuccess,
  doCreateWatchlistFailure,
  doUpdateWatchlistFailure,
  doRetrieveWatchlistsPaginatedFailure,
  doRetrieveWatchlistsFailure,
  doRetrieveWatchlistDetails,
  doRetrieveWatchlistDetailsSuccess,
  doDeleteWatchlistSuccess,
  doDeleteWatchlist,
  doDeleteWatchlistFailure,
  doRetrieveWatchlistDetailsFailure,
  doDuplicateWatchlistSuccess,
  doDuplicateWatchlist,
  doDuplicateWatchlistFailure,
  doExportWatchlistSuccess,
  doExportWatchlist,
  doExportWatchlistFailure
} from './actions';
import {
  RETRIEVE_WATCHLISTS,
  RETRIEVE_WATCHLISTS_PAGINATED,
  CREATE_WATCHLIST,
  UPDATE_WATCHLIST,
  RetrieveWatchlistsAction,
  RetrieveWatchlistsPaginatedAction,
  CreateWatchlistAction,
  UpdateWatchlistAction,
  RetrieveWatchlistDetailsAction,
  RETRIEVE_WATCHLIST_DETAILS,
  DELETE_WATCHLIST,
  DeleteWatchlistAction,
  DuplicateWatchlistAction,
  DUPLICATE_WATCHLIST,
  ExportWatchlistAction,
  EXPORT_WATCHLIST
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveWatchlists(action: RetrieveWatchlistsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveWatchlists, profile.accessToken, locale, action.params);
      if (response.data) {
        yield put(doRetrieveWatchlistsSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlists(action.params)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlists(action.params)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistsFailure(error));
    }
  }
}

function* workerRetrieveWatchlistDetails(action: RetrieveWatchlistDetailsAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(getWatchlistDetails, profile.accessToken, locale, action.wathlistId);
      if (response.data) {
        yield put(doRetrieveWatchlistDetailsSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistDetails(action.wathlistId)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistDetails(action.wathlistId)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistDetailsFailure(error));
    }
  }
}

function* workerRetrieveWatchlistsPaginated(action: RetrieveWatchlistsPaginatedAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(retrieveWatchlistsPaginated, profile.accessToken, locale, action.params);
      if (response.data) {
        yield put(doRetrieveWatchlistsPaginatedSuccess(response.data.result.data, response.data.result.count));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistsPaginated(action.params)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveWatchlistsPaginated(action.params)));
    } else {
      logging.error(error);
      yield put(doRetrieveWatchlistsPaginatedFailure(error));
    }
  }
}

function* workerCreateWatchlist(action: CreateWatchlistAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(createWatchlist, profile.accessToken, locale, action.data);
      if (response.data) {
        yield put(doCreateWatchlistSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCreateWatchlist(action.data)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCreateWatchlist(action.data)));
    } else {
      logging.error(error);
      yield put(doCreateWatchlistFailure(error?.response?.data));
    }
  }
}

function* workerDuplicateWatchlist(action: DuplicateWatchlistAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(duplicateWatchlist, profile.accessToken, locale, action.watchlistId, action.data);
      if (response.data) {
        yield put(doDuplicateWatchlistSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doDuplicateWatchlist(action.watchlistId, action.data))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doDuplicateWatchlist(action.watchlistId, action.data))
      );
    } else {
      logging.error(error);
      yield put(doDuplicateWatchlistFailure(error));
    }
  }
}

function* workerExportWatchlist(action: ExportWatchlistAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(
        exportWatchlist,
        profile.accessToken,
        locale,
        action.watchlistId,
        action.watchlistDetails
      );
      if (response) {
        yield put(doExportWatchlistSuccess());
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doExportWatchlist(action.watchlistId, action.watchlistDetails))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doExportWatchlist(action.watchlistId, action.watchlistDetails))
      );
    } else {
      logging.error(error);
      yield put(doExportWatchlistFailure(error));
    }
  }
}

function* workerUpdateWatchlist(action: UpdateWatchlistAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(updateWatchlist, profile.accessToken, locale, action.watchlistId, action.data);
      if (response.data) {
        yield put(doUpdateWatchlistSuccess(response.data.result));
      }
    } else {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doUpdateWatchlist(action.watchlistId, action.data))
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(
        workerRefreshTokenAndRetry,
        doRefreshTokenAndRetry(doUpdateWatchlist(action.watchlistId, action.data))
      );
    } else {
      logging.error(error);
      yield put(doUpdateWatchlistFailure(error));
    }
  }
}

function* workerDeleteWatchlist(action: DeleteWatchlistAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response = yield call(deleteWatchlist, profile.accessToken, locale, action.watchlistId);
      if (response.data) {
        yield put(doDeleteWatchlistSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doDeleteWatchlist(action.watchlistId)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doDeleteWatchlist(action.watchlistId)));
    } else {
      logging.error(error);
      yield put(doDeleteWatchlistFailure(error));
    }
  }
}

export function* watcherCreateWatchlist() {
  yield takeLatest(CREATE_WATCHLIST, workerCreateWatchlist);
}

export function* watcherDuplicateWatchlist() {
  yield takeLatest(DUPLICATE_WATCHLIST, workerDuplicateWatchlist);
}

export function* watcherExportWatchlist() {
  yield takeLatest(EXPORT_WATCHLIST, workerExportWatchlist);
}

export function* watcherUpdateWatchlist() {
  yield takeLatest(UPDATE_WATCHLIST, workerUpdateWatchlist);
}

export function* watcherDeleteWatchlist() {
  yield takeLatest(DELETE_WATCHLIST, workerDeleteWatchlist);
}

export function* watcherRetrieveWatchlists() {
  yield takeLatest(RETRIEVE_WATCHLISTS, workerRetrieveWatchlists);
}

export function* watcherRetrieveWatchlistDetails() {
  yield takeLatest(RETRIEVE_WATCHLIST_DETAILS, workerRetrieveWatchlistDetails);
}

export function* watcherRetrieveWatchlistsPaginated() {
  yield takeLatest(RETRIEVE_WATCHLISTS_PAGINATED, workerRetrieveWatchlistsPaginated);
}

export function* watchlistSaga() {
  yield all([
    call(watcherRetrieveWatchlists),
    call(watcherRetrieveWatchlistsPaginated),
    call(watcherCreateWatchlist),
    call(watcherUpdateWatchlist),
    call(watcherDeleteWatchlist),
    call(watcherRetrieveWatchlistDetails),
    call(watcherDuplicateWatchlist),
    call(watcherExportWatchlist)
  ]);
}
