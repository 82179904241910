import {
  RETRIEVE_QUESTIONS,
  RETRIEVE_QUESTIONS_SUCCESS,
  RETRIEVE_QUESTIONS_FAILURE,
  RetrieveNavigatorQuestionsAction,
  RetrieveNavigatorQuestionsSuccessAction,
  RetrieveNavigatorQuestionsFailureAction
} from './types';
import { Question } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveQuestions = (): RetrieveNavigatorQuestionsAction => ({
  type: RETRIEVE_QUESTIONS
});

export const doRetrieveQuestionsSuccess = (questions: Question[]): RetrieveNavigatorQuestionsSuccessAction => ({
  type: RETRIEVE_QUESTIONS_SUCCESS,
  questions
});

export const doRetrieveQuestionsFailure = (error?: RequestResult<void>): RetrieveNavigatorQuestionsFailureAction => ({
  type: RETRIEVE_QUESTIONS_FAILURE,
  error
});
