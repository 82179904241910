import React, { useEffect } from 'react';

import { AppState } from '../../../../redux/reducers';
import { connect } from 'react-redux';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { Dispatch, bindActionCreators } from 'redux';
import {
  doChangeSearch,
  doSearchProducts,
  doSelectProduct,
  doDeselectProduct
} from '../../../../redux/modules/product/actions';
// @ts-ignore
import Intercom from './react-intercom.tsx';
import { businessType2IntercomBusinessType, user2IntercomBusinessType } from 'src/utils/userUtils';
import moment from 'moment';
import { doCloseIntercomChat, doOpenIntercomChat } from '@redux-modules/intercom/actions';

const { publicRuntimeConfig } = getConfig();

export type IntercomContainerProps = ReducerProps;

const IntercomContainer: React.FC<IntercomContainerProps> = props => {
  const intercomSettings: any = {
    horizontal_padding: 16,
    vertical_padding: 16
  };
  const router = useRouter();

  const updateIntercomData = () => {
    if (!intercomSettings) return;
    if (!window['Intercom']) return;

    const { appID, ...otherProps } = intercomSettings;
    window['Intercom']('update', { ...otherProps, app_id: appID });
  };

  //Introduced delay as the page title changes also with a slight delay
  useEffect(() => {
    setTimeout(updateIntercomData, 2000);
  }, [router.pathname, props.products, props.selectedBusinessTypeOnOnboarding, props.selectedProductsOnOnboarding]);

  if (!process.browser) return <></>; // don't show on server side

  if (!props.profile) {
    if (window['Intercom'] && typeof window['Intercom'] === 'function') {
      // Intercom was already initialized before - shutdown
      window['Intercom']('shutdown');
    }
    return null;
  }

  const user: any = {
    ...props.profile,
    ...props.onboardingUser
  };

  const company = {
    ...props.onboardingCompany
  };

  const businessType = props.selectedBusinessTypeOnOnboarding
    ? businessType2IntercomBusinessType(props.selectedBusinessTypeOnOnboarding)
    : user2IntercomBusinessType(props.loggedInUser);

  intercomSettings['appID'] = publicRuntimeConfig.INTERCOM_APP_ID;
  // appID: 'btk8uvsh',
  intercomSettings['name'] = `${user.firstName} ${user.lastName}`;
  if (props.loggedInUser?.id) intercomSettings['user_id'] = props.loggedInUser?.id;
  intercomSettings['email'] = user.workEmailAddress || user.email;
  const createdAt = props.loggedInUser?.createdAt || user.createdAt;
  if (createdAt) {
    intercomSettings['created_at'] = moment(createdAt).unix();
  }

  if (company.name) intercomSettings['company_name'] = company.name;

  intercomSettings['city_name'] = company.city;
  intercomSettings['country_name'] = company.countryName;
  intercomSettings['Business type'] = businessType;

  const productsSourceArray =
    props.products && props.products.length > 0 ? props.products : props.selectedProductsOnOnboarding || [];

  if (businessType == 'Physical goods' || businessType == 'Software or Services with physical components') {
    if (productsSourceArray.length > 0)
      intercomSettings['products'] = productsSourceArray
        .map(product => `${product.HSCode} ${product.description}`)
        .join('; ');
  } else {
    intercomSettings['products'] = '';
  }

  return (
    <div>
      {publicRuntimeConfig.INTERCOM_APP_ID && (
        <Intercom
          {...intercomSettings}
          isChatOpen={props.isChatOpen}
          onOpenIntercomChat={props.doOpenIntercomChat}
          onCloseIntercomChat={props.doCloseIntercomChat}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  authReducer,
  onboardingReducer,
  productReducer,
  userReducer,
  intercomReducer
}: AppState) => ({
  profile: authReducer.profile,
  onboardingUser: onboardingReducer.onboardingUser,
  loggedInUser: userReducer.user,
  onboardingCompany: onboardingReducer.onboardingCompany,
  products: productReducer.products,
  selectedProductsOnOnboarding: productReducer.selected,
  selectedBusinessTypeOnOnboarding: onboardingReducer.onboardingFormsCurrentValues.businessType,
  isChatOpen: intercomReducer.isChatOpen
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doChangeSearch,
      doSearchProducts,
      doSelectProduct,
      doDeselectProduct,
      doOpenIntercomChat,
      doCloseIntercomChat
    },
    dispatch
  );

export type ReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(IntercomContainer);
