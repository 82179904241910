import { RequestResult } from '../../../interfaces/requests';
import { PostingEmployeesReport } from './interfaces';

export const ADD_POSTING_EMPLOYEES_REPORT = 'postingemployeesreports/ADD_POSTING_EMPLOYEES_REPORT';
export const ADD_POSTING_EMPLOYEES_REPORT_SUCCESS = 'postingemployeesreports/ADD_POSTING_EMPLOYEES_REPORT_SUCCESS';
export const ADD_POSTING_EMPLOYEES_REPORT_FAILURE = 'postingemployeesreports/ADD_POSTING_EMPLOYEES_REPORT_FAILURE';

export const RETRIEVE_POSTING_EMPLOYEES_REPORT = 'postingemployeesreports/RETRIEVE_POSTING_EMPLOYEES_REPORT';
export const RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE =
  'postingemployeesreports/RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE';
export const RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS =
  'postingemployeesreports/RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS';

export interface SavePostingEmployeesReportSuccessAction {
  type: typeof ADD_POSTING_EMPLOYEES_REPORT_SUCCESS;
  postingEmployeesReport: PostingEmployeesReport;
}

export interface SavePostingEmployeesReportFailureAction {
  type: typeof ADD_POSTING_EMPLOYEES_REPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface SavePostingEmployeesReportAction {
  type: typeof ADD_POSTING_EMPLOYEES_REPORT;
  postingReasonId?: string;
  targetMarketId?: string;
  postingNationalityId?: string;
  postingEmployeesDurationId?: string;
}

export interface RetrievePostingEmployeesReportSuccessAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_REPORT_SUCCESS;
  postingEmployeesReport: PostingEmployeesReport;
}

export interface RetrievePostingEmployeesReportFailureAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_REPORT_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrievePostingEmployeesReportAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_REPORT;
}
