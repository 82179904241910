import { Question } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_QUESTIONS = 'navigator/RETRIEVE_QUESTIONS';
export const RETRIEVE_QUESTIONS_SUCCESS = 'navigator/RETRIEVE_QUESTIONS_SUCCESS';
export const RETRIEVE_QUESTIONS_FAILURE = 'navigator/RETRIEVE_QUESTIONS_FAILURE';

export interface RetrieveNavigatorQuestionsAction {
  type: typeof RETRIEVE_QUESTIONS;
}

export interface RetrieveNavigatorQuestionsSuccessAction {
  type: typeof RETRIEVE_QUESTIONS_SUCCESS;
  questions: Question[];
}

export interface RetrieveNavigatorQuestionsFailureAction {
  type: typeof RETRIEVE_QUESTIONS_FAILURE;
  error?: RequestResult<void>;
}
