import { CMSPublication } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_MARKET_PUBLICATIONS = 'publications/RETRIEVE_MARKET_PUBLICATIONS';
export const RETRIEVE_MARKET_PUBLICATIONS_SUCCESS = 'publications/RETRIEVE_MARKET_PUBLICATIONS_SUCCESS';
export const RETRIEVE_MARKET_PUBLICATIONS_FAILURE = 'publications/RETRIEVE_MARKET_PUBLICATIONS_FAILURE';

export interface RetrieveMarketPublicationsAction {
  type: typeof RETRIEVE_MARKET_PUBLICATIONS;
  slug: string;
}

export interface RetrieveMarketPublicationsSuccessAction {
  type: typeof RETRIEVE_MARKET_PUBLICATIONS_SUCCESS;
  publications: CMSPublication[];
}

export interface RetrieveMarketPublicationsFailureAction {
  type: typeof RETRIEVE_MARKET_PUBLICATIONS_FAILURE;
  error?: RequestResult<void>;
}
