import { createReducer } from 'redux-starter-kit';
import { PostingEmployeesDuration } from './interfaces';
import {
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS,
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS,
  RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE,
  RetrievePostingEmployeesDurationsSuccessAction,
  RetrievePostingEmployeesDurationsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface PostingEmployeesDurationsReducerState {
  postingEmployeesDurations?: PostingEmployeesDuration[];
  retrieveLoading: boolean;
  error: RequestResult<void> | null;
}

const initialState: PostingEmployeesDurationsReducerState = {
  postingEmployeesDurations: [],
  retrieveLoading: false,
  error: null
};

export default createReducer<PostingEmployeesDurationsReducerState>(initialState, {
  [RETRIEVE_POSTING_EMPLOYEES_DURATIONS]: state => {
    state.retrieveLoading = true;
    state.error = null;
  },
  [RETRIEVE_POSTING_EMPLOYEES_DURATIONS_SUCCESS]: (state, action: RetrievePostingEmployeesDurationsSuccessAction) => {
    state.retrieveLoading = false;
    state.postingEmployeesDurations = action.postingEmployeesDurations;
    state.error = null;
  },
  [RETRIEVE_POSTING_EMPLOYEES_DURATIONS_FAILURE]: (state, action: RetrievePostingEmployeesDurationsFailureAction) => {
    state.retrieveLoading = false;
    state.postingEmployeesDurations = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
