import {
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS,
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE,
  RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS,
  RetrievePostingEmployeesContentsAction,
  RetrievePostingEmployeesContentsFailureAction,
  RetrievePostingEmployeesContentsSuccessAction
} from './types';
import { PostingEmployeesContent } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrievePostingEmployeesContents = (
  postingReasonId?: string,
  targetMarketId?: string,
  postingNationalityId?: string,
  postingEmployeesDurationId?: string,
  contentTypes?: string
): RetrievePostingEmployeesContentsAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_CONTENTS,
  postingReasonId: postingReasonId,
  targetMarketId: targetMarketId,
  postingNationalityId: postingNationalityId,
  postingEmployeesDurationId: postingEmployeesDurationId,
  contentTypes: contentTypes
});

export const doRetrievePostingEmployeesContentsFailure = (
  error?: RequestResult<void>
): RetrievePostingEmployeesContentsFailureAction => ({
  error,
  type: RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE
});

export const doRetrievePostingEmployeesContentsSuccess = (
  postingEmployeesContent: PostingEmployeesContent[]
): RetrievePostingEmployeesContentsSuccessAction => ({
  type: RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS,
  postingEmployeesContent
});
