import { createReducer } from 'redux-starter-kit';
import { CHECK_OEC, CHECK_OEC_SUCCESS, CHECK_OEC_FAILURE } from './types';

export interface OecReducerState {
  checked: boolean;
  succeeded: boolean;
}

const initialState: OecReducerState = {
  checked: false,
  succeeded: false
};

export default createReducer<OecReducerState>(initialState, {
  [CHECK_OEC]: state => {
    state.checked = initialState.checked;
    state.succeeded = initialState.succeeded;
  },
  [CHECK_OEC_SUCCESS]: state => {
    state.checked = true;
    state.succeeded = true;
  },
  [CHECK_OEC_FAILURE]: state => {
    state.checked = true;
    state.succeeded = false;
  }
});
