// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveArticles, selectArticle } from './operations';

// Types
import { AppState } from '../../reducers';
import { CMSArticle } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrieveArticles,
  doRetrieveArticlesFailure,
  doRetrieveArticlesSuccess,
  doSelectArticle,
  doSelectArticleSuccess,
  doSelectArticleFailure,
  doRetrieveMenuArticlesSuccess,
  doRetrieveMenuArticlesFailure,
  doRetrieveMenuArticles
} from './actions';
import {
  RetrieveArticlesAction,
  RETRIEVE_ARTICLES,
  SELECT_ARTICLE,
  SelectArticleAction,
  RETRIEVE_MENU_ARTICLES
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { parseError } from '../../../utils/validationUtils';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveArticles({ limit, offset, industry, market }: RetrieveArticlesAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSArticle[]>> = yield call(
        retrieveArticles,
        limit,
        offset,
        profile.accessToken,
        locale,
        market,
        industry
      );
      if (response.data && response.data.succeeded && response.data.result) {
        const articles = yield select((state: AppState) => state.articleReducer.articles);
        const total =
          response.data.meta && response.data.meta.count ? Number(response.data.meta.count) : offset + limit;
        yield put(doRetrieveArticlesSuccess([...articles.slice(0, offset), ...response.data.result], total));
      } else {
        yield put(doRetrieveArticlesFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveArticles(limit, offset, market, industry)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveArticles(limit, offset, market, industry)));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveArticlesFailure(errorResult));
    }
  }
}

function* workerRetrieveMenuArticles() {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSArticle[]>> = yield call(
        retrieveArticles,
        2,
        0,
        profile.accessToken,
        locale,
        'all',
        'all'
      );
      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doRetrieveMenuArticlesSuccess(response.data.result));
      } else {
        yield put(doRetrieveMenuArticlesFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveMenuArticles()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveMenuArticles()));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveMenuArticlesFailure(errorResult));
    }
  }
}

function* workerSelectArticle({ drupalNodeID }: SelectArticleAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<CMSArticle>> = yield call(selectArticle, locale, drupalNodeID);

      if (response.data && response.data.succeeded && response.data.result) {
        yield put(doSelectArticleSuccess(response.data.result));
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSelectArticle(drupalNodeID)));
    }
  } catch (error) {
    const parsedError = parseError(error);
    if (parsedError.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doSelectArticle(drupalNodeID)));
    } else {
      logging.error(error);
      yield put(doSelectArticleFailure(error));
    }
  }
}

export function* watcherRetrieveArticles() {
  yield takeLatest(RETRIEVE_ARTICLES, workerRetrieveArticles);
}

export function* watcherRetrieveMenuArticles() {
  yield takeLatest(RETRIEVE_MENU_ARTICLES, workerRetrieveMenuArticles);
}

export function* watcherSelectArticle() {
  yield takeLatest(SELECT_ARTICLE, workerSelectArticle);
}

export function* articleSaga() {
  yield all([call(watcherRetrieveArticles), call(watcherSelectArticle), call(watcherRetrieveMenuArticles)]);
}
