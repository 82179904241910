import React, { useEffect, PropsWithChildren } from 'react';
import { NextPage } from 'next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { doRetryServerActions } from '../../../../redux/modules/actions/actions';
import { AppState } from '../../../../redux/reducers';

const ActionsProvider: NextPage<PropsWithChildren<AppReducerProps>> = ({ doRetryServerActions, children }) => {
  useEffect(() => {
    doRetryServerActions();
  });

  return <>{children}</>;
};

const mapStateToProps = ({ actionsReducer }: AppState) => ({ failedServerActions: actionsReducer.failedServerActions });
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ doRetryServerActions }, dispatch);

type AppReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ActionsProvider);
