import { PostingEmployeesContent } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_POSTING_EMPLOYEES_CONTENTS = 'postingemployeescontents/RETRIEVE_POSTING_EMPLOYEES_CONTENTS';
export const RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS =
  'postingemployeescontents/RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS';
export const RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE =
  'postingemployeescontents/RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE';

export interface RetrievePostingEmployeesContentsAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_CONTENTS;
  postingReasonId?: string;
  targetMarketId?: string;
  postingNationalityId?: string;
  postingEmployeesDurationId?: string;
  contentTypes?: string;
}

export interface RetrievePostingEmployeesContentsSuccessAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_CONTENTS_SUCCESS;
  postingEmployeesContent: PostingEmployeesContent[];
}

export interface RetrievePostingEmployeesContentsFailureAction {
  type: typeof RETRIEVE_POSTING_EMPLOYEES_CONTENTS_FAILURE;
  error?: RequestResult<void>;
}
