import {
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES,
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE,
  RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS,
  RetrieveBusinessOpportunityTypesAction,
  RetrieveBusinessOpportunityTypesFailureAction,
  RetrieveBusinessOpportunityTypesSuccessAction
} from './types';
import { BusinessOpportunityType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveBusinessOpportunityTypes = (): RetrieveBusinessOpportunityTypesAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITY_TYPES
});

export const doRetrieveBusinessOpportunityTypesFailure = (
  error?: RequestResult<void>
): RetrieveBusinessOpportunityTypesFailureAction => ({
  error,
  type: RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_FAILURE
});

export const doRetrieveBusinessOpportunityTypesSuccess = (
  businessOpportunityTypes: BusinessOpportunityType[]
): RetrieveBusinessOpportunityTypesSuccessAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITY_TYPES_SUCCESS,
  businessOpportunityTypes
});
