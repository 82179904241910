import { ConsultingPackage } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_CONSULTING_PACKAGES = 'consultingpackages/RETRIEVE_CONSULTING_PACKAGES';
export const RETRIEVE_CONSULTING_PACKAGES_SUCCESS = 'consultingpackages/RETRIEVE_CONSULTING_PACKAGES_SUCCESS';
export const RETRIEVE_CONSULTING_PACKAGES_FAILURE = 'consultingpackages/RETRIEVE_CONSULTING_PACKAGES_FAILURE';
export const SEND_CONFIRMATION_MAIL_TO_CONSULTANT = 'consultingpackages/SEND_CONFIRMATION_MAIL_TO_CONSULTANT';
export interface RetrieveConsultingPackagesAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES;
}

export interface RetrieveConsultingPackagesSuccessAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES_SUCCESS;
  consultingPackages: ConsultingPackage[];
}

export interface RetrieveConsultingPackagesFailureAction {
  type: typeof RETRIEVE_CONSULTING_PACKAGES_FAILURE;
  error?: RequestResult<void>;
}

export interface SendConfirmationMailToConsultantAction {
  type: typeof SEND_CONFIRMATION_MAIL_TO_CONSULTANT;
  consultingPackageId?: string;
  consultantId?: string;
  linkToConsultingPackageDetailPage?: string;
  selectedCountryId?: string;
}
