// @ts-nocheck
import { useEffect, useRef } from 'react';
import useConstructor from 'src/hooks/useConstructor';
import { useRouter } from 'next/router';

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args);
  } else {
    console.warn('Intercom not initialized yet');
  }
};

const Intercom = (props: any) => {
  const prevIsLogIn = useRef(props.email || props.user_id);
  const router = useRouter();

  const onInit = () => {
    const { appID, ...otherProps } = props;

    if (!appID || !canUseDOM) {
      return;
    }

    if (!window.Intercom) {
      (function (w, d, id, s) {
        function i() {
          //eslint-disable-next-line
          i.c(arguments);
        }
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    window.intercomSettings = { ...otherProps, app_id: appID };

    if (window.Intercom) {
      window.Intercom('boot', otherProps);
    }
  };

  useConstructor(onInit);

  useEffect(() => {
    if (!canUseDOM) return;
    if (prevIsLogIn !== (props.email || props.user_id)) {
      const { appID, ...otherProps } = props;
      const isBottomActionBarPresent = router.asPath.indexOf('/business-opportunities/edit') !== -1;

      window.intercomSettings = { ...otherProps, app_id: appID, vertical_padding: isBottomActionBarPresent ? 90 : 0 };
      if (window.Intercom) {
        if ((props.email || props.user_id) && !prevIsLogIn) {
          // Shutdown and boot each time the user logs out to clear conversations
          window.Intercom('shutdown');
          window.Intercom('boot', otherProps);
        } else {
          window.Intercom('update', otherProps);
        }
      }
    }
  }, [props.email, props.user_id, router]);

  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');

        delete window.Intercom;
        delete window.intercomSettings;
      }
    };
  }, []);

  useEffect(() => {
    if (!window.Intercom) return;
    window.Intercom('onHide', props.onCloseIntercomChat);
    window.Intercom('onShow', props.onOpenIntercomChat);
  }, []);

  useEffect(() => {
    if (props.isChatOpen && window.Intercom) window.Intercom('show');
  }, [props.isChatOpen]);

  return null;
};

export default Intercom;
