import { BusinessType } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_BUSINESS_TYPES = 'businesstypes/RETRIEVE_BUSINESS_TYPES';
export const RETRIEVE_BUSINESS_TYPES_SUCCESS = 'businesstypes/RETRIEVE_BUSINESS_TYPES_SUCCESS';
export const RETRIEVE_BUSINESS_TYPES_FAILURE = 'businesstypes/RETRIEVE_BUSINESS_TYPES_FAILURE';

export interface RetrieveBusinessTypesAction {
  type: typeof RETRIEVE_BUSINESS_TYPES;
}

export interface RetrieveBusinessTypesSuccessAction {
  type: typeof RETRIEVE_BUSINESS_TYPES_SUCCESS;
  businessTypes: BusinessType[];
}

export interface RetrieveBusinessTypesFailureAction {
  type: typeof RETRIEVE_BUSINESS_TYPES_FAILURE;
  error?: RequestResult<void>;
}
