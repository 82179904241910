import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RequestResult } from '../../../interfaces/requests';
import logging from '../../../logging';
import { AppState } from '../../reducers';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { doPublishOnBackendQueueFailure, doPublishOnBackendQueueSuccess, doPublishOnBackendQueue } from './actions';
import { publish } from './operations';
import { PublishOnBackendQueueAction, PUBLISH_ON_BACKEND_QUEUE } from './types';

function* workerPublishOnBackendQueue({ event, variables, onSuccess, onFailure }: PublishOnBackendQueueAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      const response: AxiosResponse<RequestResult> = yield call(publish, locale, event, variables, profile.accessToken);
      if (response.data && response.data.succeeded) {
        yield put(doPublishOnBackendQueueSuccess());
        if (onSuccess) {
          yield call(onSuccess);
        }
      }
    } else {
      yield put(doRefreshTokenAndRetry(doPublishOnBackendQueue(event, variables, onSuccess, onFailure)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doPublishOnBackendQueue(event, variables, onSuccess, onFailure)));
    } else {
      logging.error(error);
      yield put(doPublishOnBackendQueueFailure(error));
      if (onFailure) {
        yield call(onFailure);
      }
    }
  }
}

export function* watcherPublishOnBackendQueue() {
  yield takeLatest(PUBLISH_ON_BACKEND_QUEUE, workerPublishOnBackendQueue);
}

export function* queueingSaga() {
  yield all([call(watcherPublishOnBackendQueue)]);
}
