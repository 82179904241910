import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getTranslations } from 'src/locales';
import { AppState } from '../../reducers';
import { AuthenticatedProfile } from '../auth/interfaces';
import {
  doUpdateTranslationSuccess,
  doUpdateTranslationFailed,
  doRetrieveTranslationsSuccess,
  doRetrieveTranslationsFailed
} from './actions';
import { updateTranslation } from './operations';
import {
  RetrieveTranslationsAction,
  RETRIEVE_TRANSLATIONS,
  UpdateTranslationAction,
  UPDATE_TRANSLATION
} from './types';

function* workerUpdateTranslation({ currentTranslationKey, newValue }: UpdateTranslationAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);
    if (profile) {
      yield call(updateTranslation, profile.accessToken, String(locale), currentTranslationKey, newValue);
      yield put(doUpdateTranslationSuccess(currentTranslationKey, newValue));
    } else {
      yield put(doUpdateTranslationFailed());
    }
  } catch (error) {
    yield put(doUpdateTranslationFailed());
  }
}

export function* watcherUpdateTranslation() {
  yield takeLatest(UPDATE_TRANSLATION, workerUpdateTranslation);
}

function* workerRetrieveTranslations({ language }: RetrieveTranslationsAction) {
  try {
    const translations = yield call(getTranslations, language);
    yield put(doRetrieveTranslationsSuccess(translations));
  } catch (error) {
    yield put(doRetrieveTranslationsFailed());
  }
}

export function* watcherRetrieveTranslations() {
  yield takeLatest(RETRIEVE_TRANSLATIONS, workerRetrieveTranslations);
}

export function* appSaga() {
  yield all([call(watcherUpdateTranslation), call(watcherRetrieveTranslations)]);
}
